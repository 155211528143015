<template>
  <div class="b-solution">
    <div v-if="item.new" class="e-new">NEW</div>

    <div class="b-solution__picture">
      <Anchor v-if="item.link" :item="item.link" :hide-text="true">
        <LazyImage
          v-if="item.image"
          :image="item.image"
          class="object-fit-cover" />
      </Anchor>
    </div>

    <div class="b-solution__main">
      <div class="b-solution__title mb-15 h2">{{ item.title }}</div>
      <article class="b-article mb-40" v-html="item.summary"></article>
      <div class="b-solution__footer">
        <Anchor
          v-if="item.link"
          :item="item.link"
          class="btn btn-primary btn-full-width" />
      </div>
    </div>
  </div>
</template>

<script>
import Anchor from './common/Anchor.vue'
import LazyImage from './common/LazyImage.vue'

export default {
  components: { LazyImage, Anchor },
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {}
  },
}
</script>
