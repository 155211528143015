<template>
  <div class="b-booking-wizard mb-40 d-print-none">
    <div class="b-booking-wizard__steps">
      <ul>
        <li
          v-for="(item, index) in steps"
          :key="index"
          :class="getClassName(item, index)"
          @click="onClick(item, index)">
          <div class="bar">
            <div
              class="progress"
              :class="{ 'is-active': isActiveBar(index) }"></div>
          </div>

          <button type="button">
            <i class="icon-check"></i>
          </button>
          <span class="d-none d-md-block">
            {{ steps[index]?.title }}
          </span>
          <em>{{ steps[index]?.summary }}</em>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as lib from './../../lib/lib'

export default {
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return { steps: [] }
  },
  mounted() {
    const { steps } = lib.getData('bookingData')
    this.steps = steps
  },
  methods: {
    onClick(item, index) {
      if (this.hasLink(item, index)) {
        window.location.assign(item.link.url)
      }
    },

    hasLink(item, index) {
      return (
        lib.hasValue(item.link?.url) &&
        this.isPrevious(index) &&
        this.step + 1 !== this.steps.length
      )
    },

    getClassName(item, index) {
      const classNames = []

      if (this.isActive(index)) {
        classNames.push('is-active')
      }

      if (this.isPrevious(index)) {
        classNames.push('is-previous')
      }

      if (this.hasLink(item, index)) {
        classNames.push('has-link')
      }

      return classNames.join(' ')
    },

    isActiveBar(index) {
      return this.step >= index + 1
    },
    isActive(index) {
      return index === Number(this.step)
    },
    isPrevious(index) {
      return index < Number(this.step)
    },
  },
}
</script>
