<template>
  <div class="dropdown-center">
    <button
      id="dropdowFleetFilters"
      class="form-select"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false">
      <div v-if="categories.length" class="form-select-wrapper">
        <div>
          {{ categories.length }}
          <span v-if="categories.length === 1">
            {{ dictionary.filtersSelectedSuffixSingle }}
          </span>
          <span v-else>{{ dictionary.filtersSelectedSuffixMultiple }}</span>
        </div>
        <button
          type="button"
          class="btn btn-clear btn-reset"
          title="Clear"
          @click.stop="resetCategories">
          <i class="icon-close"></i>
        </button>
      </div>
      <div v-else>{{ dictionary.filtersPlaceholder }}</div>
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdowFleetFilters">
      <div class="b-fleet-filters" @click.stop>
        <div class="b-fleet-filters__body mb-20">
          <!-- group -->
          <div class="b-fleet-filters__group-title mb-15">
            {{ filterTransmissionsLabel }}
          </div>
          <ul class="mb-15">
            <li v-for="item in transmissionsOptions" :key="item.value">
              <input
                :id="idPrefix + item.value"
                v-model="transmissions"
                class="form-check-input"
                autocomplete="off"
                type="checkbox"
                :value="item.value" />
              <label
                class="form-check-label"
                :for="idPrefix + item.value"
                @click.stop>
                {{ item.text }}
              </label>
            </li>
          </ul>

          <div class="b-fleet-filters__separator mb-15"></div>
          <!-- group -->
          <div class="b-fleet-filters__group-title mb-15">
            {{ filterSpecialCategoriesLabel }}
          </div>
          <ul>
            <li v-for="item in specialCategoriesOptions" :key="item.value">
              <input
                :id="idPrefix + item.value"
                v-model="specialCategories"
                class="form-check-input"
                autocomplete="off"
                type="checkbox"
                :value="item.value" />
              <label
                class="form-check-label"
                :for="idPrefix + item.value"
                @click.stop>
                {{ item.text }}
              </label>
            </li>
          </ul>
        </div>
        <div class="b-fleet-filters__footer">
          <button
            type="button"
            class="btn btn-primary btn-full-width"
            @click="onClickApply">
            {{ dictionary.buttonApplyFiltersText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { groupBy } from 'lodash'
import { Dropdown } from 'bootstrap'

export default {
  props: {
    types: {
      required: true,
      type: Array,
    },
    idPrefix: {
      required: false,
      type: String,
      default: '',
    },
  },
  emits: ['submit-filters'],
  data() {
    return {
      transmissionsOptions: [],
      specialCategoriesOptions: [],
      transmissions: [],
      specialCategories: [],
    }
  },

  computed: {
    categories() {
      return this.transmissions.concat(this.specialCategories)
    },

    filterTransmissionsLabel() {
      if (!this.transmissionsOptions.length) {
        return ''
      }

      return this.transmissionsOptions[0].group
    },

    filterSpecialCategoriesLabel() {
      if (!this.specialCategoriesOptions.length) {
        return ''
      }

      return this.specialCategoriesOptions[0].group
    },
  },
  mounted() {
    this.setTypes()
  },
  methods: {
    resetCategories() {
      this.transmissions = this.specialCategories = []
      this.onClickApply()
      this.dropDownHide()
    },

    dropDownHide() {
      const dropdownElement = document.querySelector('#dropdowFleetFilters')
      const dropdown = new Dropdown(dropdownElement)
      dropdown.hide()
    },

    setTypes() {
      /* eslint-disable no-undef */
      const grouped = _.groupBy(this.types, (car) => car.Grouping)

      this.transmissionsOptions = Object.values(grouped)[0].map((i) => ({
        value: i.CategoryId,
        text: i.CategoryName,
        group: i.Grouping,
      }))
      this.specialCategoriesOptions = Object.values(grouped)[1].map((i) => ({
        value: i.CategoryId,
        text: i.CategoryName,
        group: i.Grouping,
      }))
    },

    onClickApply() {
      this.$emit(
        'submit-filters',
        this.categories,
        this.transmissions,
        this.specialCategories
      )
      this.hideTooltip()
    },

    hideTooltip() {
      const element = document.getElementById('dropdowFleetFilters')
      const dropdown = Dropdown.getOrCreateInstance(element)
      dropdown.hide()
    },
  },
}
</script>

<style scoped lang="scss">
.form-select-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.btn-reset {
  color: #c71818;

  i {
    font-size: 12px;
  }
}
</style>
