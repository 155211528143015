<template>
  <section class="s-strip s-strip--short s-strip--theme-grey">
    <div class="s-strip__header mb-40">
      <div class="page-container">
        <div class="b-strip-header align-items-center">
          <div class="title h2">{{ title }}</div>
        </div>
      </div>
    </div>

    <div class="page-container">
      <BookingWizard :step="step" />
    </div>
  </section>

  <section class="s-strip">
    <div class="page-container">
      <div ref="strip" class="s-booking-layout">
        <Loader v-if="isLoading"></Loader>
        <Alert
          v-if="errorMsgs.length"
          type="danger"
          :messages="errorMsgs"></Alert>

        <form autocomplete="off" @submit.prevent="onSubmitForm">
          <div class="mb-30 h1">
            <span class="light">{{ fullName }} | {{ referenceNumber }}</span>
          </div>

          <Alert
            v-if="headerMessage"
            type="warning"
            :dismissible="true"
            class="mb-30">
            <div v-html="headerMessage"></div>
          </Alert>

          <div class="d-block d-lg-none mb-40">
            <BookingAsideAccordion
              v-if="vehicle"
              :item="vehicle"
              :payment="payment">
              <BookingAside :item="vehicle" :payment="payment" />
            </BookingAsideAccordion>
          </div>

          <div class="s-booking-layout__body">
            <div v-show="remoteUrl" class="s-booking-layout__main">
              <iframe id="payment-iframe-frame"></iframe>
            </div>

            <div v-show="!remoteUrl" class="s-booking-layout__main">
              <div
                class="d-none d-lg-flex justify-content-between align-items-center gx-2 mb-30">
                <div class="fs-20">
                  <span class="extrabold">
                    {{ dictionary.yourReservation }}
                  </span>
                </div>

                <div
                  class="d-flex change-link"
                  data-bs-toggle="tooltip"
                  :title="changeTooltipText">
                  <Anchor
                    v-if="changeLink"
                    :item="changeLink"
                    class="btn btn-link btn-icon-start"
                    :hide-text="true">
                    <i class="icon-edit"></i>
                    <span>{{ changeLink.text }}</span>
                  </Anchor>

                  <span v-if="changeTooltipText">
                    <LazyImage :image="{ url: '/imgs/icons/tooltip.svg' }" />
                  </span>
                </div>
              </div>

              <BookingYourReservation
                v-if="pickupDetails && returnDetails"
                :pickup-details="pickupDetails"
                :return-details="returnDetails"
                class="d-none d-lg-flex" />

              <Accordion
                :title="dictionary.yourReservation"
                class="d-block d-lg-none mb-40">
                <BookingYourReservation
                  v-if="pickupDetails && returnDetails"
                  :pickup-details="pickupDetails"
                  :return-details="returnDetails" />
              </Accordion>

              <Accordion
                v-if="amenitiesDefinitions.length"
                title="Extras"
                class="d-block d-lg-none mb-40">
                <BookingExtrasShortList :records="amenitiesDefinitions" />
              </Accordion>

              <div
                v-if="amenitiesDefinitions.length"
                class="d-none d-lg-flex justify-content-between align-items-center gx-2 mb-30">
                <div class="fs-20">
                  <span class="extrabold">Extras</span>
                </div>

                <div
                  class="d-flex change-link"
                  data-bs-toggle="tooltip"
                  :title="changeTooltipText">
                  <Anchor
                    v-if="changeLink"
                    :item="changeLink"
                    class="btn btn-link btn-icon-start"
                    :hide-text="true">
                    <i class="icon-edit"></i>
                    <span>{{ changeLink.text }}</span>
                  </Anchor>

                  <span v-if="changeTooltipText">
                    <LazyImage :image="{ url: '/imgs/icons/tooltip.svg' }" />
                  </span>
                </div>
              </div>

              <BookingExtrasShortList
                v-if="amenitiesDefinitions.length"
                class="d-none d-lg-block"
                :records="amenitiesDefinitions" />

              <div class="row d-none d-lg-flex">
                <div class="col mb-30 fs-20">
                  <span class="extrabold">
                    {{ dictionary.personalInformation }}
                  </span>
                </div>
              </div>

              <BookingPersonalInfo
                v-if="reservationPersonalInfo"
                :personal-info="reservationPersonalInfo"
                class="d-none d-lg-flex" />

              <Accordion
                :title="dictionary.personalInformation"
                class="d-block d-lg-none mb-40">
                <BookingPersonalInfo
                  v-if="reservationPersonalInfo"
                  :personal-info="reservationPersonalInfo" />
              </Accordion>

              <div v-if="hasAddressForm">
                <div class="form-separator mb-40"></div>

                <div class="row">
                  <div class="col mb-30 fs-20">
                    <span class="extrabold">
                      {{ dictionary.addressHeader }}
                    </span>
                  </div>
                </div>

                <BookingAddress
                  :show-phones="true"
                  @change-address-data="onChangeAddressData" />
              </div>

              <div v-if="hasCreditCard" class="row">
                <div class="col mb-30 fs-20">
                  <span class="extrabold">
                    {{ dictionary.creditCardHeader }}
                  </span>
                </div>
              </div>

              <BookingCrediCard
                v-if="hasCreditCard"
                @change-credit-card-data="onChangecreditCardData" />

              <div class="row">
                <div class="col mb-30">
                  <BookingLegalTerms :records="legalTerms" />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <ul class="b-booking-buttons-list">
                    <li>
                      <button
                        type="submit"
                        class="btn btn-primary btn-full-width">
                        {{ dictionary.buttonSubmitText }}
                      </button>
                    </li>
                    <li>
                      <Anchor
                        v-if="cancelLink"
                        :item="cancelLink"
                        class="btn btn-outline-primary btn-full-width" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              v-if="vehicle"
              class="s-booking-layout__aside d-none d-lg-block">
              <BookingAside :item="vehicle" :payment="payment" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import jQuery from 'jquery'
import LazyImage from './../components/common/LazyImage.vue'
import axios from 'axios'
import Anchor from '../components/common/Anchor.vue'
import useVuelidate from '@vuelidate/core'
import * as lib from '../../lib/lib'
import Accordion from '../components/common/Accordion.vue'
import BookingWizard from '../components/BookingWizard.vue'
import BookingAside from '../components/BookingAside.vue'
import BookingAsideAccordion from '../components/BookingAsideAccordion.vue'
import BookingPersonalInfo from '../components/BookingPersonalInfo.vue'
import BookingYourReservation from '../components/BookingYourReservation.vue'
import BookingExtrasShortList from '../components/BookingExtrasShortList.vue'
import BookingCrediCard from './../components/BookingCreditCard.vue'
import BookingLegalTerms from './../components/BookingLegalTerms.vue'
import BookingAddress from './../components/BookingAddress.vue'
import Loader from './../components/common/Loader.vue'
import Alert from './../components/common/Alert.vue'
import bookingMixins from './../mixins/booking-mixins'

export default {
  components: {
    Loader,
    Alert,
    LazyImage,
    Anchor,
    BookingWizard,
    BookingAside,
    BookingAsideAccordion,
    Accordion,
    BookingPersonalInfo,
    BookingYourReservation,
    BookingExtrasShortList,
    BookingCrediCard,
    BookingLegalTerms,
    BookingAddress,
  },
  mixins: [bookingMixins],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      scrollOffsetY: 125,
      isLoading: false,
      errorMsgs: [],
      // always show last step as active
      token: null,
      step: 2,
      title: null,
      vehicle: null,
      payment: null,
      reservationIdentityInfo: null,
      reservationPersonalInfo: null,
      pickupDetails: null,
      returnDetails: null,
      cancelLink: null,
      changeLink: null,
      changeTooltipText: '',
      headerMessage: null,
      amenitiesDefinitions: [],
      fullName: '',
      referenceNumber: '',
      legalTerms: [],
      hasAddressForm: false,

      addressData: {
        address: '',
        streetNumber: '',
        addressAlt: '',
        postalCode: '',
        city: '',
        country: '',
        area: '',
      },

      creditCardData: {
        cardNumber: '',
        cardHolder: '',
        cardExpirationMonth: '',
        cardExpirationYear: '',
        cardSecureCode: '',
      },

      remoteUrl: null,
    }
  },
  computed: {
    hasCreditCard() {
      const { cardExpirationYears } = lib.getData('bookingData')
      return cardExpirationYears !== null
    },
  },
  mounted() {
    const {
      token,
      title,
      vehicle,
      Payment,
      reservationIdentityInfo,
      reservationPersonalInfo,
      reservationDetails,
      cancelLink,
      changeLink,
      changeTooltipText,
      headerMessage,
      amenitiesDefinitions,
      fullName,
      referenceNumber,
      legalTerms,
      hasAddressForm,
    } = lib.getData('bookingData')

    this.token = token
    this.title = title
    this.vehicle = vehicle
    this.payment = Payment
    this.reservationIdentityInfo = reservationIdentityInfo
    this.reservationPersonalInfo = reservationPersonalInfo
    this.pickupDetails = reservationDetails.pickupDetails
    this.returnDetails = reservationDetails.returnDetails
    this.cancelLink = cancelLink
    this.changeLink = changeLink
    this.changeTooltipText = changeTooltipText
    this.headerMessage = headerMessage
    this.amenitiesDefinitions = amenitiesDefinitions
    this.fullName = fullName
    this.referenceNumber = referenceNumber
    this.legalTerms = legalTerms
    this.hasAddressForm = hasAddressForm

    this.setNavigatorValues()

    document.addEventListener('startNotifyTimer', (e) => {
      this.startNotifyTimer(e)
    })

    document.addEventListener('paymentRemoveIframe', (e) => {
      this.paymentRemoveIframe(e)
    })
  },
  methods: {
    paymentShowIframe() {
      console.log('paymentShowIframe')
      jQuery('#payment-iframe-frame').show()
      jQuery('#payment-iframe-frame').attr('src', this.remoteUrl)
      lib.scrollToElem(jQuery(this.$refs.strip), 100)
    },

    startNotifyTimer(e) {
      console.log('startNotifyTimer', e)

      const { checkUrl, iframeUrl } = e.detail

      let start = 0
      const timer = window.setInterval(() => {
        console.log('interval', start)

        if (start > 10) {
          console.log('start larger than 10')
          window.clearInterval(timer)
          jQuery('#payment-iframe-frame').attr('src', iframeUrl)
          return
        }
        axios
          .get(checkUrl)
          .then((resp) => {
            const { success, notified } = resp.data

            if (success && notified) {
              console.log('success && notified')
              window.clearInterval(timer)
              jQuery('#payment-iframe-frame').attr('src', iframeUrl)
              setTimeout(() => {
                this.isLoading = false
              }, 1000)
            }
          })
          .then(() => {
            start += 1
          })
      }, 1000)
    },

    paymentRemoveIframe(e) {
      console.log('paymentRemoveIframe', e)
      console.log('paymentRemoveIframe', e.detail.errors)
      console.log('paymentRemoveIframe', e.detail.url)

      this.isLoading = true

      jQuery('#payment-iframe-frame').hide()

      const { errors, url } = e.detail
      if (url) {
        window.location.assign(url)
        return false
      }

      this.remoteUrl = ''
      this.isLoading = false
      this.errorMsgs = [this.dictionary.defaultErrorMessage]
      // if errors array is empty or
      // has just one item with empty string
      if (errors.length && lib.hasValue(errors[0])) {
        this.errorMsgs = errors
      }

      lib.scrollToElem(jQuery(this.$refs.strip), 100)
    },

    onChangecreditCardData(data) {
      this.creditCardData = data
    },

    onChangeAddressData(data) {
      this.addressData = data
    },

    appendCreditCardData(formData) {
      formData.append('CardNumber', this.creditCardData.cardNumber)
      formData.append('CardHolder', this.creditCardData.cardHolder)
      // prettier-ignore
      formData.append('CardExpirationMonth', this.creditCardData.cardExpirationMonth)
      // prettier-ignore
      formData.append('CardExpirationYear', this.creditCardData.cardExpirationYear)
      formData.append('CardSecureCode', this.creditCardData.cardSecureCode)

      formData.append('NavigatorLanguage', this.navigatorLanguage)
      formData.append('NavigatorColors', this.navigatorColors)
      formData.append('NavigatorHeight', this.navigatorHeight)
      formData.append('NavigatorWidth', this.navigatorWidth)
      formData.append('NavigatorTimezone', this.navigatorTimezone)
      formData.append('IsJavaEnabled', this.isJavaEnabled)
      formData.append('ChallengeWindowSize', this.challengeWindowSize)
    },

    appendAddressData(formData) {
      const {
        address,
        streetNumber,
        addressAlt,
        postalCode,
        city,
        country,
        area,
        mobilePhone,
        mobileDialingCode,
        landPhone,
        landDialingCode,
      } = this.addressData

      formData.append('MobilePhone', mobilePhone)
      formData.append('MobileDialingCode', mobileDialingCode)
      formData.append('LandPhone', landPhone)
      formData.append('LandDialingCode', landDialingCode)

      formData.append('AddressStreet', address)
      formData.append('AddressNumber', streetNumber)

      formData.append('Address2', addressAlt)
      formData.append('PostalCode', postalCode)

      formData.append('City', city)
      formData.append('Country', country)
      formData.append('Area', area)
    },

    onSubmitForm() {
      this.v$.$validate()

      if (this.v$.$invalid) {
        this.$nextTick(() => {
          lib.scrollToValidation(this.scrollOffsetY)
        })
        return false
      }

      this.isLoading = true

      const formData = new FormData()

      formData.append('HasAddressData', !this.hasAddressForm)
      formData.append('AcceptTerms', true)
      formData.append('Token', this.token)

      if (this.hasCreditCard) {
        this.appendCreditCardData(formData)
      }

      if (this.hasAddressForm) {
        this.appendAddressData(formData)
      }

      axios
        .post(this.configuration.endpointSubmitData, formData)
        .then((response) => {
          const { HasError, Errors, Url, RemoteUrl } = response.data
          // response has error
          if (HasError) {
            this.handleError(Errors)
            return false
          }

          if (Url) {
            window.location.assign(Url)
          }
          if (RemoteUrl) {
            this.remoteUrl = RemoteUrl
            this.paymentShowIframe()
          }

          this.errorMsgs = []
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    handleSuccess() {
      this.errorMsgs = []
      this.isLoading = false
    },

    handleError(messages) {
      this.errorMsgs = messages
      this.isLoading = false

      this.$nextTick(() => {
        lib.scrollToError(100)
      })
    },
  },
}
</script>

<style scoped lang="scss">
#payment-iframe-frame {
  border: none;
  width: 100%;
  height: 450px;
  overflow: auto;
}
.change-link {
  gap: 10px;

  img {
    width: 20px;
    height: auto;
  }
}
</style>
