<template>
  <div
    id="modalSignup"
    class="modal fade s-modal s-modal--signup"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <div class="col-lg-6">{{ dictionary.modalTitle }}</div>
            </div>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close">
            <i class="icon-close-light"></i>
          </button>
        </div>
        <div class="modal-body">
          <form autocomplete="off" @submit.prevent="onSubmitForm">
            <Loader v-if="isLoading" display="absolute"></Loader>
            <Alert
              v-if="errorMsgs.length"
              type="danger"
              :messages="errorMsgs" />

            <div class="row">
              <!-- Firstname -->
              <div class="col-lg-6 mb-20">
                <label for="SignUpName" class="form-label">
                  {{ dictionary.firstnameLabel }}
                </label>
                <input
                  id="SignUpName"
                  v-model.trim="firstname"
                  class="form-control"
                  :class="{ 'has-error': v$.firstname.$error }"
                  name="SignUpName"
                  type="text"
                  @blur="v$.firstname.$touch" />

                <div v-if="v$.firstname.$error">
                  <div v-if="v$.firstname.required.$invalid" class="error">
                    {{ dictionary.firstnameRequired }}
                  </div>
                  <div
                    v-if="
                      v$.firstname.minLength.$invalid ||
                      v$.firstname.maxLength.$invalid
                    "
                    class="error">
                    {{ dictionary.firstnameLength }}
                  </div>
                </div>
              </div>

              <!-- Lastname -->
              <div class="col-lg-6 mb-20">
                <label for="SignUpSurname" class="form-label">
                  {{ dictionary.surnameLabel }}
                </label>
                <input
                  id="SignUpSurname"
                  v-model.trim="surname"
                  class="form-control"
                  :class="{ 'has-error': v$.surname.$error }"
                  name="SignUpSurname"
                  type="text"
                  @blur="v$.surname.$touch" />

                <div v-if="v$.surname.$error">
                  <div v-if="v$.surname.required.$invalid" class="error">
                    {{ dictionary.surnameRequired }}
                  </div>
                  <div
                    v-if="
                      v$.surname.minLength.$invalid ||
                      v$.surname.maxLength.$invalid
                    "
                    class="error">
                    {{ dictionary.surnameLength }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <!-- Email -->
              <div class="col-lg-6 mb-20">
                <label for="SignUpEmail" class="form-label">
                  {{ dictionary.emailLabel }}
                </label>

                <input
                  id="SignUpEmail"
                  v-model.trim="email"
                  class="form-control"
                  :class="{ 'has-error': v$.email.$error }"
                  name="SignUpEmail"
                  type="email"
                  @blur="v$.email.$touch" />

                <div v-if="v$.email.$error">
                  <div v-if="v$.email.required.$invalid" class="error">
                    {{ dictionary.emailRequired }}
                  </div>
                  <div v-if="v$.email.email.$invalid" class="error">
                    {{ dictionary.emailRegex }}
                  </div>
                  <div
                    v-if="
                      v$.email.minLength.$invalid || v$.email.maxLength.$invalid
                    "
                    class="error">
                    {{ dictionary.emailLength }}
                  </div>
                </div>
              </div>

              <!-- Password -->
              <div class="col-lg-6 mb-20 relative">
                <label for="SignUpPassword" class="form-label">
                  {{ dictionary.passwordLabel }}
                </label>

                <div class="position-relative">
                  <input
                    id="SignUpPassword"
                    v-model.trim="password"
                    class="form-control form-control-password"
                    :class="{ 'has-error': v$.password.$error }"
                    name="SignUpPassword"
                    type="password"
                    @blur="v$.password.$touch" />
                  <button
                    type="button"
                    class="btn btn-clear btn-password js-password-toggler">
                    <i class="icon-eye"></i>
                  </button>
                </div>

                <div v-if="v$.password.$error">
                  <div v-if="v$.password.required.$invalid" class="error">
                    {{ dictionary.passwordRequired }}
                  </div>
                  <div
                    v-if="
                      v$.password.minLength.$invalid ||
                      v$.password.maxLength.$invalid
                    "
                    class="error">
                    {{ dictionary.passwordLength }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <!-- Accept Terms -->
              <div class="col-lg-6 mb-20">
                <div class="form-check">
                  <input
                    id="signup-form-accept-terms"
                    v-model="terms"
                    name="AcceptTerms"
                    type="checkbox"
                    class="form-check-input"
                    autocomplete="off" />
                  <label
                    class="form-check-label"
                    for="signup-form-accept-terms">
                    <div v-html="dictionary.termsLabel"></div>
                  </label>
                </div>

                <div v-if="v$.terms.$error">
                  <div v-if="v$.terms.checked.$invalid" class="error">
                    {{ dictionary.termsRequired }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-20">
                <div id="g-recaptcha"></div>
              </div>
            </div>

            <div class="row">
              <div class="col d-flex justify-content-center mb-30">
                <button type="submit" class="btn btn-primary btn-full-width">
                  {{ dictionary.buttonSubmitText }}
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col d-flex justify-content-center semibold">
                <span>
                  {{ dictionary.alreadyAMember }}&nbsp;
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#modalLogin"
                    href="javascript:void(0)">
                    {{ dictionary.loginHere }}
                  </a>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- modal -->
  <div
    id="modalSignupThanks"
    class="modal fade s-modal s-modal--signup"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <div class="col-lg-6">{{ dictionary.successTitle }}</div>
            </div>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close">
            <i class="icon-close-light"></i>
          </button>
        </div>
        <div class="modal-body">
          <div v-html="dictionary.successText"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '../mixins/mixins'
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { email, maxLength, minLength, required } from '@vuelidate/validators'
import Alert from '../components/common/Alert.vue'
import Loader from '../components/common/Loader.vue'
import { Modal } from 'bootstrap'

export default {
  components: { Loader, Alert },
  mixins: [mixins],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      isLoading: false,
      errorMsgs: [],
      // form properties
      firstname: '',
      surname: '',
      email: '',
      password: '',
      terms: false,
      modalRegister: null,
      modalRegisterThanks: null,
      reCaptchaResponse: '',
    }
  },
  validations() {
    return {
      firstname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30),
      },
      surname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(40),
      },
      email: {
        required,
        email,
        minLength: minLength(6),
        maxLength: maxLength(50),
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(50),
      },
      terms: {
        checked: (value) => value === true,
      },
    }
  },
  mounted() {
    this.modalRegister = new Modal(document.getElementById('modalSignup'))
    // prettier-ignore
    this.modalRegisterThanks = new Modal(document.getElementById('modalSignupThanks'))

    document.addEventListener('recaptcha-ready', () => {
      this.iniReCaptcha()
    })

    // setTimeout(() => {
    //   this.iniReCaptcha()
    // }, 500)
  },
  methods: {
    callbackReCaptcha(response) {
      this.reCaptchaResponse = response
    },

    expiredCallbackReCaptha() {
      this.reCaptchaResponse = ''
    },

    iniReCaptcha() {
      document.getElementById('g-recaptcha').innerHTML = ''

      const grecaptcha = window.grecaptcha

      grecaptcha.render('g-recaptcha', {
        sitekey: this.configuration.reCaptchaPublicKey,
        callback: this.callbackReCaptcha,
        'expired-callback': this.expiredCallbackReCaptha,
      })
    },

    resetReCaptcha() {
      this.reCaptchaResponse = ''
      window.grecaptcha.reset()
    },

    onSubmitForm() {
      this.v$.$validate()

      if (this.v$.$invalid) {
        return false
      }

      this.isLoading = true

      const postData = {
        Culture: this.configuration.culture,
        nodeId: this.configuration.nodeId,
        SignUpName: this.firstname,
        SignUpSurname: this.surname,
        SignUpEmail: this.email,
        SignUpPassword: this.password,
        AcceptTerms: this.terms,
        'g-recaptcha-response': this.reCaptchaResponse,
      }

      axios
        .post(
          this.configuration.endpointSubmitData,
          postData,
          this.getAxiosConfig()
        )
        .then((response) => {
          const { result, description } = response.data
          // response has error
          if (result === 'error') {
            this.handleError([description])
            return false
          }

          this.modalRegister.hide()
          this.modalRegisterThanks.show()
          this.handleSuccess()
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    handleSuccess() {
      this.isLoading = false
      this.errorMsgs = []
    },

    handleError(messages) {
      this.resetReCaptcha()
      this.errorMsgs = messages
      this.isLoading = false
    },
  },
}
</script>
