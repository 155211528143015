/**
 * check if an element is in viewport, apply for .in-viewport selectors
 */
import jQuery from 'jquery'
import inViewport from 'in-viewport'

class IsInViewport {
  constructor(element) {
    if (!element) {
      return false
    }

    this.$element = jQuery(element)

    inViewport(element, { offset: -100 }, () => {
      this.$element.addClass('is-in-viewport')
    })
  }
}
export default IsInViewport
