/**
 * show sub menu on hover event of main menu items, apply to .header__nav selectors
 * set burger menu button functionality on click event
 */

import * as lib from './../lib/lib'
import jQuery from 'jquery'
import constants from './../lib/constants'

class Menu {
  constructor() {
    this.header = jQuery('.s-header')
    this.asideLinks = jQuery('.s-menumega__aside-menu a')
    this.menuLinks = jQuery('.s-header__nav a.has-submenu')
    this.menumegas = jQuery('.s-menumega')
    this.asideSubMenus = jQuery('.s-menumega__aside-submenu')
    // this.arrows = jQuery('> a > i', this.menuLinks)
    this.body = jQuery('body')
    this.burgerDesktopTrigger = jQuery('.js-burder-desktop-trigger')
    this.burgerMobileTrigger = jQuery('.js-burder-mobile-trigger')
    this.myTimer = false

    this._iniMenu()
  }

  _iniMenu() {
    this.burgerDesktopTrigger.on('click', (e) => {
      e.stopPropagation()
      const targetElement = jQuery(e.currentTarget)
      const id = targetElement.data('id')
      const headerHeight = this.header.outerHeight()

      if (this.body.hasClass(constants.body.activeClassname)) {
        this._resetMenu()
        return false
      }

      const menu = jQuery(`.s-menumega[data-parent-id='${id}']`)
      menu.addClass('is-active').css({ top: `${headerHeight}px` })

      this.body.toggleClass(
        `${constants.body.overlayClassname} ${constants.body.activeClassname}`
      )

      this._displayFirstPanel(menu)
    })

    this.burgerMobileTrigger.on('click', () => {
      if (this.body.hasClass(constants.body.activeClassname)) {
        // prettier-ignore
        document.dispatchEvent(new CustomEvent('burgerMobileClick', { detail: {status: constants.menu.close} }))
        this._resetMenu()
        return false
      }
      // prettier-ignore
      document.dispatchEvent(new CustomEvent('burgerMobileClick', { detail: {status: constants.menu.open} }))
      this.body.toggleClass(
        `${constants.body.overlayClassname} ${constants.body.activeClassname}`
      )
    })

    this.asideLinks.on('mouseenter', (e) => {
      e.preventDefault()
      e.stopPropagation()
      const targetElement = jQuery(e.currentTarget)
      const id = targetElement.data('id')
      const className = '.s-menumega__aside-submenu'

      jQuery(className).removeClass('is-active')
      this.asideLinks.removeClass('is-active')

      const menu = jQuery(`${className}[data-parent-id='${id}']`)
      menu.addClass('is-active')
      targetElement.addClass('is-active')
    })

    this.menuLinks.on('mouseenter', (e) => {
      const targetElement = jQuery(e.currentTarget)
      const id = targetElement.data('id')
      const menu = jQuery(`.s-menumega[data-parent-id='${id}']`)
      const headerHeight = this.header.outerHeight()

      if (lib.scrDevices()) {
        return false
      }

      clearTimeout(this.myTimer)

      this.myTimer = setTimeout(() => {
        this._resetMenu()

        menu.addClass('is-active').css({ top: `${headerHeight}px` })
        this.body.addClass(constants.body.overlayClassname)

        this._displayFirstPanel(menu)
      }, 250)
    })

    this.menuLinks.on('mouseleave', () => {
      if (lib.scrDevices()) {
        return false
      }

      clearTimeout(this.myTimer)

      this.myTimer = setTimeout(() => {
        // this.menumegas.removeClass('is-active').removeAttr('style')
        // this.body.removeClass(constants.body.overlayClassname)
        this._resetMenu()
      }, 250)
    })

    this.menumegas.on('mouseenter', () => {
      clearTimeout(this.myTimer)
    })

    this.menumegas.on('mouseleave', (e) => {
      const targetElement = jQuery(e.currentTarget)
      if (targetElement.hasClass('e-burger-menumega')) {
        return false
      }

      this.myTimer = setTimeout(() => {
        this._resetMenu()
      }, 250)
    })
  }
  _resetMenu() {
    this.body
      .removeClass(constants.body.overlayClassname)
      .removeClass(constants.body.activeClassname)
    this.menumegas.removeClass('is-active').removeAttr('style')
    this.asideLinks.removeClass('is-active')
    this.asideSubMenus.removeClass('is-active')
    this.asideLinks.trigger('blur')
  }

  _displayFirstPanel(element) {
    const basicMenu = jQuery(
      '.s-menumega__aside-menu > ul > li:first-child > a',
      element
    )
    const basicMenuId = basicMenu.data('id')
    const basicSubMenu = jQuery(
      `.s-menumega__aside-submenu[data-parent-id='${basicMenuId}']`
    )
    basicSubMenu.addClass('is-active')
    basicMenu.addClass('is-active')
  }
}
export default Menu
