<template>
  <div
    ref="vehicle"
    class="b-vehicle"
    :class="[isViewList ? 'view-list' : 'view-grid']">
    <Loader v-if="isLoading" display="absolute"></Loader>
    <Alert v-if="errorMsgs.length" type="danger" :messages="errorMsgs" />

    <div class="b-vehicle__tagged">
      <VehicleTag :item="item" />
    </div>

    <div class="b-vehicle__body">
      <div class="b-vehicle__header mb-40">
        <div class="b-vehicle__title h4 extrabold">
          {{ item.Model }}
        </div>
        <div class="b-vehicle__groups mb-10 semibold">
          <ul>
            <li data-bs-toggle="tooltip" :title="item.AcrissData">
              {{ item.GroupAcrissTxt }}
              <span>
                <LazyImage :image="{ url: '/imgs/icons/tooltip.svg' }" />
              </span>
            </li>
            <li class="separator"></li>
            <li>{{ item.CategoryNames }}</li>
          </ul>
        </div>
        <div class="b-vehicle__details">
          <ul>
            <li>
              <div class="pair bold">
                <i class="icon-passenger"></i>
                {{ item.PassengersTxt }}
              </div>
            </li>

            <li>
              <div class="b-vehicle__luggages">
                <div class="pair bold">
                  <i class="icon-suitcase-large"></i>
                  <span v-if="item.BigLuggage">x{{ item.BigLuggage }}</span>
                  <span v-else>-</span>
                </div>

                <div class="pair bold">
                  <i class="icon-suitcase"></i>
                  <span v-if="item.SmallLuggage">x{{ item.SmallLuggage }}</span>
                  <span v-else>-</span>
                </div>
              </div>
            </li>
            <li>
              <div class="pair bold">
                <i class="icon-ac"></i>
                <span v-if="item.HasAC">{{ dictionary.acYes }}</span>
                <span v-else>{{ dictionary.acNo }}</span>
              </div>
            </li>
            <li v-if="showTransmission">
              <div class="pair bold">
                <i class="icon-transmission"></i>
                <span v-if="item.IsManual">
                  {{ dictionary.transmissionManual }}
                </span>
                <span v-if="item.IsAutomatic">
                  {{ dictionary.transmissionAutomatic }}
                </span>
              </div>
            </li>
            <li v-if="item.Extras">
              <div class="pair">
                <i class="icon-info"></i>
                <span v-html="item.Extras"></span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="b-vehicle__picture mb-30">
        <LazyImage v-if="image" :image="image" />
      </div>

      <div v-if="isViewGrid" class="b-vehicle__extras mb-20">
        <p>
          <small>{{ dictionary.bookingIncludes }}</small>
        </p>
        <ul>
          <li v-for="(extra, index) in item.ExtrasList" :key="index">
            {{ extra }}
          </li>
        </ul>
      </div>

      <div class="b-vehicle__footer">
        <div v-if="isViewGrid" class="b-vehicle__separator mb-20"></div>

        <div class="b-vehicle__totals mb-20">
          <div class="b-vehicle__price">
            <span class="semibold">
              {{ dictionary.prepaidTotalPricePrefix }}
            </span>
            <span
              v-if="item.IsPrePaid"
              class="price"
              v-html="item.PrepaidTotalPrice"></span>
            <span
              v-if="isOnlyNonPrePaid"
              class="price"
              v-html="item.ReturnTotalPrice"></span>
          </div>
          <form
            v-if="item.IsPrePaid"
            autocomplete="off"
            @submit.prevent="onSubmitPrepaid">
            <button class="btn btn-primary btn-full-width" type="submit">
              {{ dictionary.buttonBookText }}
            </button>
          </form>
          <form
            v-if="isOnlyNonPrePaid"
            autocomplete="off"
            @submit.prevent="onSubmitReturn">
            <button class="btn btn-primary btn-full-width" type="submit">
              {{ dictionary.buttonBookText }}
            </button>
          </form>
        </div>

        <div
          v-if="isViewGrid && !isOnlyNonPrePaid"
          class="b-vehicle__separator mb-20"></div>

        <div v-if="!isOnlyNonPrePaid" class="b-vehicle__category">
          <form autocomplete="off" @submit.prevent="onSubmitReturn">
            <button class="btn btn-link" type="submit">
              <div>
                {{ dictionary.returnTotalPricePrefix }}
                <span class="extrabold" v-html="item.ReturnTotalPrice"></span>
                {{ dictionary.returnTotalPriceSuffix }}
              </div>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery'
import LazyImage from './common/LazyImage.vue'
import * as lib from '../../lib/lib'
import axios from 'axios'
import Loader from './../components/common/Loader.vue'
import Alert from './../components/common/Alert.vue'
import constants from './../../lib/constants'
import VehicleTag from './VehicleTag.vue'

export default {
  components: { LazyImage, Loader, Alert, VehicleTag },
  props: {
    item: {
      required: true,
      type: Object,
    },
    isViewGrid: {
      required: false,
      type: Boolean,
    },
    isViewList: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      scrollOffsetY: 100,
      isLoading: false,
      errorMsgs: [],
      token: '',
      rateReference: '',
    }
  },
  computed: {
    isOnlyNonPrePaid() {
      return !this.item.IsPrePaid && this.item.IsNonPrePaid
    },

    showTransmission() {
      return this.item.IsManual || this.item.IsAutomatic
    },

    image() {
      const images = this.item.Images
      if (!images.length) {
        return null
      }
      return {
        url: images[0],
        alt: '',
      }
    },

    link() {
      return {
        url: this.item.ButtonUrl,
        text: this.item.ButtonText,
        target: '_parent',
        external: false,
      }
    },
  },
  mounted() {
    const { token, rateReference } = lib.getData('bookingData')
    this.token = token
    this.rateReference = rateReference
  },
  methods: {
    onSubmitPrepaid() {
      this.onSubmitForm(constants.paymentType.prepaid)
    },

    onSubmitReturn() {
      this.onSubmitForm(constants.paymentType.return)
    },

    onSubmitForm(paymentType) {
      this.isLoading = true

      const postData = {
        Name: this.item.Name,
        PaymentType: paymentType,
        Token: this.token,
        RateReference: this.rateReference,
      }

      axios
        .post(this.configuration.endpointSubmitData, postData)
        .then((response) => {
          const { HasError, Errors, Url } = response.data
          // response has error
          if (HasError) {
            this.handleError(Errors)
            return false
          }

          window.location.assign(Url)
          this.handleSuccess()
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    handleSuccess() {
      this.errorMsgs = []
    },

    handleError(messages) {
      this.errorMsgs = messages
      this.isLoading = false

      lib.scrollToElem(jQuery(this.$refs.vehicle), this.scrollOffsetY)
    },
  },
}
</script>
