/**
 * add accordion functionality for menu items to .s-footer selector
 */
import jQuery from 'jquery'
import * as lib from './../lib/lib'

export default class Footer {
  constructor(element) {
    if (!element) {
      return false
    }

    this.$element = jQuery(element)
    this.headers = jQuery('.s-footer__group--collapsed .heading', this.$element)
    this._onClick()
  }

  _onClick() {
    this.headers.on('click', (e) => {
      e.preventDefault()

      if (lib.scrDesktops()) {
        return false
      }

      const targetElement = jQuery(e.currentTarget)
      const ul = targetElement.siblings('.list').first()
      ul.slideToggle()
      targetElement.toggleClass('is-active')
    })
  }
}
