import jQuery from 'jquery'

const bookingMixins = {
  methods: {
    setNavigatorValues() {
      const bodyWidth = jQuery('body').width()
      let wSize = 600
      if (bodyWidth < 600) wSize = 500
      if (bodyWidth < 500) wSize = 390
      if (bodyWidth < 300) wSize = 250

      let colors = 48
      if (screen.colorDepth >= 48) colors = 48
      else if (screen.colorDepth >= 32) colors = 32
      else if (screen.colorDepth >= 24) colors = 24
      else if (screen.colorDepth >= 16) colors = 16
      else if (screen.colorDepth >= 15) colors = 15
      else if (screen.colorDepth >= 8) colors = 8
      else if (screen.colorDepth >= 4) colors = 4
      else if (screen.colorDepth >= 1) colors = 1

      this.navigatorColors = colors
      this.navigatorLanguage = navigator.language
      this.navigatorHeight = parseInt(`${screen.height}`.trim(), 10)
      this.navigatorWidth = parseInt(`${screen.width}`, 10)
      this.navigatorTimezone = new Date().getTimezoneOffset()
      this.isJavaEnabled = navigator.javaEnabled()
      this.challengeWindowSize = wSize
    },
  },
}

export default bookingMixins
