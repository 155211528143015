import constants from '../../lib/constants'
import * as lib from './../../lib/lib'
import axios from 'axios'

const mixins = {
  methods: {
    getAxiosConfig() {
      return {
        headers: {
          RequestVerificationToken: this.getTokenDOMValue(),
        },
      }
    },

    getTokenResponseValue(data) {
      const parser = new DOMParser()
      const html = parser.parseFromString(data, 'text/html')
      // prettier-ignore
      const value = html.querySelector(constants.tokenInputSelector).value
      return value
    },

    getTokenDOMValue() {
      const form = document.getElementById(constants.tokenFormId)

      if (!form) {
        return ''
      }
      // prettier-ignore
      const input = form.querySelector(constants.tokenInputSelector)
      return input.value
    },

    refreshTokenDOMValue(value) {
      document.querySelectorAll(constants.tokenInputSelector).forEach((i) => {
        i.value = value
      })
    },

    refreshToken() {
      const { endpointRefreshToken } = this.configuration

      if (!endpointRefreshToken) {
        alert('An Error Occured')
        return false
      }

      axios
        .post(endpointRefreshToken, {})
        .then((response) => {
          const { data } = response
          const value = this.getTokenResponseValue(data)
          this.refreshTokenDOMValue(value)
        })
        .catch(() => {
          alert('An Error Occured')
        })
    },

    getVueSliderMarksAsArray(min, max, interval) {
      const array = []
      let i

      for (i = min; i < max; i += interval) {
        array.push(i)
      }
      return array
    },

    formatCurrency(value) {
      const locale = lib.getLocaleFromLangAttr()
      const number = Intl.NumberFormat(locale)
      return `€ ${number.format(value.toFixed(2))}`
    },

    formatCurrencyInt(value) {
      const locale = lib.getLocaleFromLangAttr()
      const number = Intl.NumberFormat(locale)
      return `€ ${number.format(value.toFixed(0))}`
    },
  },
}

export default mixins
