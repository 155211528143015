<template>
  <div class="b-personal-info mb-40">
    <div class="b-personal-info__conts">
      <dl>
        <dt>{{ dictionary.fullNameLabel }}</dt>
        <dd>{{ personalInfo?.fullName }}</dd>
      </dl>
      <dl>
        <dt>{{ dictionary.emailLabel }}</dt>
        <dd>
          <a :href="'mailto:' + personalInfo?.email">
            {{ personalInfo?.email }}
          </a>
        </dd>
      </dl>
      <dl>
        <dt>{{ dictionary.phoneNumberLabel }}</dt>
        <dd>
          <a :href="'tel:' + personalInfo?.phoneNumber">
            {{ personalInfo?.phoneNumber }}
          </a>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    personalInfo: {
      required: true,
      type: Object,
    },
  },
}
</script>
