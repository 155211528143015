<template>
  <div :dir="dir"><slot></slot></div>
</template>

<script>
import jQuery from 'jquery'
import 'slick-carousel'

export default {
  props: {
    className: {
      required: false,
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  emits: [
    'afterchange',
    'beforechange',
    'breakpoint',
    'destroy',
    'edge',
    'init',
    'reinit',
    'setposition',
    'swipe',
    'lazyloaded',
    'lazyloaderror',
  ],
  data() {
    return { dir: null }
  },

  mounted() {
    const self = this

    this.dir = jQuery('html').attr('dir')

    this.$nextTick(() => {
      self.create()

      jQuery(this.$el).find('[data-src]')

      jQuery(this.$el).on('beforeChange', () => {
        jQuery(this.$el).find('[data-src]')
      })

      jQuery(this.$el).on('afterChange', () => {
        jQuery(this.$el).find('[data-src]')
      })
    })
  },
  beforeUnmount() {
    this.onDestroy()
  },

  methods: {
    create: function () {
      const $slick = jQuery(this.$el)

      if ($slick.find('.item').length >= 1) {
        $slick.on('afterChange', this.onAfterChange)
        $slick.on('beforeChange', this.onBeforeChange)
        $slick.on('breakpoint', this.onBreakpoint)
        $slick.on('destroy', this.onDestroy)
        $slick.on('edge', this.onEdge)
        $slick.on('init', this.onInit)
        $slick.on('reInit', this.onReInit)
        $slick.on('setPosition', this.onSetPosition)
        $slick.on('swipe', this.onSwipe)
        $slick.on('lazyLoaded', this.onLazyLoaded)
        $slick.on('lazyLoadError', this.onLazyLoadError)
        $slick.slick(this.options)
      }
    },

    destroy: function () {
      const $slick = jQuery(this.$el)

      if (jQuery(this.$el).hasClass('slick-initialized')) {
        $slick.off('afterchange', this.onAfterChange)
        $slick.off('beforeChange', this.onBeforeChange)
        $slick.off('breakpoint', this.onBreakpoint)
        $slick.off('destroy', this.onDestroy)
        $slick.off('edge', this.onEdge)
        $slick.off('init', this.onInit)
        $slick.off('reInit', this.onReInit)
        $slick.off('setPosition', this.onSetPosition)
        $slick.off('swipe', this.onSwipe)
        $slick.off('lazyLoaded', this.onLazyLoaded)
        $slick.off('lazyLoadError', this.onLazyLoadError)
        $slick.removeClass('slick-initialized')
        jQuery(this.$el).slick('unslick')
      }
    },

    reSlick: function () {
      console.log('reslick')
      this.destroy()
      this.create()
    },

    next: function () {
      jQuery(this.$el).slick('slickNext')
    },

    prev: function () {
      jQuery(this.$el).slick('slickPrev')
    },

    pause: function () {
      jQuery(this.$el).slick('slickPause')
    },

    play: function () {
      jQuery(this.$el).slick('slickPlay')
    },

    goTo: function (index, dontAnimate) {
      jQuery(this.$el).slick('slickGoTo', index, dontAnimate)
    },

    currentSlide: function () {
      return jQuery(this.$el).slick('slickCurrentSlide')
    },

    add: function (element, index, addBefore) {
      jQuery(this.$el).slick('slickAdd', element, index, addBefore)
    },

    remove: function (index, removeBefore) {
      jQuery(this.$el).slick('slickRemove', index, removeBefore)
    },

    filter: function (filterData) {
      jQuery(this.$el).slick('slickFilter', filterData)
    },

    unfilter: function () {
      jQuery(this.$el).slick('slickUnfilter')
    },

    getOption: function (option) {
      jQuery(this.$el).slick('slickGetOption', option)
    },

    setOption: function (option, value, refresh) {
      jQuery(this.$el).slick('slickSetOption', option, value, refresh)
    },

    setPosition: function () {
      jQuery(this.$el).slick('setPosition')
    },

    // Events
    onAfterChange: function (event, slick, currentSlide) {
      this.$emit('afterchange', event, slick, currentSlide)
    },

    onBeforeChange: function (event, slick, currentSlide, nextSlide) {
      this.$emit('beforechange', event, slick, currentSlide, nextSlide)
    },

    onBreakpoint: function (event, slick, breakpoint) {
      this.$emit('breakpoint', event, slick, breakpoint)
    },

    onDestroy: function (event, slick) {
      this.$emit('destroy', event, slick)
    },

    onEdge: function (event, slick, direction) {
      this.$emit('edge', event, slick, direction)
    },

    onInit: function (event, slick) {
      this.$emit('init', event, slick)
    },

    onReInit: function (event, slick) {
      this.$emit('reinit', event, slick)
    },

    onSetPosition: function (event, slick) {
      this.$emit('setposition', event, slick)
    },

    onSwipe: function (event, slick, direction) {
      this.$emit('swipe', event, slick, direction)
    },

    onLazyLoaded: function (event, slick, image, imageSource) {
      this.$emit('lazyloaded', event, slick, image, imageSource)
    },

    onLazyLoadError: function (event, slick, image, imageSource) {
      this.$emit('lazyloaderror', event, slick, image, imageSource)
    },
  },
}
</script>
