<template>
  <Anchor
    v-if="item.link"
    :item="item.link"
    :hide-text="true"
    class="b-menu-offer">
    <div class="b-menu-offer__picture mb-15">
      <LazyImage
        v-if="item.image"
        :image="item.image"
        class="object-fit-cover"
        :class="{ 'object-fit-contain': item.imageContain }" />
    </div>
    <div class="h4 bold mb-10">{{ item.title }}</div>
    <div class="mb-30">
      <p>
        {{ item.summary }}
      </p>
    </div>
    <div class="btn btn-link-dark btn-icon-end btn-forward">
      <span>{{ item.link.text }}</span>
      <i class="icon-arrow-long-right"></i>
    </div>
  </Anchor>
</template>

<script>
import Anchor from './common/Anchor.vue'
import LazyImage from './common/LazyImage.vue'

export default {
  components: { Anchor, LazyImage },
  props: {
    item: {
      required: true,
      type: Object,
    },
    imageContain: {
      required: false,
      type: Boolean,
    },
  },
}
</script>
