<template>
  <!-- modal -->
  <div
    id="sessionTimeoutModal"
    class="modal fade s-modal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            {{ dictionary.sessionWarningTitle }}
          </div>
        </div>
        <div class="modal-body">
          <div class="js-session-panel-count">
            <p v-if="session?.additionalMessage">
              {{ session?.additionalMessage }}
            </p>

            <p>
              {{ dictionary.sessionRemainingTime }}
            </p>
            <div class="js-session-counter h2"></div>
          </div>
          <div class="js-session-panel-end">
            <p>{{ dictionary.sessionExpired }}</p>
          </div>
        </div>

        <div class="modal-footer">
          <ul>
            <li>
              <div class="js-session-panel-count">
                <a :href="session?.refreshUrl" class="btn btn-primary">
                  {{ dictionary.sessionButtonRefreshText }}
                </a>
              </div>
              <div class="js-session-panel-end">
                <a :href="session?.expiredUrl" class="btn btn-primary">
                  {{ dictionary.sessionButtonOKText }}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery'
import * as lib from './../../lib/lib'
import { Modal } from 'bootstrap'

export default {
  data() {
    return {
      session: null,

      modal: null,

      isModalOpen: false,

      mainInterval: 0,

      iniTotalTime: 0,
      iniCapTime: 0,
      totalTime: 0,
      capTime: 0,
    }
  },
  mounted() {
    const { sessionExpirationWarning } = lib.getData('bookingData')
    this.session = sessionExpirationWarning

    if (!this.session) {
      return false
    }
    this.iniTotalTime = this.totalTime = this.session.timeoutSeconds
    this.iniCapTime = this.capTime = this.session.countDownSeconds

    this.modal = new Modal(document.getElementById('sessionTimeoutModal'))

    this.setModalState()

    this.countDown()
  },
  methods: {
    // public
    countDown() {
      clearInterval(this.mainInterval)

      this.mainInterval = setInterval(() => {
        this.countDownSetTime()
      }, 1000)
    },

    countDownSetTime() {
      this.totalTime = Math.max(0, this.totalTime - 1)

      if (this.totalTime < this.capTime) {
        this.capTime = Math.max(0, this.capTime - 1)

        if (!this.isModalOpen) {
          this.openModal()
        }
      }

      if (this.capTime <= 0) {
        clearInterval(this.mainInterval)
        this.setModalState()
      }

      this.printCapTime()
    },

    // public
    pause() {
      clearInterval(this.mainInterval)
    },

    // public
    resume() {
      if (this.totalTime && this.capTime) {
        this.closeModal()
        this.countDown()
      }
    },

    // public
    restart() {
      clearInterval(this.mainInterval)

      this.totalTime = this.iniTotalTime
      this.capTime = this.iniCapTime
      this.mainInterval = 0

      this.resume()
    },

    printCapTime() {
      jQuery('.js-session-counter').text(this.formatTime(this.capTime))
    },

    setModalState() {
      jQuery('.js-session-panel-count').hide()
      jQuery('.js-session-panel-end').hide()
      if (this.capTime > 0) {
        jQuery('.js-session-panel-count').show()
      } else {
        jQuery('.js-session-panel-end').show()
      }
    },

    openModal() {
      this.isModalOpen = true
      this.modal.show()
    },

    closeModal() {
      this.isModalOpen = false
      this.modal.hide()
    },

    formatTime(timestamp) {
      let minutes = Math.floor(timestamp / 60)
      minutes = minutes > 0 ? minutes : 0
      let seconds = (timestamp % 60).toFixed(0)
      seconds = seconds > 0 ? seconds : 0

      const minutesFormatted = minutes.toString().padStart(2, '0')
      const secondsFormatted = seconds.toString().padStart(2, '00')
      return `${minutesFormatted}:${secondsFormatted}`
    },
  },
}
</script>
