<template>
  <div class="b-booking-calendar" @click.stop>
    <div class="b-booking-calendar__body mb-15">
      <DatePicker
        v-model="date"
        :locale="locale"
        :min-date="minDate"
        :max-date="maxDate"
        :columns="columns"
        nav-visibility="" />
    </div>

    <div class="b-booking-calendar__footer">
      <div class="b-booking-calendar__hours">
        <div class="b-booking-calendar__hours-label">
          <i class="icon-clock"></i>
          <label :for="'hour' + type + screen">
            <span class="small bold">{{ hourLabel }}</span>
          </label>
        </div>
        <div class="b-booking-calendar__hours-options">
          <ul>
            <li>
              <select
                :id="'hour' + type + screen"
                v-model="hour"
                :name="'hour' + type"
                class="form-select">
                <option
                  v-for="item in hourDataSource"
                  :key="item.value"
                  :value="item.value">
                  {{ item.text }}
                </option>
              </select>
            </li>
            <li><span class="separator">:</span></li>
            <li>
              <select
                :id="'minutes' + type + screen"
                v-model="minutes"
                :name="'minutes' + type"
                class="form-select">
                <option
                  v-for="item in minutesDataSource"
                  :key="item.value"
                  :value="item.value">
                  {{ item.text }}
                </option>
              </select>
            </li>
          </ul>
        </div>
      </div>
      <div class="b-booking-calendar__buttons">
        <ul>
          <li>
            <button
              type="button"
              class="btn btn-outline-primary btn-full-width"
              @click="onClickCancel">
              {{ dictionary.buttonDatePickerCancelText }}
            </button>
          </li>
          <li>
            <button
              type="button"
              class="btn btn-primary btn-full-width"
              :disabled="isDisabled"
              @click="onClickSubmit">
              {{ dictionary.buttonDatePickerSubmitText }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar'
import constants from './../../lib/constants'
import moment from 'moment'

export default {
  components: { DatePicker },
  props: {
    columns: {
      required: false,
      type: Number,
      default: 2,
    },
    screen: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    hourLabel: {
      required: true,
      type: String,
    },
    selectedDate: {
      required: false,
      type: Date,
      default: null,
    },
    selectedHour: {
      required: true,
      type: String,
    },
    selectedMinutes: {
      required: true,
      type: String,
    },
    hourDataSource: {
      required: true,
      type: Array,
    },
    minutesDataSource: {
      required: true,
      type: Array,
    },
    dateDeparture: {
      required: false,
      type: Date,
      default: null,
    },
  },
  emits: ['submit-calendar', 'cancel-calendar'],
  data() {
    return {
      locale: document.documentElement.lang,
      date: this.selectedDate,
      hour: this.selectedHour,
      minutes: this.selectedMinutes,
      maxDate: moment(new Date()).add(365, 'd').toDate(),
    }
  },
  computed: {
    isDisabled() {
      return !this.date
    },
    minDate() {
      if (this.type === constants.location.departure) {
        return new Date()
      }

      if (this.type === constants.location.return) {
        if (!this.dateDeparture) {
          return new Date()
        }
      }
      return this.dateDeparture
    },
  },
  methods: {
    onClickSubmit() {
      this.$emit(
        'submit-calendar',
        this.date,
        this.hour,
        this.minutes,
        this.type
      )
    },

    onClickCancel() {
      this.$emit('cancel-calendar')
    },
  },
}
</script>
