import { Modal } from 'bootstrap'
import jQuery from 'jquery'

export default class WorkFlow {
  constructor() {
    const handler = jQuery('.s-header__languages').data('handler')
    const links = jQuery('.s-header__languages a')
    const element = document.getElementById('modalWorkFlow')
    const modal = element ? new Modal(element) : null
    const modalButtonOK = jQuery('#modalWorkFlow button.btn-primary')
    this.href = null

    if (!modal) {
      return false
    }

    if (!handler) {
      return false
    }

    links.on('click', (e) => {
      e.preventDefault()

      const targetElement = jQuery(e.currentTarget)
      this.href = targetElement.attr('href')

      try {
        jQuery
          .get(handler)
          .done((data) => {
            const { hasWorkflow } = data
            if (hasWorkflow) {
              modal.show()
              return false
            }
            window.location.assign(this.href)
          })
          .fail(() => {
            window.location.assign(this.href)
          })
          .always(() => {})
      } catch (err) {
        window.location.assign(this.href)
      }
    })

    modalButtonOK.on('click', (e) => {
      e.preventDefault()
      window.location.assign(this.href)
    })
  }
}
