<template>
  <div class="form-check">
    <input
      id="acceptTerms"
      v-model="acceptTerms"
      name="acceptTerms"
      type="checkbox"
      class="form-check-input"
      :class="{ 'has-error': v$.acceptTerms.$error }"
      autocomplete="off" />
    <label for="acceptTerms" class="form-check-label">
      <div>
        <span>{{ dictionary.acceptTermsLabel }}&nbsp;</span>

        <span v-for="(item, index) in records" :key="index">
          <a
            href="javascript:void(0)"
            data-bs-toggle="modal"
            :data-bs-target="'#modalLegalTerms' + index">
            {{ item.title }}
          </a>
          <span v-if="index < records.length - 1">&nbsp;&&nbsp;</span>
        </span>
      </div>
    </label>
  </div>

  <div v-if="v$.acceptTerms.$error">
    <div v-if="v$.acceptTerms.checked.$invalid" class="error">
      {{ dictionary.acceptTermsRequired }}
    </div>
  </div>

  <div v-for="(item, index) in records" :key="index">
    <Modal
      :id="'modalLegalTerms' + index"
      :title="item.title"
      class="s-modal--booking-terms">
      <div v-html="item.content"></div>
    </Modal>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import Modal from './../components/common/Modal.vue'

export default {
  components: { Modal },
  props: {
    records: {
      required: true,
      type: Object,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return { acceptTerms: false }
  },
  validations() {
    return {
      acceptTerms: { checked: (value) => value === true },
    }
  },
}
</script>
