<template>
  <transition name="slide-left-to-right">
    <section v-if="showMenu" class="s-menumobile d-block d-lg-none">
      <div class="s-menumobile__main-menu mb-80">
        <ul>
          <li v-for="item in menuLinks" :key="item.id">
            <Anchor
              :item="item"
              class="h2"
              :class="{
                'has-submenu': hasSubmenu(item),
                'is-active': menuIsActive(item),
              }">
              <i
                class="icon-triangle-down"
                @click.prevent="($event) => onClickCaret(item)"></i>
            </Anchor>

            <ul v-if="menuIsActive(item)" v-auto-animate>
              <li v-for="submenu in getSubMenu(item)" :key="submenu.id">
                <div
                  class="h2"
                  :title="submenu.text"
                  @click.stop="($event) => onClick2ndLevel(submenu)">
                  {{ submenu.text }}
                </div>
              </li>
            </ul>
          </li>

          <li>
            <div
              class="href has-submenu"
              :class="{ 'is-active': bookingIsActive }"
              @click="onClickBookings">
              {{ bookingLabel }}
              <i class="icon-triangle-down"></i>
            </div>

            <ul v-if="bookingIsActive" v-auto-animate>
              <li v-for="item in bookingLinks" :key="item.id">
                <Anchor :item="item" class="h2" />
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="s-menumobile__global-label h4 mb-10 extrabold">
        {{ globalLabel }}
      </div>

      <div class="s-menumobile__global mb-120">
        <ul>
          <li v-for="item in globalLinks" :key="item.id">
            <a
              :href="item.url"
              :title="item.text"
              :target="item.target"
              @click.stop="($event) => onClick2ndLevel(item)">
              {{ item.text }}
            </a>
          </li>
        </ul>
      </div>

      <div class="s-menumobile__extras">
        <ul>
          <li v-for="item in extraLinks" :key="item.id">
            <Anchor :item="item" class="h3 bold" />
          </li>
        </ul>
      </div>
    </section>
  </transition>

  <transition name="slide">
    <section v-if="showAside" class="s-menumobile-aside d-block d-lg-none">
      <div class="mb-60">
        <button
          type="button"
          class="btn btn-link-dark btn-icon-start btn-back"
          @click="onClickBack">
          <i class="icon-arrow-long-left"></i>
          <span>{{ backText }}</span>
        </button>
      </div>

      <ul class="s-menumobile-aside__cards">
        <li v-for="card in cards" :key="card.id">
          <MenuCard :item="card" />
        </li>
      </ul>
      <ul class="s-menumobile-aside__cards">
        <li v-for="offer in offers" :key="offer.id">
          <MenuOffer :item="offer" :image-contain="offer.imageContain" />
        </li>
      </ul>
      <ul class="s-menumobile-aside__vehicles">
        <li v-for="vehicle in vehicles" :key="vehicle.id">
          <MenuVehicle :item="vehicle" />
        </li>
      </ul>
    </section>
  </transition>
</template>

<script>
import jQuery from 'jquery'
import Breakpoint from 'bootstrap-breakpoints'
import * as lib from '../../lib/lib'
import Anchor from './../components/common/Anchor.vue'
import MenuCard from './../components/MenuCard.vue'
import MenuOffer from './../components/MenuOffer.vue'
import MenuVehicle from './../components/MenuVehicle.vue'
import constants from './../../lib/constants'

export default {
  components: { Anchor, MenuCard, MenuOffer, MenuVehicle },
  data() {
    return {
      showMenu: false,

      menuLinks: null,
      bookingLabel: null,
      bookingLinks: null,
      globalLabel: null,
      globalLinks: null,
      extraLinks: null,
      bookingIsActive: false,

      pathIds: [],
      pathTexts: [],

      backText: '',
      cards: [],
      offers: [],
      vehicles: [],
    }
  },
  computed: {
    showAside() {
      return (
        this.showMenu &&
        (this.cards.length || this.offers.length || this.vehicles.length)
      )
    },
  },
  mounted() {
    // prettier-ignore
    const { menuLinks, bookingLinks, globalLabel, bookingLabel, globalLinks, extraLinks } = lib.getData('menuData')
    this.menuLinks = menuLinks
    this.bookingLabel = bookingLabel
    this.bookingLinks = bookingLinks
    this.globalLabel = globalLabel
    this.globalLinks = globalLinks
    this.extraLinks = extraLinks

    document.addEventListener('burgerMobileClick', (e) => {
      if (e.detail.status === constants.menu.open) {
        this.showMenu = true
      } else {
        this.showMenu = false
      }
    })

    jQuery(window).on('resize', () => {
      const body = jQuery('body')

      Breakpoint.init()

      if (Breakpoint.is('md') || Breakpoint.is('lg')) {
        this.showMenu = false
        body.removeClass(constants.body.overlayClassname)
        body.removeClass(constants.body.activeClassname)
      }
    })
  },

  methods: {
    getSubMenu(item) {
      return this.menuLinks.find((i) => i.id === item.id).subMenu
    },

    menuIsActive(item) {
      return this.pathIds.includes(item.id)
    },

    hasSubmenu(item) {
      return item.subMenu.length
    },

    onClickBookings() {
      this.bookingIsActive = !this.bookingIsActive
    },

    onClickCaret(item) {
      if (this.menuIsActive(item)) {
        this.onClickBack()
        return false
      }

      this.pathIds.push(item.id)
      this.pathTexts.push(item.text)
    },

    onClick2ndLevel(item) {
      this.backText = item.text
      this.cards = item.cards ?? []
      this.vehicles = item.vehicles ?? []
      this.offers = item.offers ?? []
      this.pathIds.push(item.id)
      this.pathTexts.push(item.text)
    },

    onClickBack() {
      this.backText = ''
      this.cards = this.vehicles = this.offers = []
      this.pathIds.pop()
      this.pathTexts.pop()
    },
  },
}
</script>
