const constants = {
  tokenFormId: 'RequestVerificationTokenForm',
  tokenInputSelector: 'input[name="__RequestVerificationToken"]',
  placeholderImage: '/imgs/misc/placeholder-image.png',
  location: { departure: 'departure', return: 'return' },
  crediCards: {
    visa: 'visa',
    amex: 'amex',
    mastercard: 'mastercard',
  },
  extras: {
    manually: 'M',
    auto: 'A',
  },
  paymentType: {
    prepaid: 'prepaid',
    return: 'return',
  },
  breakpoints: {
    desktops: 'desktops',
    devices: 'devices',
  },
  body: {
    activeClassname: 'menu-open',
    overlayClassname: 'has-overlay',
  },
  menu: {
    open: 'open',
    close: 'close',
  },
  view: {
    grid: 'grid',
    list: 'list',
  },
  offerTypes: {
    CDP: 'CDP',
    offerCode: 'OfferCode',
  },
}

export default constants
