<template>
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 id="exampleModalLabel" class="modal-title">
            {{ title }}
          </h4>
          <button
            type="button"
            class="btn-close btn-clear btn-link"
            data-bs-dismiss="modal"
            aria-label="Close">
            <i class="icon-close-light"></i>
          </button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    title: {
      required: false,
      type: String,
      default: '',
    },
  },
}
</script>
