<template>
  <form autocomplete="off" @submit.prevent="onSubmitForm">
    <section class="b-booking-bar">
      <div v-auto-animate class="page-container">
        <Alert v-if="errorMsgs.length" type="danger" :messages="errorMsgs" />

        <div class="row">
          <div class="col mb-60 mb-lg-20">
            <div class="form-check form-switch">
              <label class="form-check-label" for="differentReturn">
                <span>{{ dictionary.differentReturnLabel }}</span>
              </label>
              <input
                id="differentReturn"
                v-model="differentReturn"
                class="form-check-input"
                type="checkbox"
                role="switch"
                @change="onChangeDifferentReturn" />
            </div>
          </div>
        </div>

        <div class="row gx-4">
          <div class="col-xl-6 mb-15">
            <div class="row gx-4">
              <div
                v-auto-animate
                :class="[differentReturn ? 'col-lg-6 mb-15 mb-lg-0' : 'col']">
                <div
                  class="form-pseudo-floating pb-0 b-booking-pick-location"
                  :class="[
                    v$.locationDeparture.$error ? 'has-error' : '',
                    differentReturn ? 'has-different-return' : '',
                  ]"
                  @click="onClickLocationDeparture">
                  <label
                    for="searchLocationDeparture"
                    class="form-label"
                    @click.stop="onClickLocationDeparture">
                    <i class="icon-pin"></i>
                    <span>{{ dictionary.locationDepartureLabel }}</span>
                    <button
                      class="btn btn-info btn-icon-end btn-network"
                      type="button"
                      @click.stop="onClickNetworkDeparture">
                      <small>{{ dictionary.buttonNetworkText }}</small>
                      <i class="icon-pin-network"></i>
                    </button>
                  </label>

                  <MultiSelect
                    id="searchLocationDeparture"
                    ref="multiselectLocationDeparture"
                    v-model="locationDeparture"
                    autocomplete="off"
                    :searchable="true"
                    :clear-on-search="true"
                    :can-clear="false"
                    :options="locations"
                    :can-deselect="false"
                    :no-results-text="dictionary.noResultsText"
                    value-prop="Value"
                    label="Label"
                    :placeholder="dictionary.searchLocationPlaceholder"
                    :filter-results="false"
                    :no-options-text="dictionary.noOptionsText"
                    @search-change="onSearchChange"
                    @click.stop />
                </div>

                <BookingBarNetwork
                  v-if="showNetworkDeparture"
                  type="departure"
                  @change-location="onChangeLocation" />
              </div>
              <div v-if="differentReturn" v-auto-animate class="col-lg-6">
                <div
                  class="form-pseudo-floating pb-0 b-booking-pick-location"
                  :class="[
                    v$.locationReturn.$error ? 'has-error' : '',
                    differentReturn ? 'has-different-return' : '',
                  ]"
                  @click="onClickLocationReturn">
                  <label
                    for="searchLocationReturn"
                    class="form-label"
                    @click.stop="onClickLocationReturn">
                    <i class="icon-pin"></i>
                    <span>{{ dictionary.locationReturnLabel }}</span>
                    <button
                      class="btn btn-info btn-icon-end btn-network"
                      type="button"
                      @click.stop="onClickNetworkReturn">
                      <small>{{ dictionary.buttonNetworkText }}</small>
                      <i class="icon-pin-network"></i>
                    </button>
                  </label>

                  <MultiSelect
                    id="searchLocationReturn"
                    ref="multiselectLocationReturn"
                    v-model="locationReturn"
                    autocomplete="off"
                    :searchable="true"
                    :clear-on-search="true"
                    :can-clear="false"
                    :options="locations"
                    :can-deselect="false"
                    :no-results-text="dictionary.noResultsText"
                    value-prop="Value"
                    label="Label"
                    :placeholder="dictionary.searchLocationPlaceholder"
                    :filter-results="false"
                    :no-options-text="dictionary.noOptionsText"
                    @search-change="onSearchChange"
                    @click.stop />
                </div>

                <BookingBarNetwork
                  v-if="showNetworkReturn"
                  type="return"
                  @change-location="onChangeLocation" />
              </div>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="row gx-3 gx-lg-4">
              <div class="col-6 col-lg-4 mb-15">
                <BookingBarDatePicker
                  :key="'departure' + key"
                  type="departure"
                  :label="dictionary.dateDepartureLabel"
                  :hour-label="dictionary.hourDepartureLabel"
                  :selected-date="dateDepartureFormated"
                  :selected-hour="hourDeparture"
                  :selected-minutes="minutesDeparture"
                  :hour-data-source="hourDataSource"
                  :minutes-data-source="minutesDataSource"
                  :has-error="v$.dateDeparture.$error"
                  @change-clock="onChangeClock" />
              </div>
              <div class="col-6 col-lg-4 mb-15">
                <BookingBarDatePicker
                  :key="'return' + key"
                  type="return"
                  :label="dictionary.dateReturnLabel"
                  :hour-label="dictionary.hourReturnLabel"
                  :selected-date="dateReturnFormated"
                  :selected-hour="hourReturn"
                  :selected-minutes="minutesReturn"
                  :hour-data-source="hourDataSource"
                  :minutes-data-source="minutesDataSource"
                  :has-error="v$.dateReturn.$error"
                  :date-departure="dateDeparture"
                  dropdown-align="right"
                  @change-clock="onChangeClock" />
              </div>
              <div class="col col-lg-4 mb-15">
                <button
                  v-if="!additionalInfoCheck"
                  type="submit"
                  class="btn btn-outline-primary btn-full-width"
                  :class="{ 'is-loading': isLoading }"
                  :disabled="isLoading">
                  <Loader
                    v-if="isLoading"
                    display="absolute"
                    size="sm"></Loader>
                  <span>{{ buttonSubmitText }}</span>
                </button>

                <div class="d-none d-lg-flex justify-content-end">
                  <button
                    v-if="additionalInfoCheck"
                    type="button"
                    class="btn btn-icon-end btn-link-dark"
                    @click="onClickClose">
                    {{ dictionary.buttonAdditionalInfoCloseText }}
                    <i class="icon-close-light"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row gx-5">
          <div class="col-lg" :class="{ 'mb-15': additionalInfoCheck }">
            <div class="form-check">
              <input
                id="additionalInfoCheck"
                v-model="additionalInfoCheck"
                name="additionalInfoCheck"
                type="checkbox"
                class="form-check-input"
                autocomplete="off" />
              <label
                class="form-check-label form-check-label--tooltip"
                for="additionalInfoCheck"
                data-bs-toggle="tooltip"
                :title="dictionary.additionalInfoTooltipText">
                <span>{{ dictionary.additionalInfoLabel }}</span>
                <LazyImage
                  :image="{ url: '/imgs/icons/tooltip.negative.svg' }"
                  width="16" />
              </label>
            </div>
          </div>
        </div>

        <div v-if="additionalInfoCheck">
          <div class="b-booking-bar__separator mb-20"></div>

          <div class="row gx-5">
            <div v-auto-animate class="col-lg-10">
              <div v-for="(item, index) in selectedOffers" :key="item.id">
                <BookingBarOffer
                  :key="'offer' + index + key"
                  :item="item"
                  :index="index"
                  :selected-offers="selectedOffers"
                  @remove-offer="onRemoveOffer"
                  @change-offer-data="onChangeOfferData" />
              </div>

              <div v-if="showAddOffer" class="row">
                <div class="col d-flex mb-15 mb-lg-0">
                  <button
                    type="button"
                    class="btn btn-link-dark btn-icon-start"
                    @click="onClickAddOffer">
                    <i class="icon-addthis"></i>
                    <span>{{ dictionary.buttonOfferAddText }}</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-2">
              <button
                type="submit"
                class="btn btn-outline-primary btn-full-width"
                :class="{ 'is-loading': isLoading }"
                :disabled="isLoading">
                <Loader v-if="isLoading" display="absolute" size="sm"></Loader>
                <span>{{ buttonSubmitText }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="e-booking-bar-toggle d-lg-none">
        <button type="button" class="btn btn-full-width dropdown-toggle">
          <span>{{ dictionary.buttonToogleText }}</span>
        </button>
      </div>
    </section>
  </form>

  <BookingBarModalValidations :errors="validationErrors" />
</template>

<script>
import constants from './../../lib/constants'
import moment from 'moment'
import * as lib from './../../lib/lib'
import mixins from '../mixins/mixins'
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import { Modal } from 'bootstrap'
import { v4 as uuidv4 } from 'uuid'
import Alert from '../components/common/Alert.vue'
import Loader from '../components/common/Loader.vue'
import LazyImage from './../components/common/LazyImage.vue'
import MultiSelect from '@vueform/multiselect'
import BookingBarNetwork from './../components/BookingBarNetwork.vue'
import BookingBarDatePicker from './../components/BookingBarDatePicker.vue'
import BookingBarModalValidations from './../components/BookingBarModalValidations.vue'
import BookingBarOffer from './../components/BookingBarOffer.vue'
import BookingBarSticky from './../../modules/BookingBarSticky'

class Offer {
  constructor(type = '', code = '') {
    this.id = uuidv4()
    this.type = type
    this.code = code
  }
}

export default {
  components: {
    Loader,
    Alert,
    LazyImage,
    MultiSelect,
    BookingBarNetwork,
    BookingBarModalValidations,
    BookingBarDatePicker,
    BookingBarOffer,
  },
  mixins: [mixins],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      key: uuidv4(),
      isLoading: false,
      errorMsgs: [],
      modalValidations: null,
      validationErrors: [],
      // form properties
      differentReturn: false,
      additionalInfoCheck: false,

      locationDeparture: '',
      locationReturn: '',

      countryDeparture: '',
      countryReturn: '',

      dateDeparture: null,
      dateDepartureOneDayAfter: null,
      dateReturn: null,

      hourDeparture: '',
      hourReturn: '',

      minutesDeparture: '',
      minutesReturn: '',

      vehiclesType: '',

      // data sources
      countries: [],
      cities: [],
      locations: [],
      hourDataSource: [],
      minutesDataSource: [],
      selectedOffers: [new Offer()],
      // helpers
      showNetworkDeparture: false,
      showNetworkReturn: false,
      searchMinLength: 3,
      preselectedGroup: '',
      preselectedCDP: false,
    }
  },
  validations() {
    return {
      locationDeparture: { required },
      locationReturn: { requiredIf: requiredIf(this.differentReturn) },
      dateDeparture: { required },
      dateReturn: { required },
      hourDeparture: { required },
      hourReturn: { required },
      minutesDeparture: { required },
      minutesReturn: { required },
    }
  },
  computed: {
    buttonSubmitText() {
      const { buttonSubmitText, buttonSubmitAltText } = this.dictionary
      return this.vehiclesType && this.preselectedGroup
        ? buttonSubmitAltText
        : buttonSubmitText
    },

    dateDepartureFormated() {
      return this.dateDeparture
        ? moment(this.dateDeparture, this.configuration.dateFormat).toDate()
        : null
    },

    dateReturnFormated() {
      return this.dateReturn
        ? moment(this.dateReturn, this.configuration.dateFormat).toDate()
        : null
    },

    showAddOffer() {
      return this.selectedOffers.length < 2
    },
  },
  watch: {
    locationDeparture(newValue) {
      const country = this.getLocationCountry(newValue)
      this.countryDeparture = country
    },

    locationReturn(newValue) {
      const country = this.getLocationCountry(newValue)
      this.countryReturn = country
    },
  },
  mounted() {
    const { hours, minutes } = lib.getData('bookingData')
    this.hourDataSource = hours
    this.minutesDataSource = minutes

    this.setPreselectedOptions()
    this.setPreselectedValues()
    // do not remove line below
    this.key = uuidv4()

    this.modalValidations = new Modal(
      document.getElementById('modalValidations')
    )

    this.$nextTick(() => {
      new BookingBarSticky(document.querySelector('.b-booking-bar'))
    })
  },

  methods: {
    onClickLocationDeparture() {
      this.$refs.multiselectLocationDeparture.open()
      document.getElementById('searchLocationDeparture').focus()
    },

    onClickLocationReturn() {
      this.$refs.multiselectLocationReturn.open()
      document.getElementById('searchLocationReturn').focus()
    },

    getCDPValue() {
      const { CDP } = constants.offerTypes
      const entry = this.selectedOffers.find(
        (i) => i.type.toLowerCase() === CDP.toLowerCase()
      )

      return entry ? entry.code : ''
    },

    getOfferCodeValue() {
      const { offerCode } = constants.offerTypes
      const entry = this.selectedOffers.find(
        (i) => i.type.toLowerCase() === offerCode.toLowerCase()
      )

      return entry ? entry.code : ''
    },

    onRemoveOffer(id) {
      this.selectedOffers = this.selectedOffers.filter((i) => i.id !== id)
    },

    onChangeOfferData(index, type, code) {
      this.selectedOffers[index].type = type
      this.selectedOffers[index].code = code
    },

    onClickAddOffer() {
      this.selectedOffers.push(new Offer())
    },

    setPreselectedValues() {
      const {
        panelExpanded,
        pickupLocationCode,
        pickupLocationCountryCode,
        pickupDate,
        pickupHour,
        pickupMinutes,
        returnLocationCode,
        returnLocationCountryCode,
        returnDate,
        returnHour,
        returnMinutes,
        selectPickupLocation,
        selectReturnLocation,
        vehiclesType,
        CDP,
        offerCode,

        preselectedGroup,
        preselectedCDP,
      } = lib.getData('bookingData')

      if (!panelExpanded) {
        return false
      }

      if (pickupLocationCode) {
        this.locationDeparture = pickupLocationCode
        this.countryDeparture = pickupLocationCountryCode

        this.locations.push({
          Country: pickupLocationCountryCode,
          Value: pickupLocationCode,
          Label: selectPickupLocation[0].Text,
        })

        this.dateDeparture = moment(pickupDate).toDate()
        this.hourDeparture = pickupHour
        this.minutesDeparture = pickupMinutes

        this.locationReturn = returnLocationCode
        this.countryReturn = returnLocationCountryCode

        if (pickupLocationCode !== returnLocationCode) {
          this.differentReturn = true

          this.locations.push({
            Country: returnLocationCountryCode,
            Value: returnLocationCode,
            Label: selectReturnLocation[0].Text,
          })
        }

        this.dateReturn = moment(returnDate).toDate()
        this.hourReturn = returnHour
        this.minutesReturn = returnMinutes
      }

      if (offerCode || CDP) {
        this.additionalInfoCheck = true
        this.selectedOffers.pop()
      }
      if (offerCode) {
        this.offerCode = offerCode
        this.selectedOffers.push(
          new Offer(constants.offerTypes.offerCode, offerCode)
        )
      }
      if (CDP) {
        this.CDP = CDP
        this.selectedOffers.push(new Offer(constants.offerTypes.CDP, CDP))
      }
      if (vehiclesType) {
        this.vehiclesType = vehiclesType
      }

      this.preselectedGroup = preselectedGroup
      this.preselectedCDP = preselectedCDP

      // do not remove line below
      this.key = uuidv4()
    },

    setPreselectedOptions() {
      const { hours, minutes } = lib.getData('bookingData')

      // hours
      const entry1 = hours.find((i) => i.selected)
      if (entry1) {
        this.hourDeparture = this.hourReturn = entry1.value
      }
      // minutes
      const entry2 = minutes.find((i) => i.selected)
      if (entry2) {
        this.minutesDeparture = this.minutesReturn = entry2.value
      }
    },

    onChangeClock(date, hour, minutes, type) {
      if (type === constants.location.departure) {
        this.dateDeparture = date
        this.hourDeparture = hour
        this.minutesDeparture = minutes
        this.setDateReturnOneDayAfter()
        // do not remove line below
        this.key = uuidv4()
      }
      if (type === constants.location.return) {
        this.dateReturn = date
        this.hourReturn = hour
        this.minutesReturn = minutes
      }
    },

    setDateReturnOneDayAfter() {
      this.dateDepartureOneDayAfter = moment(this.dateDeparture)
        .add(1, 'd')
        .toDate()

      if (!this.dateReturn || this.dateDeparture >= this.dateReturn) {
        this.dateReturn = this.dateDepartureOneDayAfter
      }
    },

    onChangeDifferentReturn() {
      if (!this.differentReturn) {
        this.locationReturn = this.countryReturn = null
      }
    },

    setValidationErrors() {
      const array = []
      const {
        locationDepartureRequired,
        locationReturnRequired,
        dateDepartureRequired,
        dateReturnRequired,
      } = this.dictionary

      if (this.v$.locationDeparture.required.$invalid) {
        array.push(locationDepartureRequired)
      }

      if (this.v$.locationReturn.requiredIf.$invalid) {
        array.push(locationReturnRequired)
      }

      if (this.v$.dateDeparture.required.$invalid) {
        array.push(dateDepartureRequired)
      }

      if (this.v$.dateReturn.required.$invalid) {
        array.push(dateReturnRequired)
      }

      this.validationErrors = array
    },

    onSearchChange(query) {
      if (query.length < this.searchMinLength) {
        return false
      }

      const postData = { term: query }
      axios
        .post(this.configuration.endpointSearchLocations, postData)
        .then((response) => {
          const { Locations } = response.data
          this.locations = Locations
          this.handleSuccess()
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    onChangeLocation(value, options, type) {
      this.locations = options

      if (type === constants.location.departure) {
        this.locationDeparture = value
        this.showNetworkDeparture = false
      }
      if (type === constants.location.return) {
        this.locationReturn = value
        this.showNetworkReturn = false
      }
    },

    getLocationCountry(value) {
      const entry = this.locations.find((i) => i.Value === value)
      return entry ? entry.Country : ''
    },

    onSubmitForm() {
      this.v$.$validate()

      if (this.v$.$invalid) {
        this.setValidationErrors()
        this.modalValidations.show()
        return false
      }

      if (this.isLoading) {
        return false
      }

      this.isLoading = true

      const postData = {
        PickupLocationCode: this.locationDeparture,
        PickupLocationCountryCode: this.countryDeparture,
        PickupDate: this.dateDeparture,
        PickupHour: this.hourDeparture,
        PickupMinutes: this.minutesDeparture,
        ReturnLocationCode: this.locationReturn,
        ReturnLocationCountryCode: this.countryReturn,
        ReturnDate: this.dateReturn,
        ReturnHour: this.hourReturn,
        ReturnMinutes: this.minutesReturn,
        VehiclesType: this.vehiclesType,
        OfferCode: this.getOfferCodeValue(),
        CDP: this.getCDPValue(),
        // legacy property
        Carrier: '',
        // legacy property
        FFNCard: '',
        PreselectedGroup: this.preselectedGroup,
        PreselectedCDP: this.preselectedCDP,
      }

      axios
        .post(this.configuration.endpointSubmitData, postData)
        .then((response) => {
          const { HasError, Errors, Url } = response.data

          // response has error
          if (HasError) {
            this.handleError(Errors)
            return false
          }

          window.location.assign(Url)
          this.handleSuccess()
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    handleSuccess() {
      this.errorMsgs = []
    },

    handleError(messages) {
      this.errorMsgs = messages
      this.isLoading = false
    },

    onClickClose() {
      this.additionalInfoCheck = false
    },

    onClickNetworkDeparture() {
      this.showNetworkDeparture = !this.showNetworkDeparture
    },

    onClickNetworkReturn() {
      this.showNetworkReturn = !this.showNetworkReturn
    },
  },
}
</script>
