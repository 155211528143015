<template>
  <span
    v-if="item.Guaranteed && item.IsRequest"
    class="e-onrequest"
    data-bs-toggle="tooltip"
    :title="dictionary.onRequestTooltipText">
    {{ dictionary.onRequestGuaranteedLabel }}
    <span>
      <LazyImage :image="{ url: '/imgs/icons/tooltip.svg' }" />
    </span>
  </span>
  <span v-else-if="item.Guaranteed" class="e-guaranteed">
    {{ dictionary.guaranteedLabel }}
  </span>
  <span
    v-else-if="item.IsRequest"
    class="e-onrequest"
    data-bs-toggle="tooltip"
    :title="dictionary.onRequestTooltipText">
    {{ dictionary.onRequestLabel }}
    <span>
      <LazyImage :image="{ url: '/imgs/icons/tooltip.svg' }" />
    </span>
  </span>
</template>

<script>
import LazyImage from './common/LazyImage.vue'
import * as lib from './../../lib/lib'

export default {
  components: { LazyImage },
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  mounted() {
    this.$nextTick(() => {
      lib.iniTooltips(document)
    })
  },
}
</script>
