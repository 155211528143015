<template>
  <!--Extras Case-1-->
  <div
    id="modalValidations"
    class="modal s-modal s-modal--warning"
    tabindex="-1"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div><i class="icon-warning"></i></div>
            <div>{{ dictionary.modalValidationsTitle }}</div>
          </div>
          <button
            type="button"
            class="btn-close btn-clear btn-link"
            data-bs-dismiss="modal"
            aria-label="Close">
            <i class="icon-close-light"></i>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ dictionary.modalValidationsErrorsPrefix }}</p>
          <ul class="errors-list">
            <li v-for="(item, index) in errors" :key="index">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      required: true,
      type: Array,
    },
  },
}
</script>

<style scoped lang="scss">
.errors-list {
  list-style: inside;
}
</style>
