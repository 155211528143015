/**
 * add accordion functionality for menu items to .s-footer selector
 */
import jQuery from 'jquery'
import { scrDevices } from './../lib/lib'

export default class Promo {
  constructor(element) {
    if (!element) {
      return false
    }

    this.$element = jQuery(element)
    this.summary = jQuery('.b-promo__summary', this.$element)
    this.hoverLayer = jQuery('.hover-layer', this.$element)
    this.myTimer = false

    this.hoverLayer.on('mouseenter', () => {
      if (scrDevices()) {
        return false
      }

      this.myTimer = setTimeout(() => {
        this.summary.slideDown('fast')
      }, 150)
    })

    this.hoverLayer.on('mouseleave', () => {
      if (scrDevices()) {
        return false
      }

      clearTimeout(this.myTimer)

      this.myTimer = setTimeout(() => {
        this.summary.slideUp('fast')
      }, 150)
    })
  }
}
