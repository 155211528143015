<template>
  <div ref="strip" class="s-fleet">
    <div class="page-container">
      <Loader v-if="isLoading" display="absolute"></Loader>
      <Alert
        v-if="errorMsgs.length"
        type="danger"
        :messages="errorMsgs"></Alert>

      <div class="s-fleet__progress-bar d-flex d-lg-none">
        <ProgressBar :percentage="percentage" />
      </div>

      <div class="s-fleet__header mb-40">
        <div class="h2">Επιλογές ενοικίασης για</div>
        <div class="s-fleet__tools">
          <div class="s-fleet__countries">
            <select
              v-model="country"
              class="form-select"
              @change="onChangeCountry">
              <option
                v-for="item in countries"
                :key="item.value"
                :value="item.value">
                {{ item.text }}
              </option>
            </select>
          </div>
          <div class="s-fleet__my-filters">
            <span class="semibold">Φίλτρα</span>

            <FleetFilters
              v-if="types.length"
              :types="types"
              @submit-filters="onSubmitFilters" />
          </div>
        </div>
      </div>

      <!-- desktop -->
      <div class="d-none d-lg-block">
        <div
          v-if="recordsCountFiltered"
          v-auto-animate
          class="s-fleet__grid mb-60">
          <div
            v-for="item in recordsFiltered"
            :key="item.id"
            class="s-fleet__item">
            <Vehicle :item="item" />
          </div>
        </div>
      </div>

      <!-- devices -->
      <div class="d-block d-lg-none">
        <SlickCarousel
          v-if="recordsCountFiltered"
          ref="slick"
          :key="slickKey"
          class="s-fleet__slick mb-60"
          :options="slickOptions"
          @init="onInit"
          @beforechange="onBeforeChange">
          <div
            v-for="(item, index) in recordsFiltered"
            :key="index"
            class="s-fleet__slide item">
            <Vehicle :item="item" />
          </div>
        </SlickCarousel>
      </div>

      <div v-if="!recordsCountFiltered">
        <NoResults></NoResults>
      </div>
    </div>

    <div class="page-container">
      <div class="s-fleet__footer">
        <Anchor
          v-if="viewMoreLink"
          :item="viewMoreLink"
          class="btn btn-outline-primary" />
      </div>
    </div>
  </div>
</template>

<script>
import * as lib from '../../lib/lib'
import axios from 'axios'
import Vehicle from '../components/Vehicle.vue'
import Loader from './../components/common/Loader.vue'
import Alert from './../components/common/Alert.vue'
import NoResults from './../components/common/NoResults.vue'
import Anchor from './../components/common/Anchor.vue'
import FleetFilters from './../components/FleetFilters.vue'
import ProgressBar from './../components/common/ProgressBar.vue'
import SlickCarousel from '../components/common/SlickCarousel.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    ProgressBar,
    SlickCarousel,
    FleetFilters,
    Anchor,
    Alert,
    Loader,
    NoResults,
    Vehicle,
  },

  data() {
    return {
      pageSize: 9,
      percentage: 0,
      isLoading: false,
      errorMsgs: [],
      records: [],
      recordsCount: null,
      recordsFiltered: [],

      viewMoreLink: null,
      countries: [],
      country: '',
      types: [],
      slickKey: uuidv4(),
      slickOptions: {
        infinite: false,
        autoplay: false,
        arrows: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      },
    }
  },
  computed: {
    recordsCountFiltered() {
      return this.recordsFiltered.length
    },
  },
  mounted() {
    const { types, countries, viewMoreLink } = lib.getData('fleetData')
    this.types = types
    this.countries = countries
    this.viewMoreLink = viewMoreLink
    this.setPreselectedCountry()
    this.fetchData()
  },
  methods: {
    onSubmitFilters(categories) {
      if (!categories.length) {
        this.recordsFiltered = this.records
        this.slickKey = uuidv4()
        return false
      }
      // https://stackoverflow.com/questions/16312528/check-if-an-array-contains-any-element-of-another-array-in-javascript
      this.recordsFiltered = this.records.filter((i) =>
        i.Categories.split('|').some((r) => categories.indexOf(r) >= 0)
      )
      this.slickKey = uuidv4()
    },

    setPreselectedCountry() {
      const entry = this.countries.find((i) => i.selected)
      this.country = entry ? entry.value : ''
    },

    onChangeCountry() {
      this.fetchData()
    },

    fetchData() {
      this.isLoading = true

      const postData = {
        countryCode: this.country,
      }
      axios
        .post(this.configuration.endpointFetchData, postData)
        .then((response) => {
          const { hasError, errorMessages, CarsView } = response.data
          // response has error
          if (hasError) {
            this.handleError(errorMessages)
            return false
          }
          const carsViewPaged = CarsView.slice(0, this.pageSize)
          this.records = this.recordsFiltered = carsViewPaged
          this.recordsCount = this.records.length

          this.handleSuccess()
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    handleSuccess() {
      this.errorMsgs = []
      this.isLoading = false
    },

    handleError(messages) {
      this.errorMsgs = messages
      this.isLoading = false
    },

    onInit(event, slick) {
      this.percentage = lib.getSlickProgressBarPercentage(slick)
    },
    onBeforeChange(event, slick) {
      setTimeout(() => {
        this.percentage = lib.getSlickProgressBarPercentage(slick)
      }, 100)
    },
  },
}
</script>
