<template>
  <Vue3Lottie :animation-data="json" />
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import json from './../../../../imgs/lotties/Idea.json'

export default {
  components: { Vue3Lottie },
  data() {
    return { json }
  },
}
</script>
