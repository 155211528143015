<template>
  <!--Extras Case-2-->
  <div
    id="modalCase2"
    class="modal s-modal s-modal--warning"
    tabindex="-1"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div><i class="icon-warning"></i></div>
            <div>{{ dictionary.modalCase2Title }}</div>
          </div>
          <button
            type="button"
            class="btn-close btn-clear btn-link"
            data-bs-dismiss="modal"
            aria-label="Close">
            <i class="icon-close-light"></i>
          </button>
        </div>
        <div class="modal-body">
          <div v-html="text"></div>
        </div>
        <div class="modal-footer">
          <ul>
            <li>
              <button
                type="button"
                class="btn btn-outline-danger btn-full-width"
                @click="onClickUndo">
                {{ dictionary.modalCase2ButtonUndoText }}
              </button>
            </li>
            <li>
              <button
                type="button"
                class="btn btn-primary btn-full-width"
                @click="onClickSubmit">
                {{ dictionary.modalCase2ButtonSubmitText }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as lib from './../../lib/lib'

export default {
  props: {
    master: {
      required: false,
      type: String,
      default: '',
    },
    child: {
      required: false,
      type: String,
      default: '',
    },
    exemption: {
      required: false,
      type: String,
      default: '',
    },
  },
  emits: ['submit', 'undo'],
  data() {
    return { records: [] }
  },
  computed: {
    text() {
      const value1 = `<strong>${this.childTitle}</strong>`
      const value2 = `<strong>${this.masterTitle}</strong>`
      const value3 = `<span>${this.exemption}</span>`

      return this.dictionary.modalCase2Text
        .replace('{0}', value1)
        .replace('{1}', value2)
        .replace('{2}', value3)
    },

    masterTitle() {
      return this.master
        ? this.records.find((i) => i.code === this.master).description
        : ''
    },
    childTitle() {
      return this.child
        ? this.records.find((i) => i.code === this.child).description
        : ''
    },
  },
  mounted() {
    const { amenitiesDefinitions } = lib.getData('bookingData')
    this.records = amenitiesDefinitions
  },
  methods: {
    onClickUndo() {
      this.$emit('undo', this.child)
    },

    onClickSubmit() {
      this.$emit('submit', this.master, this.child)
    },
  },
}
</script>
