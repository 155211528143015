<template>
  <div
    v-auto-animate
    class="b-accordion-booking-aside"
    :class="{ 'is-active': isActive }">
    <div class="b-accordion-booking-aside__header h3" @click="onClickHeader">
      <span>{{ dictionary.asideHeaderTitle }}</span>
      <i class="icon-arrow-dropdown-down"></i>
    </div>
    <div v-if="isActive" class="b-accordion-booking-aside__body">
      <slot></slot>
    </div>
  </div>

  <div
    v-if="!isActive"
    class="b-vehicle-preview d-print-none"
    @click="onClickHeader">
    <div class="b-vehicle-preview__content">
      <div class="b-vehicle__tagged">
        <VehicleTag :item="item" />
      </div>

      <div class="b-vehicle-preview__body mb-20">
        <div class="b-vehicle-preview__aside">
          <LazyImage v-if="image" :image="image" />
        </div>

        <div class="b-vehicle-preview__main">
          <div class="b-vehicle-preview__title h4 extrabold">
            {{ item.Model }}
          </div>
          <div class="b-vehicle-preview__groups mb-10 semibold">
            <ul>
              <li data-bs-toggle="tooltip" :title="item.AcrissData">
                {{ item.GroupAcrissTxt }}
                <span>
                  <LazyImage :image="{ url: '/imgs/icons/tooltip.svg' }" />
                </span>
              </li>
              <li class="separator"></li>
              <li>{{ item.CategoryNames }}</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="b-vehicle-preview__footer">
        <span>{{ dictionary.asideCostTotal }}</span>
        <span class="price" v-html="payment?.TotalCost"></span>
      </div>
    </div>
  </div>
</template>

<script>
import LazyImage from './../components/common/LazyImage.vue'
import VehicleTag from './VehicleTag.vue'
import * as lib from './../../lib/lib'

export default {
  components: { LazyImage, VehicleTag },
  props: {
    item: {
      required: true,
      type: Object,
    },
    payment: {
      required: true,
      type: Object,
    },
  },
  data() {
    return { isActive: false }
  },
  computed: {
    image() {
      const images = this.item.Images
      if (!images.length) {
        return null
      }
      return {
        url: images[0],
        alt: '',
      }
    },
  },

  methods: {
    onClickHeader() {
      this.isActive = !this.isActive

      this.$nextTick(() => {
        lib.iniTooltips(document)
      })
    },
  },
}
</script>
