<template>
  <div v-if="details" class="b-booking-upsell mb-80">
    <div class="b-booking-upsell__success">
      <i class="icon-check-circle"></i>
      <div class="p" v-html="details.vehicleDescription"></div>
    </div>
  </div>

  <form v-if="info" autocomplete="off" @submit.prevent="onSubmitForm">
    <Alert v-if="errorMsgs.length" type="danger" :messages="errorMsgs"></Alert>

    <div class="b-booking-upsell mb-80">
      <Loader v-if="isLoading" display="absolute"></Loader>

      <div class="b-booking-upsell__header" @click="onClickHeader">
        <div class="b-booking-upsell__text">
          <div class="extrabold highlight">
            {{ dictionary.upsellSubTitle }}
          </div>
          <div class="h2">
            {{ dictionary.upsellTitle }}
            <span class="extrabold">
              {{ dictionary.upsellDailyRatePrefix }}
              <span>{{ info.dailyRateDifference }}</span>
              {{ dictionary.upsellDailyRateSuffix }}
            </span>
          </div>
        </div>
        <div class="b-booking-upsell__buttons">
          <button
            type="submit"
            class="btn btn-primary d-none d-lg-block"
            @click.stop>
            {{ dictionary.upsellButtonSubmitText }}
          </button>

          <button
            type="button"
            class="btn dropdown-toggle"
            :class="{ 'is-active': isActive }"></button>
        </div>
      </div>

      <div v-show="isActive" v-auto-animate class="b-booking-upsell__body">
        <div class="b-booking-upsell__vehicle">
          <div class="b-vehicle view-grid">
            <div class="b-vehicle__body">
              <div class="b-vehicle__picture">
                <LazyImage v-if="image" :image="image" />
              </div>

              <button
                type="submit"
                class="btn btn-primary btn-full-width d-flex d-lg-none"
                @click.stop>
                {{ dictionary.upsellButtonSubmitText }}
              </button>

              <div class="b-vehicle__header">
                <div class="b-vehicle__title h4 extrabold">
                  {{ vehicle.Model }}
                </div>
                <div class="b-vehicle__groups mb-30 semibold">
                  <ul>
                    <li data-bs-toggle="tooltip" :title="vehicle.AcrissData">
                      {{ vehicle.GroupAcrissTxt }}
                      <span>
                        <LazyImage
                          :image="{ url: '/imgs/icons/tooltip.svg' }" />
                      </span>
                    </li>
                    <li class="separator"></li>
                    <li>{{ vehicle.CategoryNames }}</li>
                  </ul>
                </div>
                <div class="b-vehicle__details">
                  <ul>
                    <li>
                      <div class="pair bold">
                        <i class="icon-passenger"></i>
                        {{ vehicle.PassengersTxt }}
                      </div>
                    </li>

                    <li>
                      <div class="b-vehicle__luggages">
                        <div class="pair bold">
                          <i class="icon-suitcase-large"></i>
                          <span v-if="vehicle.BigLuggage">
                            x{{ vehicle.BigLuggage }}
                          </span>
                          <span v-else>-</span>
                        </div>

                        <div class="pair bold">
                          <i class="icon-suitcase"></i>
                          <span v-if="vehicle.SmallLuggage">
                            x{{ vehicle.SmallLuggage }}
                          </span>
                          <span v-else>-</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="pair bold">
                        <i class="icon-ac"></i>
                        <span v-if="vehicle.HasAC">
                          {{ dictionary.acYes }}
                        </span>
                        <span v-else>{{ dictionary.acNo }}</span>
                      </div>
                    </li>
                    <li v-if="showTransmission">
                      <div class="pair bold">
                        <i class="icon-transmission"></i>
                        <span v-if="vehicle.IsManual">
                          {{ dictionary.transmissionManual }}
                        </span>
                        <span v-if="vehicle.IsAutomatic">
                          {{ dictionary.transmissionAutomatic }}
                        </span>
                      </div>
                    </li>
                    <li v-if="vehicle.Extras">
                      <div class="pair">
                        <i class="icon-info"></i>
                        <span>
                          {{ vehicle.Extras }}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="b-vehicle__extras">
                <p>
                  <small>{{ dictionary.bookingIncludes }}</small>
                </p>
                <ul>
                  <li v-for="(extra, index) in vehicle.ExtrasList" :key="index">
                    {{ extra }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import LazyImage from './common/LazyImage.vue'
import Loader from './common/Loader.vue'
import Alert from './common/Alert.vue'

export default {
  components: { Loader, Alert, LazyImage },
  props: {
    info: {
      required: true,
      type: Object,
    },
    details: {
      required: true,
      type: Object,
    },
  },
  data() {
    return { isLoading: false, errorMsgs: [], isActive: false }
  },
  computed: {
    vehicle() {
      return this.info.vehicle
    },

    showTransmission() {
      return this.vehicle.IsManual || this.vehicle.IsAutomatic
    },
    image() {
      const images = this.vehicle.Images
      if (!images.length) {
        return null
      }
      return {
        url: images[0],
        alt: '',
      }
    },
  },
  methods: {
    onClickHeader() {
      this.isActive = !this.isActive
    },

    onSubmitForm() {
      this.isLoading = true

      const postData = {
        Token: this.info.token,
        GroupName: this.vehicle.Name,
      }
      axios
        .post(this.info.postPageUrl, postData)
        .then((response) => {
          const { HasError, Errors, Url } = response.data

          // response has error
          if (HasError) {
            this.handleError(Errors)
            return false
          }

          window.location.assign(Url)
          this.handleSuccess()
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },
    handleSuccess() {
      this.isLoading = false
      this.errorMsgs = []
    },
    handleError(messages) {
      this.errorMsgs = messages
      this.isLoading = false
    },
  },
}
</script>
