<template>
  <div
    ref="wizard"
    class="s-strip s-solutions-wizard"
    :class="{ 'is-initial-step': showInitialStep }">
    <div class="page-container">
      <div
        class="s-solutions-wizard__steps"
        :class="{ 'show-solutions': showSolutions }">
        <!--initial step-->
        <div v-if="showInitialStep" class="s-solutions-wizard__welcome">
          <!--aside-->
          <div class="s-solutions-wizard__aside">
            <div class="s-solutions-wizard__aside__content">
              <div class="bold mb-10">{{ initialStep.subTitle }}</div>
              <div class="heading h2 mb-15">{{ initialStep.title }}</div>
              <div class="summary h3 p mb-40">{{ initialStep.summary }}</div>
              <div class="start">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="onClickStart">
                  {{ dictionary.letsStart }}
                </button>
              </div>
            </div>
          </div>
          <!--main-->
          <div class="s-solutions-wizard__picture">
            <LazyImage
              v-if="coverImage"
              class="object-fit-cover"
              :image="coverImage" />
          </div>
        </div>
        <!--end of : step-->
        <!--intemediate step-->
        <div v-if="currentStep" class="s-solutions-wizard__step">
          <!--aside-->
          <div class="s-solutions-wizard__aside">
            <div class="s-solutions-wizard__aside__content">
              <div class="bold mb-10">{{ currentStep.subTitle }}</div>
              <div class="heading h2 mb-15">{{ currentStep.title }}</div>
              <div v-if="currentStep.summary" class="summary h3 p mb-40">
                {{ currentStep.summary }}
              </div>

              <div class="s-solutions-wizard__toolbar">
                <button
                  type="button"
                  class="btn btn-link btn-icon-start btn-back"
                  @click="onClickBack">
                  <i class="icon-arrow-long-left"></i>
                  <span v-if="isFirstQuestion">
                    {{ dictionary.goBackToStart }}
                  </span>
                  <span v-else>{{ dictionary.goBack }}</span>
                </button>
              </div>
            </div>
          </div>
          <!--main-->
          <div class="s-solutions-wizard__main">
            <div class="s-solutions-wizard__main__content">
              <div class="b-solutions-wizard-step">
                <div class="b-solutions-wizard-step__options">
                  <ul class="custom-inpts">
                    <li v-for="(item, index) in options" :key="index">
                      <input
                        :id="item.id"
                        v-model="option"
                        name="step1"
                        type="radio"
                        :value="item.id"
                        @change="onChangeOption" />
                      <label :for="item.id">
                        <span class="title mb-10">{{ item.title }}</span>
                        <span class="h3">{{ item.summary }}</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end of : step-->
        <!--final step-->
        <div v-if="showSolutions" class="s-solutions-wizard__final">
          <div class="mb-20">
            <button
              type="button"
              class="btn btn-link btn-icon-start btn-refresh"
              @click="onClickRefresh">
              <i class="icon-refresh"></i>
              {{ dictionary.repeat }}
            </button>
          </div>

          <SlickCarousel
            ref="slick"
            :key="'key-' + slickKey"
            class="s-solutions-wizard__slick"
            :options="slickOptions">
            <div
              v-for="(item, index) in solutionsFeatured"
              :key="index"
              class="s-solutions-wizard__slide item">
              <SolutionsWizardProgramm :item="item" />
            </div>

            <div
              v-for="(item, index) in solutionsRelated"
              :key="index"
              class="s-solutions-wizard__slide item">
              <SolutionsWizardProgramm :item="item" related="true" />
            </div>
          </SlickCarousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery'
import * as lib from '../../lib/lib'
import SolutionsWizardProgramm from './../components/SolutionsWizardProgramm.vue'
import LazyImage from './../components/common/LazyImage.vue'
import SlickCarousel from '../components/common/SlickCarousel.vue'

export default {
  components: { SlickCarousel, SolutionsWizardProgramm, LazyImage },

  data() {
    return {
      scrollOffsetY: 100,
      // default
      $this: [],
      isMounted: false,
      isLoading: false,
      // properties
      selectedPath: [],
      option: null,
      solutionsWizard: lib.getData('solutionsWizard'),
      currentStep: null,
      solutions: [],
      stepReady: true,
      animNameLbl: 'fade-slow',
      animName: '',
      resultLoaded: false,
      slickKey: 0,
      slickOptions: {
        infinite: false,
        autoplay: false,
        arrows: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 992,
            settings: { variableWidth: false },
          },
        ],
      },
    }
  },

  computed: {
    coverImage() {
      return {
        url: this.initialStep.imageUrl,
        alt: '',
      }
    },

    hasRelated() {
      return this.solutionsRelated.length ? true : false
    },

    showSolutions() {
      return this.solutions.length
    },

    solutionsFeatured() {
      const tmp = this.solutions.filter((i) => !i.related).map((i) => i.id)

      return this.solutionsData.filter((i) => tmp.includes(i.id))
    },

    solutionsRelated() {
      const tmp = this.solutions.filter((i) => i.related).map((i) => i.id)

      return this.solutionsData.filter((i) => tmp.includes(i.id))
    },

    isDisabledNext() {
      return !lib.hasValue(this.option)
    },

    options() {
      if (!lib.hasValue(this.currentStep)) {
        return []
      }

      return this.currentStep.options
    },

    showInitialStep() {
      return !this.selectedPath.length && !this.showSolutions
    },

    intermediateSteps() {
      return this.solutionsWizard.intermediateSteps
    },

    dictionary() {
      return this.solutionsWizard.dictionary
    },

    solutionsData() {
      return this.solutionsWizard.solutionsData
    },

    initialStep() {
      return this.solutionsWizard.initialStep
    },

    isFirstQuestion() {
      return this.selectedPath.length === 1
    },
  },
  methods: {
    onChangeOption() {
      this.$nextTick(() => {
        this.stepReady = false
        this.animName = this.animNameLbl

        this.$nextTick(() => {
          this.onClickNext()

          this.$nextTick(() => {
            this.stepReady = true

            if (lib.scrDevices()) {
              lib.scrollToElem(jQuery(this.$refs.wizard), this.scrollOffsetY)
            }
          })
        })
      })
    },

    onClickNext() {
      const nextStep = this.currentStep.options.find(
        (i) => i.id === this.option
      ).step

      this.solutions = this.currentStep.options.find(
        (i) => i.id === this.option
      ).solutions

      // show solutions
      if (this.showSolutions) {
        this.iniFinalResults()
        return false
      }

      if (!lib.hasValue(nextStep)) {
        alert('An Error Occured!')
        return false
      }

      this.currentStep = nextStep

      this.selectedPath[this.selectedPath.length - 1].optionId = this.option
      this.selectedPath.push({
        stepId: this.currentStep.id,
        optionId: null,
      })
      this.option = null
    },

    iniFinalResults() {
      this.stepReady = false
      this.animName = 'none'
      this.$nextTick(() => {
        this.stepReady = true
        this.showResultLoader()
        this.resetWizard()
      })
    },

    onClickBack() {
      this.animName = 'none'

      this.$nextTick(() => {
        let previousStep = this.intermediateSteps

        // remove last item
        this.selectedPath.splice(-1, 1)

        // back to initialStep
        if (!this.selectedPath.length) {
          this.currentStep = null
          return false
        }

        this.selectedPath.forEach((item, index) => {
          if (index < this.selectedPath.length - 1) {
            previousStep = previousStep.options.find(
              (i) => i.id === item.optionId
            ).step
          }
        })

        this.currentStep = previousStep
        if (lib.scrDevices()) {
          lib.scrollToElem(jQuery(this.$refs.wizard), this.scrollOffsetY)
        }
      })
    },

    onClickStart() {
      this.animName = this.animNameLbl
      this.stepReady = false

      this.$nextTick(() => {
        this.stepReady = true
        this.currentStep = this.intermediateSteps
        this.selectedPath.push({
          stepId: this.currentStep.id,
          optionId: null,
        })

        if (lib.scrDevices()) {
          lib.scrollToElem(jQuery(this.$refs.wizard), this.scrollOffsetY)
        }
      })
    },

    onClickRefresh() {
      this.animName = 'none'
      this.resultLoaded = false

      this.$nextTick(() => {
        this.stepReady = true

        this.resetWizard()
        this.solutions = []

        this.$nextTick(() => {
          this.animName = this.animNameLbl
          if (lib.scrDevices()) {
            lib.scrollToElem(jQuery(this.$refs.wizard), this.scrollOffsetY)
          }
        })
      })
    },

    resetWizard() {
      this.selectedPath = []
      this.option = null
      this.currentStep = null
    },

    showResultLoader() {
      setTimeout(() => {
        this.resultLoaded = true
      }, 2000)
    },
  },
}
</script>
