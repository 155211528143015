<template>
  <div class="s-our-services">
    <div class="page-container">
      <div class="s-our-services__grid">
        <div
          v-for="item in records"
          :key="item.id"
          class="s-our-services__item">
          <Promo :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as lib from '../../lib/lib'
import Promo from './../components/Promo.vue'

export default {
  components: { Promo },

  data() {
    return {
      records: [],
    }
  },
  mounted() {
    const { records } = lib.getData('servicesData')
    this.records = records
  },
  methods: {},
}
</script>
