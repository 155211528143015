<template>
  <div class="e-progress-bar">
    <div class="fill" :style="{ width: `${percentage}%` }"></div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      required: true,
      type: Number,
    },
  },
}
</script>
