<template>
  <Anchor v-if="item.link" :item="item.link" :hide-text="true" class="b-promo">
    <div class="overlay"></div>
    <div class="hover-layer"></div>
    <div class="b-promo__picture">
      <LazyImage
        v-if="item.image"
        :image="item.image"
        class="object-fit-cover" />
    </div>
    <div class="b-promo__body">
      <div class="b-promo__title h1 mb-15">{{ item.title }}</div>
      <div class="b-promo__summary mb-40 h3 p" v-html="item.summary"></div>
      <div class="e-arrow-diagonal">
        <i class="icon-arrow-long-right"></i>
      </div>
    </div>
  </Anchor>
</template>

<script>
import Anchor from './common/Anchor.vue'
import LazyImage from './common/LazyImage.vue'

export default {
  components: { Anchor, LazyImage },
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {}
  },
}
</script>
