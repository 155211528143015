/**
 * initialize slick carousel for .s-hero-v2 selectors
 */

import jQuery from 'jquery'
import 'slick-carousel'
import * as lib from './../lib/lib'

export default class Hero {
  constructor(element) {
    if (!element) {
      return false
    }

    this.$element = jQuery(element)
    this.slider = jQuery('.s-hero-v2__slick', this.$element)
    this._iniSlick()
  }

  _setControls() {
    const arrowPrev = jQuery('.slick-prev', this.$element)
    const arrowNext = jQuery('.slick-next', this.$element)
    const dots = jQuery('.slick-dots', this.$element)

    const refer = jQuery('.b-hero__title', this.$element)

    const offsetLeft = refer.offset().left
    arrowPrev.css({ left: offsetLeft })
    arrowNext.css({ left: offsetLeft + 50 })
    dots.css({ left: offsetLeft })
  }

  _resetControls() {
    const dots = jQuery('.slick-dots', this.$element)
    dots.removeAttr('style')
  }

  _iniSlick() {
    this.slider.on('init', () => {
      if (lib.scrDevices()) {
        this._resetControls()
        return false
      }
      this._setControls()
    })

    this.slider.slick({
      lazyLoad: 'ondemand',
      autoplay: true,
      arrows: true,
      dots: true,
      slidesToShow: 1,
      fade: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            arrows: false,
            dots: true,
            fade: false,
          },
        },
      ],
    })

    this.slider.on('setPosition', () => {
      if (lib.scrDevices()) {
        this._resetControls()
        return false
      }
      this._setControls()
    })
  }
}
