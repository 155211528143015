<template>
  <div class="b-booking-aside">
    <div class="b-booking-aside__header">
      <span class="b-booking-aside__title h2">
        {{ dictionary.asideHeaderTitle }}
      </span>

      <Anchor
        v-if="editVehicleLink"
        :item="editVehicleLink"
        :hide-text="true"
        class="btn btn-link-light btn-icon-start btn-edit">
        <i class="icon-edit"></i>
        <small>{{ editVehicleLink.text }}</small>
      </Anchor>
    </div>
    <div class="b-booking-aside__vehicle">
      <div class="b-vehicle view-list">
        <div class="b-vehicle__tagged">
          <VehicleTag :item="item" />
        </div>

        <div class="b-vehicle__body">
          <div class="b-vehicle__header mb-40">
            <div class="b-vehicle__title h4 extrabold">
              {{ item.Model }}
            </div>
            <div class="b-vehicle__groups mb-10 semibold">
              <ul>
                <li data-bs-toggle="tooltip" :title="item.AcrissData">
                  {{ item.GroupAcrissTxt }}
                  <span>
                    <LazyImage :image="{ url: '/imgs/icons/tooltip.svg' }" />
                  </span>
                </li>
                <li class="separator"></li>
                <li>{{ item.CategoryNames }}</li>
              </ul>
            </div>
            <div class="b-vehicle__details">
              <ul>
                <li>
                  <div class="pair bold">
                    <i class="icon-passenger"></i>
                    {{ item.PassengersTxt }}
                  </div>
                </li>

                <li>
                  <div class="b-vehicle__luggages">
                    <div class="pair bold">
                      <i class="icon-suitcase-large"></i>
                      <span v-if="item.BigLuggage">x{{ item.BigLuggage }}</span>
                      <span v-else>-</span>
                    </div>

                    <div class="pair bold">
                      <i class="icon-suitcase"></i>
                      <span v-if="item.SmallLuggage">
                        x{{ item.SmallLuggage }}
                      </span>
                      <span v-else>-</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="pair bold">
                    <i class="icon-ac"></i>
                    <span v-if="item.HasAC">{{ dictionary.acYes }}</span>
                    <span v-else>{{ dictionary.acNo }}</span>
                  </div>
                </li>
                <li v-if="showTransmission">
                  <div class="pair bold">
                    <i class="icon-transmission"></i>
                    <span v-if="item.IsManual">
                      {{ dictionary.transmissionManual }}
                    </span>
                    <span v-if="item.IsAutomatic">
                      {{ dictionary.transmissionAutomatic }}
                    </span>
                  </div>
                </li>
                <li v-if="item.Extras">
                  <div class="pair">
                    <i class="icon-info"></i>
                    <span v-html="item.Extras"></span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="b-vehicle__picture mb-30">
            <LazyImage v-if="image" :image="image" />
          </div>

          <div class="b-vehicle__extras mb-20">
            <p>
              <small>{{ dictionary.bookingIncludes }}</small>
            </p>
            <ul>
              <li v-for="(extra, index) in item.ExtrasList" :key="index">
                {{ extra }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="b-booking-aside__main">
      <div class="b-booking-aside__separator mb-30"></div>

      <div class="b-booking-aside__extras mb-20 p">
        <label for="" class="form-label extrabold mb-20">
          {{ dictionary.asideExtrasLabel }}
        </label>

        <!-- prepaid -->
        <ul v-if="payment?.IsPrepaid && payment?.PrepaidAmenities.length">
          <li v-for="(option, index) in payment.PrepaidAmenities" :key="index">
            <span>
              {{ option.Description }}
              <span v-if="option.Quantity > 1">(x{{ option.Quantity }})</span>
            </span>
            <em class="h4 extrabold" v-html="option.Cost"></em>
          </li>
        </ul>
        <!-- non-prepaid -->
        <ul v-else-if="!payment?.IsPrepaid && payment?.ReturnAmenities.length">
          <li v-for="(option, index) in payment.ReturnAmenities" :key="index">
            <span>
              {{ option.Description }}
              <span v-if="option.Quantity > 1">(x{{ option.Quantity }})</span>
            </span>
            <em class="h4 extrabold" v-html="option.Cost"></em>
          </li>
        </ul>
        <p v-else>{{ dictionary.asideExtrasNoResults }}</p>
      </div>

      <div
        v-if="!payment?.IsPrepaid && payment?.ReturnAmenities.length"
        class="b-booking-aside__cost mb-20">
        <span>{{ dictionary.asideReturnAmenitiesCost }}</span>
        <em class="h4 extrabold" v-html="payment?.ReturnAmenitiesCost"></em>
      </div>

      <div class="b-booking-aside__cost mb-20">
        <span>{{ dictionary.asideCostOfVehicle }}</span>
        <em class="h4 extrabold" v-html="payment?.VehicleCost"></em>
      </div>

      <div class="b-booking-aside__total-cost mb-20">
        <div>
          <span class="extrabold">{{ dictionary.asideCostTotal }}</span>
          <br />
          <span v-if="payment?.IsPrepaid">
            {{ dictionary.asideCostTotalPrepaid }}
          </span>
          <span v-else>
            {{ dictionary.asideCostTotalPayOnReturn }}
          </span>
        </div>
        <em v-html="payment?.TotalCost"></em>
      </div>

      <div v-if="showSubmitButton" class="b-booking-aside__footer">
        <button
          type="button"
          class="btn btn-primary btn-full-width"
          @click="onClickSubmit">
          {{ dictionary.buttonSubmitText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import * as lib from '../../lib/lib'
import LazyImage from './common/LazyImage.vue'
import VehicleTag from './VehicleTag.vue'
import Anchor from './../components/common/Anchor.vue'

export default {
  components: { LazyImage, Anchor, VehicleTag },
  props: {
    item: {
      required: true,
      type: Object,
    },
    payment: {
      required: true,
      type: Object,
    },
    showSubmitButton: {
      required: false,
      type: Boolean,
    },
  },
  emits: ['submit'],
  data() {
    return {
      editVehicleLink: null,
      prepaidAmenities: [],
    }
  },
  computed: {
    showTransmission() {
      return this.item.IsManual || this.item.IsAutomatic
    },
    image() {
      const images = this.item.Images
      if (!images.length) {
        return null
      }
      return {
        url: images[0],
        alt: '',
      }
    },
  },
  mounted() {
    const { editVehicleLink } = lib.getData('bookingData')
    this.editVehicleLink = editVehicleLink

    this.$nextTick(() => {
      lib.iniTooltips(document)
    })
  },
  methods: {
    onClickSubmit() {
      this.$emit('submit')
    },
  },
}
</script>
