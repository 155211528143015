/**
 * show / hide password input value for .js-password-toggler selectors
 */

import jQuery from 'jquery'

const PasswordToggler = () => {
  jQuery('.js-password-toggler').on('click', (e) => {
    const targetElement = jQuery(e.currentTarget)
    const icon = targetElement.find('i')
    const input = targetElement.siblings('input')

    if (!input.length) {
      return false
    }

    targetElement.toggleClass('')
    const type = input.attr('type')

    if (type === 'password') {
      input.attr('type', 'text')
      icon.attr('class', 'icon-eye-off')
    } else {
      input.attr('type', 'password')
      icon.attr('class', 'icon-eye')
    }
  })
}

export default PasswordToggler
