/**
 * add accordion functionality for menu items to .s-footer selector
 */
import jQuery from 'jquery'

export default class Newsletter {
  constructor(element) {
    if (!element) {
      return false
    }

    this.$element = jQuery(element)
    this.form = jQuery('#newsletter-form')
    this.loader = jQuery('.e-loader', this.$element)
    this.main = jQuery('.s-newsletter__main', this.$element)
    this.thankyou = jQuery('.s-newsletter__thankyou', this.$element)

    this.form.on('submit', (e) => {
      e.preventDefault()

      this.loader.show()

      setTimeout(() => {
        this.loader.hide()
        this.main.toggle()
        this.thankyou.toggle()
      }, 1000)
    })
  }
}
