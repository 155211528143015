<template>
  <Loader v-if="isLoading"></Loader>
  <Alert v-if="errorMsgs.length" type="danger" :messages="errorMsgs"></Alert>

  <div class="s-booking-extras mb-60">
    <div class="s-booking-extras__items">
      <div v-for="item in records" :key="item.code">
        <BookingExtra
          :item="item"
          :selected="selected"
          @add="onClickAdd"
          @delete="onClickDelete" />
      </div>
    </div>
  </div>

  <ModalCase1
    :master="case1Master"
    :childs="case1Childs"
    @submit="onSubmitCase1"
    @undo="onUndoCase1" />

  <ModalCase2
    :master="case2Master"
    :child="case2Child"
    :exemption="case2Exemption"
    @submit="onSubmitCase2"
    @undo="onUndoCase2" />

  <ModalCase3
    :master="case3Master"
    :childs="case3Childs"
    @submit="onSubmitCase3"
    @undo="onUndoCase3" />

  <ModalCase4
    :master="case4Master"
    :child="case4Child"
    @submit="onSubmitCase4"
    @undo="onUndoCase4" />
</template>

<script>
import * as lib from '../../lib/lib'
import BookingExtra from './BookingExtra.vue'
import axios from 'axios'
import constants from '../../lib/constants'
import { Modal } from 'bootstrap'
import Loader from './common/Loader.vue'
import Alert from './common/Alert.vue'
import ModalCase1 from './BookingExtrasModalCase1.vue'
import ModalCase2 from './BookingExtrasModalCase2.vue'
import ModalCase3 from './BookingExtrasModalCase3.vue'
import ModalCase4 from './BookingExtrasModalCase4.vue'

export default {
  components: {
    Loader,
    Alert,
    BookingExtra,
    ModalCase1,
    ModalCase2,
    ModalCase3,
    ModalCase4,
  },
  props: {
    isModifyMode: {
      required: false,
      type: Boolean,
    },
  },
  emits: ['change-payment', 'change-extras-data'],
  data() {
    return {
      isLoading: false,
      errorMsgs: [],
      payment: null,
      records: [],
      recordsCount: null,
      selected: [],
      token: '',
      modalCase1: null,
      modalCase2: null,
      modalCase3: null,
      modalCase4: null,
      amenitiesPrerequisites: [],

      case1Master: null,
      case1Childs: [],

      case2Master: null,
      case2Child: null,
      case2Exemption: null,

      case3Master: null,
      case3Childs: [],

      case4Master: null,
      case4Child: null,
    }
  },
  computed: {
    selectedIds() {
      return this.selected.map((i) => i.code)
    },

    unselectedIds() {
      return this.records
        .filter((i) => !this.selectedIds.includes(i.code))
        .map((i) => i.code)
    },
  },
  mounted() {
    const { token, amenitiesDefinitions, Payment, amenitiesPrerequisites } =
      lib.getData('bookingData')
    this.payment = Payment
    this.token = token
    this.records = amenitiesDefinitions
    this.amenitiesPrerequisites = amenitiesPrerequisites
    this.modalCase1 = new Modal(document.getElementById('modalCase1'))
    this.modalCase2 = new Modal(document.getElementById('modalCase2'))
    this.modalCase3 = new Modal(document.getElementById('modalCase3'))
    this.modalCase4 = new Modal(document.getElementById('modalCase4'))
    this.setPreselectedValues()
    this.setPreselectedValuesForModify()
  },
  methods: {
    setPreselectedValues() {
      const { PrepaidAmenities, ReturnAmenities } = this.payment

      PrepaidAmenities.forEach((element) => {
        if (element.Id) {
          this.selected.push({ code: element.Id, quantity: element.Quantity })
        }
      })
      ReturnAmenities.forEach((element) => {
        if (element.Id) {
          this.selected.push({ code: element.Id, quantity: element.Quantity })
        }
      })
    },

    setPreselectedValuesForModify() {
      const { amenitiesDefinitions } = lib.getData('bookingData')
      if (!amenitiesDefinitions) {
        return false
      }
      if (!amenitiesDefinitions.length) {
        return false
      }

      amenitiesDefinitions.forEach((element) => {
        if (element.requestedQuantity >= 1) {
          this.selected.push({
            code: element.code,
            quantity: element.requestedQuantity,
          })
        }
      })
    },

    amenityIsSelected(item) {
      return this.selectedIds.includes(item.code)
    },

    getAmenityQuantity(item) {
      if (!this.amenityIsSelected(item)) {
        return 0
      }
      const entry = this.selected.find((i) => i.code === item.code)
      return entry.quantity
    },

    getAmenityAddedType(item) {
      if (!this.amenityIsSelected(item)) {
        return constants.extras.manually
      }
      const entry = this.selected.find((i) => i.code === item.code)
      return entry.added
    },

    getAmenities() {
      return this.records.map((i) => ({
        Code: i.code,
        Quantity: this.getAmenityQuantity(i),
        Added: this.getAmenityAddedType(i),
      }))
    },

    fetchData() {
      if (this.isModifyMode) {
        return false
      }

      this.isLoading = true

      const postData = {
        Amenities: this.getAmenities(),
        Token: this.token,
      }
      axios
        .post(this.configuration.endpointExtrasListSubmitData, postData)
        .then((response) => {
          const { HasError, Errors, Payment, Token } = response.data
          // response has error
          if (HasError) {
            this.handleError(Errors)
            return false
          }

          this.handleSuccess()
          this.payment = Payment
          this.token = Token

          this.$emit('change-payment', this.payment, this.token)
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    handleSuccess() {
      this.errorMsgs = []
      this.isLoading = false
    },

    handleError(messages) {
      this.errorMsgs = messages
      this.isLoading = false

      this.$nextTick(() => {
        lib.scrollToError(100)
      })
    },

    onClickAdd(code, quantity) {
      if (this.isCase1(code)) {
        return false
      }

      if (this.isCase2(code)) {
        return false
      }

      this.selected.push({ code, quantity, added: constants.extras.manually })
      this.fetchData()

      this.$emit('change-extras-data', this.selected)
    },

    onClickDelete(code) {
      if (this.isCase3(code)) {
        return false
      }

      if (this.isCase4(code)) {
        return false
      }

      const index = this.selectedIds.indexOf(code)
      this.selected.splice(index, 1)
      this.fetchData()

      this.$emit('change-extras-data', this.selected)
    },

    isCase1(code) {
      const entry = this.amenitiesPrerequisites.find((i) => i.id === code)

      if (entry) {
        const prereqs = entry.prereqsIds

        const atLeastOnePrereqIsUnSelected = prereqs.some((x) =>
          this.unselectedIds.includes(x)
        )

        if (atLeastOnePrereqIsUnSelected) {
          this.case1Master = code
          this.case1Childs = prereqs.filter((i) =>
            this.unselectedIds.includes(i)
          )

          this.modalCase1.show()
          return true
        }
      }

      return false
    },

    isCase2(code) {
      const entry = this.amenitiesPrerequisites.find((i) =>
        i.prereqsIds.includes(code)
      )

      if (entry) {
        this.case2Master = entry.id
        this.case2Child = code
        this.case2Exemption = entry.exemption
        const masterIsSelected = this.selectedIds.includes(entry.id)

        // hack
        // current extra has not been add to this.selected array yet
        // I add current extra temporary to a new array for check
        const selectedIdsTmp = this.selectedIds
        selectedIdsTmp.push(code)

        const allPrereqsAreSelected = entry.prereqsIds.every((i) =>
          selectedIdsTmp.includes(i)
        )

        if (allPrereqsAreSelected && !masterIsSelected) {
          this.modalCase2.show()
          return true
        }
      }
      return false
    },

    isCase3(code) {
      const entry = this.amenitiesPrerequisites.find((i) => i.id === code)
      if (entry) {
        const prereqs = entry.prereqsIds

        const prereqsSelectedAuto = this.selected
          .filter((i) => i.added === constants.extras.auto)
          .filter((i) => prereqs.includes(i.code))
          .map((i) => i.code)

        if (prereqsSelectedAuto.length) {
          this.case3Master = code
          this.case3Childs = prereqsSelectedAuto

          this.modalCase3.show()
          return true
        }
      }
      return false
    },

    isCase4(code) {
      const entry = this.amenitiesPrerequisites.find((i) =>
        i.prereqsIds.includes(code)
      )
      if (entry) {
        const masterIsSelected = this.selectedIds.includes(entry.id)

        if (masterIsSelected) {
          this.case4Master = entry.id
          this.case4Child = code

          this.modalCase4.show()
          return true
        }
      }
      return false
    },

    onSubmitCase1(masterId, childsIds) {
      this.selected.push({
        code: masterId,
        quantity: 1,
        added: constants.extras.manually,
      })

      childsIds.forEach((elem) => {
        this.selected.push({
          code: elem,
          quantity: 1,
          added: constants.extras.auto,
        })
      })

      this.fetchData()
      this.modalCase1.hide()
    },

    onSubmitCase2(masterId, childId) {
      this.selected.push({
        code: childId,
        quantity: 1,
        added: constants.extras.manually,
      })

      this.selected.push({
        code: masterId,
        quantity: 1,
        added: constants.extras.auto,
      })

      this.fetchData()
      this.modalCase2.hide()
    },

    onSubmitCase3(masterId, childsIds) {
      const index = this.selectedIds.indexOf(masterId)
      this.selected.splice(index, 1)

      childsIds.forEach((elem) => {
        const index = this.selectedIds.indexOf(elem)
        this.selected.splice(index, 1)
      })

      this.fetchData()
      this.modalCase3.hide()
    },

    onSubmitCase4(masterId, childId) {
      let index = this.selectedIds.indexOf(masterId)
      this.selected.splice(index, 1)

      index = this.selectedIds.indexOf(childId)
      this.selected.splice(index, 1)

      this.fetchData()
      this.modalCase4.hide()
    },

    onUndoCase1() {
      this.modalCase1.hide()
    },

    onUndoCase2(code) {
      this.selected.push({
        code,
        quantity: 1,
        added: constants.extras.manually,
      })

      this.fetchData()
      this.modalCase2.hide()
    },

    onUndoCase3(code) {
      const index = this.selectedIds.indexOf(code)
      this.selected.splice(index, 1)
      this.fetchData()
      this.modalCase3.hide()
    },

    onUndoCase4() {
      this.modalCase4.hide()
    },
  },
}
</script>
