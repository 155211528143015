<template>
  <section class="s-strip s-strip--short s-strip--theme-grey">
    <div class="s-strip__header mb-40">
      <div class="page-container">
        <div class="b-strip-header align-items-center">
          <div class="title h2">{{ title }}</div>
        </div>
      </div>
    </div>

    <div class="page-container d-none d-lg-block">
      <BookingWizard :step="step" />
      <BookingPickNReturn />
    </div>

    <div class="page-container d-block d-lg-none">
      <BookingWizard :step="step" />
      <Accordion title="Booking details">
        <BookingPickNReturn />
      </Accordion>
    </div>
  </section>

  <section class="s-strip s-strip--short">
    <div class="page-container">
      <BookingExtras />
    </div>
  </section>

  <BookingSessionTimeoutModal />
</template>

<script>
import BookingWizard from '../components/BookingWizard.vue'
import BookingPickNReturn from '../components/BookingPickNReturn.vue'
import BookingExtras from '../components/BookingExtras.vue'
import Accordion from '../components/common/Accordion.vue'
import BookingSessionTimeoutModal from './../components/BookingSessionTimeoutModal.vue'
import * as lib from '../../lib/lib'

export default {
  components: {
    BookingWizard,
    BookingPickNReturn,
    BookingExtras,
    Accordion,
    BookingSessionTimeoutModal,
  },
  data() {
    return { title: null, step: 2 }
  },
  mounted() {
    const { title } = lib.getData('bookingData')
    this.title = title
  },
}
</script>
