<template>
  <img
    v-if="image"
    ref="image"
    class="lazy"
    src="/imgs/misc/placeholder-image.png"
    :data-src="image.url"
    :alt="image.alt" />
</template>

<script>
export default {
  props: {
    image: {
      required: true,
      type: Object,
    },
  },
  mounted() {
    if ('IntersectionObserver' in window) {
      const lazyImageObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            const lazyImage = entry.target
            lazyImage.src = lazyImage.dataset.src
            if (typeof lazyImage.dataset.srcset !== 'undefined') {
              lazyImage.srcset = lazyImage.dataset.srcset
            }
            lazyImage.classList.remove('lazy')
            lazyImageObserver.unobserve(lazyImage)
          }
        })
      })

      lazyImageObserver.observe(this.$refs.image)
    } else {
      // Possibly fall back to event handlers here
    }
  },
}
</script>
