<template>
  <div class="s-programms-slick">
    <div class="page-container">
      <div v-if="percentage" class="s-programms-slick__progress-bar">
        <ProgressBar :percentage="percentage" />
      </div>

      <SlickCarousel
        ref="slick"
        :key="'key-' + slickKey"
        class="s-programms-slick__slick"
        :options="slickOptions"
        @init="onInit"
        @beforechange="onBeforeChange">
        <div
          v-for="(item, index) in records"
          :key="index"
          class="s-programms-slick__slide item">
          <Promo :item="item" />
        </div>
      </SlickCarousel>
    </div>
  </div>
</template>

<script>
import * as lib from '../../lib/lib'
import ProgressBar from './../components/common/ProgressBar.vue'
import Promo from '../components/Promo.vue'
import SlickCarousel from '../components/common/SlickCarousel.vue'

export default {
  components: { ProgressBar, Promo, SlickCarousel },

  data() {
    return {
      percentage: 0,
      records: [],
      slickKey: 0,
      slickOptions: {
        infinite: false,
        autoplay: false,
        arrows: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 992,
            settings: { slidesToShow: 1, variableWidth: false },
          },
        ],
      },
    }
  },
  mounted() {
    const { records } = lib.getData('programmsData')
    this.records = records
  },
  methods: {
    onInit(event, slick) {
      this.percentage = lib.getSlickProgressBarPercentage(slick)
    },
    onBeforeChange(event, slick) {
      setTimeout(() => {
        this.percentage = lib.getSlickProgressBarPercentage(slick)
      }, 100)
    },
  },
}
</script>
