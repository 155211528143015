<template>
  <div v-auto-animate class="b-accordion" :class="{ 'is-active': isActive }">
    <div class="b-accordion__header" @click="onClickHeader">
      <span>{{ title }}</span>
      <i class="icon-arrow-dropdown-down"></i>
    </div>
    <div v-if="isActive" class="b-accordion__body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    isActiveProp: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return { isActive: false }
  },
  watch: {
    isActiveProp(newValue) {
      this.isActive = newValue
    },
  },
  mounted() {
    this.isActive = this.isActiveProp
  },

  methods: {
    onClickHeader() {
      this.isActive = !this.isActive
    },
  },
}
</script>
