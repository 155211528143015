<template>
  <section class="s-strip s-strip--short mb-10">
    <div class="s-strip__header mb-40">
      <div class="page-container">
        <div class="b-strip-header align-items-center">
          <div class="title h2">
            {{ title }}
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="b-booked-ribbon mb-6">
    <div class="b-booked-ribbon__conts">
      <dl>
        <dt>{{ dictionary.reservationNumberLabel }}</dt>
        <dd>
          {{ reservationIdentityInfo?.reservationNumber }}
        </dd>
      </dl>
      <div class="divider"></div>
      <dl>
        <dt>{{ dictionary.referenceNumberLabel }}</dt>
        <dd>
          {{ reservationIdentityInfo?.referenceNumber }}
        </dd>
      </dl>
      <div class="divider"></div>
      <dl>
        <dt>{{ dictionary.paymentMethodLabel }}</dt>
        <dd>
          <span v-if="reservationIdentityInfo?.isPrepaid">
            {{ dictionary.payOnline }}
          </span>
          <span v-else>{{ dictionary.payUponDelivery }}</span>
        </dd>
      </dl>
    </div>
  </div>

  <div v-if="vehicle" class="b-vehicle__body">
    <div class="b-vehicle__row-1">
      <div class="b-vehicle__header mb-20">
        <div class="b-vehicle__title h4 extrabold">
          {{ dictionary.asideHeaderTitle }}
        </div>
      </div>
      <div class="b-vehicle__image-details1-group">
        <div class="b-vehicle__picture mb-30">
          <img v-if="image" :src="image.url" />
        </div>
        <div class="b-vehicle__details-1">
          <div class="b-vehicle__title h4 extrabold">
            {{ vehicle.Model }}
          </div>
          <div class="b-vehicle__groups mb-6 semibold">
            <ul>
              <li>{{ vehicle.GroupAcrissTxt }}</li>
              <li class="separator"></li>
              <li>{{ vehicle.CategoryNames }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="b-vehicle__">
      <div class="b-vehicle__test">
        <div class="b-vehicle__details">
          <ul>
            <li>
              <div class="pair bold">
                <i class="icon-passenger"></i>
                x{{ vehicle.Passengers }}
              </div>
            </li>

            <li>
              <div class="b-vehicle__luggages">
                <div class="pair bold">
                  <i class="icon-suitcase-large"></i>
                  <span v-if="vehicle.BigLuggage">
                    x{{ vehicle.BigLuggage }}
                  </span>
                  <span v-else>-</span>
                </div>

                <div class="pair bold">
                  <i class="icon-suitcase"></i>
                  <span v-if="vehicle.SmallLuggage">
                    x{{ vehicle.SmallLuggage }}
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
            </li>
            <li>
              <div class="pair bold">
                <i class="icon-ac"></i>
                <span v-if="vehicle.HasAC">{{ dictionary.acYes }}</span>
                <span v-else>{{ dictionary.acNo }}</span>
              </div>
            </li>
            <li v-if="showTransmission">
              <div class="pair bold">
                <i class="icon-transmission"></i>
                <span v-if="vehicle.IsManual">M</span>
                <span v-if="vehicle.IsAutomatic">A</span>
              </div>
            </li>
            <li v-if="vehicle.Extras">
              <div class="pair">
                <i class="icon-info"></i>
                <span v-html="vehicle.Extras"></span>
              </div>
            </li>
          </ul>
        </div>

        <div class="b-vehicle__extras mb-6">
          <ul>
            <li>150 Ελεύθερα χλμ. ανά ημέρα</li>
            <li>Eπιπλέον χλμ. : 0.15 EUR / ΚΜ</li>
            <li>Ασφάλεια Ιδίων Ζημιών με απαλλαγή (CDW)</li>
            <li>Χρέωση Παράδοσης Αεροδρομίου</li>
          </ul>
        </div>
      </div>
      <div class="b-vehicle__extras mb-6">
        <ul class="flex">
          <li>Κάλυψη Κλοπής</li>
          <li>ΦΠΑ 24%</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="b-booking-aside__main">
    <div class="b-booking-aside__extras p">
      <label for="" class="form-label extrabold mb-6">
        {{ dictionary.asideExtrasLabel }}
      </label>
      <!-- prepaid -->
      <ul v-if="payment?.IsPrepaid && payment?.PrepaidAmenities.length">
        <li v-for="(option, index) in payment.PrepaidAmenities" :key="index">
          <span>
            {{ option.Description }}
            <span v-if="option.Quantity > 1">(x{{ option.Quantity }})</span>
          </span>
          <em class="h4 extrabold" v-html="option.Cost"></em>
        </li>
      </ul>
      <!-- non-prepaid -->
      <ul v-else-if="!payment?.IsPrepaid && payment?.ReturnAmenities.length">
        <li v-for="(option, index) in payment.ReturnAmenities" :key="index">
          <span>
            {{ option.Description }}
            <span v-if="option.Quantity > 1">(x{{ option.Quantity }})</span>
          </span>
          <em class="h4 extrabold" v-html="option.Cost"></em>
        </li>
      </ul>
      <p v-else>{{ dictionary.asideExtrasNoResults }}</p>
    </div>

    <div class="b-booking-aside__cost mb-6">
      <span>{{ dictionary.asideCostOfVehicle }}</span>
      <em class="h4 extrabold" v-html="payment?.VehicleCost"></em>
    </div>
    <div class="b-booking-aside__total-cost mb-6">
      <div>
        <span class="bold">{{ dictionary.asideCostTotal }}</span>
        &nbsp;
        <span v-if="payment?.IsPrepaid">
          {{ dictionary.asideCostTotalPrepaid }}
        </span>
        <span v-else>
          {{ dictionary.asideCostTotalPayOnReturn }}
        </span>
      </div>
      <em v-html="payment?.TotalCost"></em>
    </div>
    <!--v-if-->
  </div>

  <div class="b-your-reservation mb-6">
    <div class="b-your-reservation__conts">
      <div class="b-your-reservation__tbl">
        <div class="e-pickup">
          <div>
            <img src="/imgs/icons/pickup.svg" />
          </div>
          <div>{{ dictionary.pickUp }}</div>
        </div>
        <div class="b-your-reservation__dt">
          {{ dictionary.pickUpLocation }}
        </div>
        <div class="b-your-reservation__dd">
          <div class="b-your-reservation__dd__main">
            <ul>
              <li>{{ pickupDetails?.address }}</li>
              <li>{{ pickupDetails?.telephone }}</li>
            </ul>
          </div>
        </div>
        <div class="b-your-reservation__dt">{{ dictionary.dateAndTime }}</div>
        <div class="b-your-reservation__dd">
          <div class="b-your-reservation__dd__main">
            <ul>
              <li>
                {{ pickupDetails?.date }}
                <span>{{ pickupDetails?.time }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="b-your-reservation__seperator d-xl-block"></div>
      <div class="b-your-reservation__tbl">
        <div class="e-pickup">
          <div>
            <img src="/imgs/icons/return.svg" />
          </div>
          <div>{{ dictionary.return }}</div>
        </div>
        <div class="b-your-reservation__dt">
          {{ dictionary.returnLocation }}
        </div>
        <div class="b-your-reservation__dd">
          <div class="b-your-reservation__dd__main">
            <ul>
              <li>{{ returnDetails?.address }}</li>
              <li>{{ returnDetails?.telephone }}</li>
            </ul>
          </div>
        </div>
        <div class="b-your-reservation__dt">{{ dictionary.dateAndTime }}</div>
        <div class="b-your-reservation__dd">
          <div class="b-your-reservation__dd__main">
            <ul>
              <li>
                {{ returnDetails?.date }}
                <span>{{ returnDetails?.time }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="b-personal-info mb-6">
    <div>
      <p>{{ dictionary.personalInformation }}</p>
    </div>
    <div class="b-personal-info__conts">
      <dl>
        <dt>{{ dictionary.fullNameLabel }}</dt>
        <dd>{{ reservationPersonalInfo?.fullName }}</dd>
      </dl>
      <dl>
        <dt>{{ dictionary.emailLabel }}</dt>
        <dd>{{ reservationPersonalInfo?.email }}</dd>
      </dl>
      <dl>
        <dt>{{ dictionary.phoneNumberLabel }}</dt>
        <dd>{{ reservationPersonalInfo?.phoneNumber }}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
import * as lib from '../../lib/lib'

export default {
  data() {
    return {
      title: null,
      vehicle: null,
      payment: null,
      reservationIdentityInfo: null,
      reservationPersonalInfo: null,
      pickupDetails: null,
      returnDetails: null,
    }
  },
  computed: {
    showTransmission() {
      return this.vehicle.IsManual || this.vehicle.IsAutomatic
    },
    image() {
      const images = this.vehicle.Images
      if (!images.length) {
        return null
      }
      return {
        url: images[0],
        alt: '',
      }
    },
  },
  mounted() {
    const {
      title,
      vehicle,
      Payment,
      reservationIdentityInfo,
      reservationPersonalInfo,
      pickupDetails,
      returnDetails,
    } = lib.getData('bookingData')

    this.title = title
    this.vehicle = vehicle
    this.payment = Payment
    this.reservationIdentityInfo = reservationIdentityInfo
    this.reservationPersonalInfo = reservationPersonalInfo
    this.pickupDetails = pickupDetails
    this.returnDetails = returnDetails
  },
}
</script>
