<template>
  <div class="dropdown d-none d-lg-block">
    <div
      :id="'dropdownMenu' + type"
      ref="dropdownMenu"
      class="form-pseudo-floating b-booking-pick-date"
      :class="[hasError ? 'has-error' : '', disabled ? 'is-disabled' : '']"
      :data-bs-toggle="[!disabled ? 'dropdown' : '']"
      aria-expanded="false">
      <label class="form-label">
        <i class="icon-calendar"></i>
        {{ label }}
      </label>
      <div class="b-booking-pick-date__preview">
        <div>
          <span v-if="selectedDate">{{ selectedDateFormatted }}</span>
          <span v-else>{{ dictionary.datePlaceholder }}</span>
        </div>
        <div>{{ clock }}</div>
      </div>
    </div>

    <div
      v-if="!disabled"
      class="dropdown-menu"
      :class="{ 'dropdown-menu-end': dropdownAlign === 'right' }"
      :aria-labelledby="'dropdownMenu' + type">
      <BookingBarCalendar
        screen="desktop"
        :type="type"
        :hour-data-source="hourDataSource"
        :minutes-data-source="minutesDataSource"
        :hour-label="hourLabel"
        :selected-date="selectedDate"
        :selected-hour="selectedHour"
        :selected-minutes="selectedMinutes"
        :date-departure="dateDeparture"
        @submit-calendar="onSubmitCalendar"
        @cancel-calendar="onCancelCalendar" />
    </div>
  </div>

  <div
    class="form-pseudo-floating b-booking-pick-date d-flex d-lg-none"
    :class="[hasError ? 'has-error' : '', disabled ? 'is-disabled' : '']"
    @click="onClickDatePicker">
    <label class="form-label">
      <i class="icon-calendar"></i>
      {{ label }}
    </label>
    <div class="b-booking-pick-date__preview">
      <div>
        <span v-if="selectedDate">{{ selectedDateFormatted }}</span>
        <span v-else>{{ dictionary.datePlaceholder }}</span>
      </div>
      <div>{{ clock }}</div>
    </div>
  </div>

  <!-- modal -->
  <Teleport to="body">
    <div
      v-if="!disabled"
      :id="'modalDatePicker' + type"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <BookingBarCalendar
              :columns="1"
              screen="devices"
              :type="type"
              :hour-data-source="hourDataSource"
              :minutes-data-source="minutesDataSource"
              :hour-label="hourLabel"
              :selected-date="selectedDate"
              :selected-hour="selectedHour"
              :selected-minutes="selectedMinutes"
              @submit-calendar="onSubmitCalendar"
              @cancel-calendar="onCancelCalendar" />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import moment from 'moment'
import { Dropdown, Modal } from 'bootstrap'
import BookingBarCalendar from './BookingBarCalendar.vue'

export default {
  components: { BookingBarCalendar },
  props: {
    disabled: {
      required: false,
      type: Boolean,
    },
    type: {
      required: true,
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
    hourLabel: {
      required: true,
      type: String,
    },
    dropdownAlign: {
      required: false,
      type: String,
      default: '',
    },
    selectedDate: {
      required: false,
      type: Date,
      default: null,
    },
    selectedHour: {
      required: true,
      type: String,
    },
    selectedMinutes: {
      required: true,
      type: String,
    },
    hourDataSource: {
      required: true,
      type: Array,
    },
    minutesDataSource: {
      required: true,
      type: Array,
    },
    dateDeparture: {
      required: false,
      type: Date,
      default: null,
    },
    hasError: {
      required: false,
      type: Boolean,
    },
  },
  emits: ['change-clock'],
  data() {
    return {
      date: this.selectedDate,
      hour: this.selectedHour,
      minutes: this.selectedMinutes,
      dropdown: null,
      modal: null,
    }
  },
  computed: {
    selectedDateFormatted() {
      return moment(this.selectedDate).format(this.configuration.dateFormat)
    },

    clock() {
      if (!this.hourDataSource.length) {
        return false
      }
      // prettier-ignore
      const hourText = this.hourDataSource.find((i) => i.value === this.selectedHour).text
      // prettier-ignore
      const minutesText = this.minutesDataSource.find((i) => i.value === this.selectedMinutes).text
      return `${hourText}:${minutesText}`
    },
  },
  mounted() {
    if (this.disabled) {
      return false
    }

    const element = document.getElementById(`dropdownMenu${this.type}`)
    this.dropdown = new Dropdown(element)
    // prettier-ignore
    const elem = document.getElementById(`modalDatePicker${this.type}`)
    this.modal = new Modal(elem)
  },

  methods: {
    onClickDatePicker() {
      if (this.disabled) {
        return false
      }
      this.modal.show()
    },

    onSubmitCalendar(date, hour, minutes, type) {
      this.$emit('change-clock', date, hour, minutes, type)
      this.dropdown.hide()
      this.modal.hide()
    },

    onCancelCalendar() {
      this.dropdown.hide()
      this.modal.hide()
    },
  },
}
</script>
