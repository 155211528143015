import Breakpoint from 'bootstrap-breakpoints'
import constants from './constants'
import { Modal, Tooltip } from 'bootstrap'
import jQuery from 'jquery'

export const getData = (objName) => {
  return typeof window[objName] !== 'undefined' ? window[objName] : null
}

export const randomNumber = (length = 10) => {
  let result = ''
  const characters = '0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    // prettier-ignore
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const scrollToError = (offsetY = 0) => {
  jQuery([document.documentElement, document.body]).animate(
    {
      scrollTop: jQuery('.alert').offset().top - offsetY,
    },
    500,
    'linear'
  )
}

export const scrollToValidation = (offsetY = 50) => {
  // prettier-ignore
  const elements = jQuery('input.has-error, select.has-error, textarea.has-error, .multiselect.has-error')
  jQuery([document.documentElement, document.body]).animate(
    {
      scrollTop: elements.first().offset().top - offsetY,
    },
    500,
    'linear'
  )
}

export const scrollToTop = () => {
  jQuery([document.documentElement, document.body]).animate(
    {
      scrollTop: jQuery('html').offset().top,
    },
    500,
    'linear'
  )
}

export const scrollToElem = (elem, offsetY = 0) => {
  if (!elem.length) {
    return
  }

  jQuery([document.documentElement, document.body]).animate(
    {
      scrollTop: elem.offset().top - offsetY,
    },
    500
  )
}

export const hasValue = (val) => {
  return typeof val !== 'undefined' && val !== null && val !== ''
}

export const getUrlParam = (parameter, defaultValue) => {
  const searchParams = new URL(window.location.href).searchParams

  for (const [key, value] of searchParams.entries()) {
    if (key === parameter) {
      return value
    }
  }

  return defaultValue
}

export const appendUrlParam = (parameter, value) => {
  const url = new URL(window.location.href)

  const searchParams = url.searchParams

  urlParamExists(parameter)
    ? searchParams.set(parameter, value)
    : searchParams.append(parameter, value)

  history.pushState({}, null, url)
}

export const appendUrlParams = (parameters, values) => {
  const url = new URL(window.location.href)
  const searchParams = url.searchParams

  parameters.forEach((i, index) => {
    urlParamExists(i)
      ? searchParams.set(i, values[index])
      : searchParams.append(i, values[index])
  })

  history.pushState({}, null, url)
}

export const urlParamExists = (field) => {
  const url = window.location.href
  if (url.indexOf(`?${field}=`) !== -1) return true
  else if (url.indexOf(`&${field}=`) !== -1) return true
  return false
}

export const isNumber = (evt) => {
  const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  const keyPressed = evt.key

  if (!keysAllowed.includes(keyPressed)) {
    evt.preventDefault()
  }
}

export const getPrevalueText = (array, value) => {
  const entry = array.find((i) => i.value === value)

  return entry ? entry.text : ''
}

export const fileTypeIsJpeg = (value) => {
  return value === constants.fileTypes.jpeg
}

export const fileTypeIsPdf = (value) => {
  return value === constants.fileTypes.pdf
}

export const roundValueByInterval = (value, interval) => {
  const modulo = value % interval
  const intervalHalf = interval / 2

  if (modulo === 0) {
    return value
  }

  if (modulo < intervalHalf) {
    return value - modulo
  }
  return value - modulo + interval
}

export const scrDesktops = () => {
  Breakpoint.init()
  return Breakpoint.is('lg') || Breakpoint.is('md')
}

export const scrDevices = () => {
  Breakpoint.init()
  return Breakpoint.is('sm') || Breakpoint.is('xs')
}

export const isExpEditor = () => {
  return document.body.classList.contains('exp-editor')
}

export const iniTooltips = (element) => {
  const tooltips = Array.from(
    element.querySelectorAll('[data-bs-toggle="tooltip"]')
  )

  tooltips.map((i) => {
    new Tooltip(i)
  })
}

export const iniModals = (element) => {
  const modals = Array.from(
    element.querySelectorAll('[data-bs-toggle="modal"]')
  )

  modals.map((i) => {
    new Modal(i)
  })
}

export const getRandomGradient = () => {
  const max = constants.gradients.length
  const index = Math.floor(Math.random() * Math.floor(max))

  const { start, finish } = constants.gradients[index]

  return `linear-gradient(135deg, ${start} ${finish})`
}

export const getReferrer = () => {
  const referrer = document.referrer

  if (!referrer) {
    return '/'
  }

  try {
    const referrerUrl = new URL(referrer)
    const documentUrl = new URL(document.URL)

    if (referrerUrl.host !== documentUrl.host) {
      return '/'
    }

    return referrer
  } catch (e) {
    return '/'
  }
}

export const getSitecoreField = (value) => {
  return document.querySelector(`[data-sc-field-name="${value}"]`)
}

export const isNumeric = (str) => {
  if (typeof str === 'number') {
    return true
  }

  // we only process strings!
  if (typeof str !== 'string') {
    return false
  }
  // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  // ...and ensure strings of whitespace fail
  return !isNaN(str) && !isNaN(parseFloat(str))
}

export const getLocaleFromLangAttr = () => {
  const { lang, locale } = constants
  const langAttr = document.documentElement.lang
  let res
  switch (langAttr) {
    case lang.el:
      res = locale.el
      break
    default:
      res = locale.en
  }
  return res
}

export const getSlickProgressBarPercentage = (slick) => {
  if (!slick.$dots) {
    return 0
  }

  const dots = jQuery('li', slick.$dots)
  const dotActiveIndex = jQuery('.slick-active', slick.$dots).index() + 1
  const dotPercentage = 100 / dots.length
  return dotActiveIndex * dotPercentage
}
