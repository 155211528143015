<template>
  <div class="b-vehicle">
    <div class="b-vehicle__tagged">
      <span v-if="item.Guaranteed" class="e-guaranteed">
        {{ dictionary.guaranteedLabel }}
      </span>
    </div>

    <div class="b-vehicle__body">
      <div class="b-vehicle__header mb-40">
        <div class="b-vehicle__title h4 extrabold">{{ item.Model }}</div>
        <div class="b-vehicle__groups mb-10 semibold">
          <ul>
            <li data-bs-toggle="tooltip" :title="dictionary.groupTooltipText">
              {{ item.GroupAcrissTxt }}

              <LazyImage :image="{ url: '/imgs/icons/tooltip.svg' }" />
            </li>
            <li class="separator"></li>
            <li>{{ item.CategoryNames }}</li>
          </ul>
        </div>
        <div class="b-vehicle__details">
          <ul>
            <li>
              <div class="pair bold">
                <i class="icon-passenger"></i>
                {{ item.PassengersTxt }}
              </div>
            </li>

            <li>
              <div class="b-vehicle__luggages">
                <div class="pair bold">
                  <i class="icon-suitcase-large"></i>
                  <span v-if="item.BigLuggage">x{{ item.BigLuggage }}</span>
                  <span v-else>-</span>
                </div>

                <div class="pair bold">
                  <i class="icon-suitcase"></i>
                  <span v-if="item.SmallLuggage">x{{ item.SmallLuggage }}</span>
                  <span v-else>-</span>
                </div>
              </div>
            </li>
            <li>
              <div class="pair bold">
                <i class="icon-ac"></i>
                <span v-if="item.HasAC">{{ dictionary.acYes }}</span>
                <span v-else>{{ dictionary.acNo }}</span>
              </div>
            </li>
            <li v-if="showTransmission">
              <div class="pair bold">
                <i class="icon-transmission"></i>
                <span v-if="item.IsManual">
                  {{ dictionary.transmissionManual }}
                </span>
                <span v-if="item.IsAutomatic">
                  {{ dictionary.transmissionAutomatic }}
                </span>
              </div>
            </li>
            <li v-if="item.Extras">
              <div class="pair">
                <i class="icon-info"></i>
                <span v-html="item.Extras"></span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="b-vehicle__picture mb-30">
        <LazyImage v-if="image" :image="image" />
      </div>
      <div class="b-vehicle__footer">
        <div class="mb-20">
          <Anchor
            v-if="link"
            :item="link"
            class="btn btn-primary btn-full-width" />
        </div>

        <div class="b-vehicle__category">
          <Anchor
            v-if="item.CategoryLink"
            :item="item.CategoryLink"
            class="btn btn-link btn-icon-end btn-forward strong">
            <i class="icon-arrow-long-right"></i>
          </Anchor>
          <span v-else>&nbsp;</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Anchor from './common/Anchor.vue'
import LazyImage from './common/LazyImage.vue'

export default {
  components: { Anchor, LazyImage },
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {}
  },
  computed: {
    showTransmission() {
      return this.item.IsManual || this.item.IsAutomatic
    },

    image() {
      const images = this.item.Images
      if (!images.length) {
        return null
      }
      return {
        url: images[0],
        alt: '',
      }
    },

    link() {
      return {
        url: this.item.ButtonUrl,
        text: this.item.ButtonText,
        target: '_parent',
        external: false,
      }
    },
  },
}
</script>
