<template>
  <div class="e-loader" :class="[display, size]"></div>
</template>

<script>
export default {
  props: {
    display: {
      required: false,
      type: String,
      default: '',
    },
    size: {
      required: false,
      type: String,
      default: '',
    },
  },
}
</script>
