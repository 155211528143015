<template>
  <div ref="strip" class="s-booking-layout">
    <Loader v-if="isLoading"></Loader>
    <Alert v-if="errorMsgs.length" type="danger" :messages="errorMsgs"></Alert>

    <form autocomplete="off" @submit.prevent="onSubmitForm">
      <div class="s-booking-layout__body">
        <div v-show="remoteUrl" class="s-booking-layout__main">
          <iframe id="payment-iframe-frame"></iframe>
        </div>

        <div v-show="!remoteUrl" class="s-booking-layout__main">
          <div class="row">
            <!-- name -->
            <div class="col-lg-6 mb-30 fs-20">
              <span class="extrabold">{{ dictionary.personalHeader }}</span>
            </div>
            <div class="col-lg-6 mb-30 d-flex justify-content-lg-end">
              <span class="small semibold">
                {{ dictionary.requiredFieldsText }}
              </span>
            </div>
          </div>

          <div class="row gx-5">
            <!-- name -->
            <div class="col-lg-6 mb-30">
              <label for="name" class="form-label">
                {{ dictionary.nameLabel }}
              </label>
              <input
                id="name"
                v-model.trim="name"
                class="form-control"
                :class="{ 'has-error': v$.name.$error }"
                name="name"
                type="text"
                @blur="v$.name.$touch" />

              <div v-if="v$.name.$error">
                <div v-if="v$.name.required.$invalid" class="error">
                  {{ dictionary.nameRequired }}
                </div>
                <!-- prettier-ignore -->
                <div
                  v-if="v$.name.minLength.$invalid || v$.name.maxLength.$invalid"
                  class="error">
                  {{ dictionary.nameLength }}
                </div>
              </div>
            </div>

            <!-- surname -->
            <div class="col-lg-6 mb-30">
              <label for="surname" class="form-label">
                {{ dictionary.surnameLabel }}
              </label>
              <input
                id="surname"
                v-model.trim="surname"
                class="form-control"
                :class="{ 'has-error': v$.surname.$error }"
                name="surname"
                type="text"
                @blur="v$.surname.$touch" />

              <div v-if="v$.surname.$error">
                <div v-if="v$.surname.required.$invalid" class="error">
                  {{ dictionary.surnameRequired }}
                </div>
                <!-- prettier-ignore -->
                <div
                  v-if="v$.surname.minLength.$invalid || v$.surname.maxLength.$invalid"
                  class="error">
                  {{ dictionary.surnameLength }}
                </div>
              </div>
            </div>
          </div>

          <div class="row gx-5">
            <!-- email -->
            <div class="col-lg-6 mb-30">
              <label for="email" class="form-label">
                {{ dictionary.emailLabel }}
              </label>
              <input
                id="email"
                v-model.trim="email"
                class="form-control"
                :class="{ 'has-error': v$.email.$error }"
                name="email"
                type="email"
                @blur="v$.email.$touch"
                @input="onInputEmail" />

              <div v-if="v$.email.$error">
                <div v-if="v$.email.required.$invalid" class="error">
                  {{ dictionary.emailRequired }}
                </div>
                <div v-if="v$.email.email.$invalid" class="error">
                  {{ dictionary.emailRegex }}
                </div>
                <!-- prettier-ignore -->
                <div
                  v-if="v$.email.minLength.$invalid || v$.email.maxLength.$invalid"
                  class="error">
                  {{ dictionary.emailLength }}
                </div>
              </div>
            </div>

            <!-- confirmEmail -->
            <div class="col-lg-6 mb-30">
              <label for="confirmEmail" class="form-label">
                {{ dictionary.confirmEmailLabel }}
              </label>
              <input
                id="confirmEmail"
                v-model.trim="confirmEmail"
                class="form-control"
                :class="{ 'has-error': v$.confirmEmail.$error }"
                name="confirmEmail"
                type="text"
                @blur="v$.confirmEmail.$touch" />

              <div v-if="v$.confirmEmail.$error">
                <div v-if="v$.confirmEmail.required.$invalid" class="error">
                  {{ dictionary.emailRequired }}
                </div>
                <div v-if="v$.confirmEmail.email.$invalid" class="error">
                  {{ dictionary.emailRegex }}
                </div>
                <!-- prettier-ignore -->
                <div
                  v-if="v$.confirmEmail.minLength.$invalid || v$.confirmEmail.maxLength.$invalid"
                  class="error">
                  {{ dictionary.emailLength }}
                </div>
                <div
                  v-if="v$.confirmEmail.sameAsPassword.$invalid"
                  class="error">
                  {{ dictionary.confirmEmailSameAs }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="hasCreditCard" class="row gx-5">
            <!-- mobilePhone -->
            <div class="col-xl-6 mb-30">
              <label for="mobilePhone" class="form-label">
                {{ dictionary.mobilePhoneLabel }}
              </label>

              <div class="phone-group">
                <MultiSelect
                  id="mobileDialingCode"
                  v-model="mobileDialingCode"
                  autocomplete="nope"
                  :searchable="true"
                  :clear-on-search="true"
                  :options="dialingCodes"
                  :can-deselect="false"
                  :can-clear="false"
                  :no-results-text="dictionary.noResultsText"
                  label="text"
                  :class="{
                    'has-error': v$.mobileDialingCode.$error,
                  }">
                  <template #singlelabel="{ value }">
                    <div class="multiselect-single-label">
                      <img
                        class="multiselect-icon-currency"
                        :src="value.icon" />
                      {{ value.text }}
                    </div>
                  </template>

                  <template #option="{ option }">
                    <img class="multiselect-icon-currency" :src="option.icon" />
                    {{ option.text }}
                  </template>
                </MultiSelect>

                <input
                  id="mobilePhone"
                  v-model.trim="mobilePhone"
                  class="form-control"
                  :class="{ 'has-error': v$.mobilePhone.$error }"
                  name="mobilePhone"
                  type="tel"
                  @blur="v$.mobilePhone.$touch" />
              </div>

              <div v-if="v$.mobilePhone.$error">
                <div v-if="v$.mobilePhone.requiredIf.$invalid" class="error">
                  {{ dictionary.mobilePhoneRequired }}
                </div>
                <!-- prettier-ignore -->
                <div
                  v-if="v$.mobilePhone.minLength.$invalid || v$.mobilePhone.maxLength.$invalid"
                  class="error">
                  {{ dictionary.mobilePhoneLength }}
                </div>
              </div>
            </div>

            <!-- landPhone -->
            <div class="col-xl-6 mb-30">
              <label for="landPhone" class="form-label">
                {{ dictionary.landPhoneLabel }}
              </label>

              <div class="phone-group">
                <MultiSelect
                  id="landDialingCode"
                  v-model="landDialingCode"
                  autocomplete="nope"
                  :searchable="true"
                  :clear-on-search="true"
                  :options="dialingCodes"
                  :can-deselect="false"
                  :can-clear="false"
                  :no-results-text="dictionary.noResultsText"
                  label="text"
                  :class="{
                    'has-error': v$.landDialingCode.$error,
                  }">
                  <template #singlelabel="{ value }">
                    <div class="multiselect-single-label">
                      <img
                        class="multiselect-icon-currency"
                        :src="value.icon" />
                      {{ value.text }}
                    </div>
                  </template>

                  <template #option="{ option }">
                    <img class="multiselect-icon-currency" :src="option.icon" />
                    {{ option.text }}
                  </template>
                </MultiSelect>

                <input
                  id="landPhone"
                  v-model.trim="landPhone"
                  class="form-control"
                  :class="{ 'has-error': v$.landPhone.$error }"
                  name="landPhone"
                  type="tel"
                  @blur="v$.landPhone.$touch" />
              </div>

              <div v-if="v$.landPhone.$error">
                <div v-if="v$.landPhone.requiredIf.$invalid" class="error">
                  {{ dictionary.landPhoneRequired }}
                </div>
                <!-- prettier-ignore -->
                <div
                  v-if="v$.landPhone.minLength.$invalid || v$.landPhone.maxLength.$invalid"
                  class="error">
                  {{ dictionary.landPhoneLength }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row gx-5">
            <div class="col-lg-6 mb-30">
              <label for="phoneType" class="form-label">
                {{ dictionary.phoneTypeLabel }}
              </label>
              <select
                id="phoneType"
                v-model="phoneType"
                name="phoneType"
                class="form-select">
                <option
                  v-for="option in phoneTypes"
                  :key="option.value"
                  :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>

            <div class="col-lg-6 mb-30">
              <label for="phone" class="form-label">
                {{ dictionary.phoneLabel }}
              </label>

              <div class="phone-group">
                <MultiSelect
                  id="dialingCode"
                  v-model="dialingCode"
                  autocomplete="nope"
                  :searchable="true"
                  :clear-on-search="true"
                  :options="dialingCodes"
                  :can-deselect="false"
                  :can-clear="false"
                  :no-results-text="dictionary.noResultsText"
                  label="text"
                  :class="{
                    'has-error': v$.dialingCode.$error,
                  }">
                  <template #singlelabel="{ value }">
                    <div class="multiselect-single-label">
                      <img
                        class="multiselect-icon-currency"
                        :src="value.icon" />
                      {{ value.text }}
                    </div>
                  </template>

                  <template #option="{ option }">
                    <img class="multiselect-icon-currency" :src="option.icon" />
                    {{ option.text }}
                  </template>
                </MultiSelect>

                <input
                  id="phone"
                  v-model.trim="phone"
                  class="form-control"
                  :class="{ 'has-error': v$.phone.$error }"
                  name="phone"
                  type="tel"
                  @blur="v$.phone.$touch" />
              </div>

              <div v-if="v$.phone.$error">
                <div v-if="v$.phone.requiredIf.$invalid" class="error">
                  {{ dictionary.phoneRequired }}
                </div>
                <!-- prettier-ignore -->
                <div
                  v-if="v$.phone.minLength.$invalid || v$.phone.maxLength.$invalid"
                  class="error">
                  {{ dictionary.phoneLength }}
                </div>
              </div>
            </div>
          </div>

          <div class="row gx-5">
            <div class="col-xl-6 mb-30">
              <div class="form-check">
                <input
                  id="usePersonalData"
                  v-model="usePersonalData"
                  name="usePersonalData"
                  type="checkbox"
                  class="form-check-input"
                  autocomplete="off" />
                <label class="form-check-label" for="usePersonalData">
                  <div v-html="dictionary.usePersonalDataLabel"></div>
                </label>
              </div>
            </div>
            <div class="col-xl-6 mb-30">
              <div class="form-check">
                <input
                  id="sendSmsMessages"
                  v-model="sendSmsMessages"
                  name="sendSmsMessages"
                  type="checkbox"
                  class="form-check-input"
                  autocomplete="off" />
                <label class="form-check-label" for="sendSmsMessages">
                  <div v-html="dictionary.sendSmsMessagesLabel"></div>
                </label>
              </div>
            </div>
          </div>

          <div v-if="hasCreditCard">
            <div class="form-separator mb-40"></div>

            <div class="row">
              <div class="col mb-30 fs-20">
                <span class="extrabold">{{ dictionary.addressHeader }}</span>
              </div>
            </div>

            <BookingAddress @change-address-data="onChangeAddressData" />
          </div>

          <div class="form-separator mb-40"></div>

          <BookingFlight
            :key="'flight' + key"
            :carriers="flightCarriers"
            @change-flight-data="onChangeFlightData" />

          <div class="form-separator mb-40"></div>

          <BookingFFN
            :key="'FFN' + key"
            :carriers="carriers"
            @change-ffn-data="onChangeFFNData" />

          <div class="form-separator mb-40"></div>

          <BookingComments @change-comments="onChangeComments" />

          <div v-if="hasCreditCard" class="row">
            <div class="col mb-30 fs-20">
              <span class="extrabold">{{ dictionary.creditCardHeader }}</span>
            </div>
          </div>

          <div v-if="hasCreditCard && dictionary.creditCardText" class="row">
            <div class="col mb-30 semibold">
              <div class="credit-card-info">
                <div><IdeaLottie width="20" /></div>
                <div v-html="dictionary.creditCardText"></div>
              </div>
            </div>
          </div>

          <BookingCrediCard
            v-if="hasCreditCard"
            @change-credit-card-data="onChangecreditCardData" />

          <div class="row">
            <div class="col-lg-6 mb-30">
              <BookingLegalTerms :records="legalTerms" />
            </div>
            <div
              class="col-lg-5 offset-lg-1 mb-30 d-flex justify-content-lg-end">
              <button type="submit" class="btn btn-primary btn-full-width">
                {{ dictionary.buttonSubmitText }}
              </button>
            </div>
          </div>
        </div>

        <div class="s-booking-layout__aside">
          <div class="d-block d-lg-none">
            <BookingAsideAccordion
              v-if="vehicle"
              :item="vehicle"
              :payment="payment">
              <BookingAside :item="vehicle" :payment="payment" />
            </BookingAsideAccordion>
          </div>

          <BookingAside
            v-if="vehicle"
            class="d-none d-lg-block"
            :item="vehicle"
            :payment="payment"
            :show-submit-button="!remoteUrl"
            @submit="onSubmitAside" />
        </div>
      </div>
    </form>
  </div>

  <!-- modal -->
  <div
    id="modalCheckReservation"
    class="modal fade s-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <div class="col">
                {{ dictionary.modalCheckReservationTitle }}
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close">
            <i class="icon-close-light"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-30" v-html="modalCheckReservationMessage"></div>

          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            aria-label="Close">
            {{ dictionary.modalCheckReservationButtonCloseTitle }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery'
import axios from 'axios'
import * as lib from '../../lib/lib'
import { Modal } from 'bootstrap'
import Loader from './common/Loader.vue'
import Alert from './common/Alert.vue'
import useVuelidate from '@vuelidate/core'
import {
  email,
  maxLength,
  minLength,
  required,
  requiredIf,
  sameAs,
} from '@vuelidate/validators'
import { v4 as uuidv4 } from 'uuid'
import MultiSelect from '@vueform/multiselect'
import IdeaLottie from './../components/lotties/Idea.vue'
import BookingAside from './BookingAside.vue'
import BookingAsideAccordion from './BookingAsideAccordion.vue'
import BookingCrediCard from './BookingCreditCard.vue'
import BookingFFN from './BookingFFN.vue'
import BookingFlight from './BookingFlight.vue'
import BookingComments from './../components/BookingComments.vue'
import BookingLegalTerms from './../components/BookingLegalTerms.vue'
import BookingAddress from './BookingAddress.vue'
import bookingMixins from './../mixins/booking-mixins'

export default {
  components: {
    Alert,
    Loader,
    MultiSelect,
    IdeaLottie,
    BookingCrediCard,
    BookingFFN,
    BookingAside,
    BookingAsideAccordion,
    BookingFlight,
    BookingComments,
    BookingLegalTerms,
    BookingAddress,
  },
  mixins: [bookingMixins],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      key: uuidv4(),
      scrollOffsetY: 125,
      isLoading: false,
      errorMsgs: [],
      vehicle: null,
      payment: null,
      token: null,
      // form properties
      name: '',
      surname: '',
      email: '',
      confirmEmail: '',

      phoneType: '',

      dialingCode: '',
      phone: '',

      mobileDialingCode: '',
      mobilePhone: '',
      landDialingCode: '',
      landPhone: '',
      usePersonalData: false,
      sendSmsMessages: false,

      addressData: {
        address: '',
        streetNumber: '',
        addressAlt: '',
        postalCode: '',
        city: '',
        country: '',
        area: '',
      },

      flightData: {
        arrivingByPlane: false,
        flightCarrier: '',
        flightNumber: '',
      },
      FFNData: {
        FFNCarrier: '',
        FFNCard: '',
      },
      creditCardData: {
        cardNumber: '',
        cardHolder: '',
        cardExpirationMonth: '',
        cardExpirationYear: '',
        cardSecureCode: '',
      },

      additionalComments: '',

      // data sources
      flightCarriers: [],
      carriers: [],
      dialingCodes: [],
      phoneTypes: [],
      legalTerms: [],

      // check reservation
      modalCheckReservation: null,
      modalCheckReservationMessage: '',

      remoteUrl: null,
    }
  },
  validations() {
    return {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30),
      },
      surname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(40),
      },
      email: {
        required,
        email,
        minLength: minLength(6),
        maxLength: maxLength(50),
      },
      confirmEmail: {
        required,
        email,
        minLength: minLength(6),
        maxLength: maxLength(50),
        sameAsPassword: sameAs(this.email),
      },
      dialingCode: {
        requiredIf: requiredIf(!this.hasCreditCard),
      },
      phone: {
        requiredIf: requiredIf(!this.hasCreditCard),
        minLength: minLength(5),
        maxLength: maxLength(40),
      },
      mobileDialingCode: {
        requiredIf: requiredIf(this.hasCreditCard),
      },
      mobilePhone: {
        requiredIf: requiredIf(this.hasCreditCard),
        minLength: minLength(5),
        maxLength: maxLength(40),
      },
      landDialingCode: {
        requiredIf: requiredIf(this.hasCreditCard),
      },
      landPhone: {
        requiredIf: requiredIf(this.hasCreditCard),
        minLength: minLength(5),
        maxLength: maxLength(40),
      },
    }
  },
  computed: {
    hasCreditCard() {
      const { cardExpirationYears } = lib.getData('bookingData')
      return cardExpirationYears !== null
    },
  },
  mounted() {
    const {
      token,
      vehicle,
      Payment,
      carriers,
      flightCarriers,
      dialingCodes,
      phoneTypes,
      legalTerms,
    } = lib.getData('bookingData')
    this.token = token
    this.vehicle = vehicle
    this.payment = Payment
    this.carriers = carriers
    this.flightCarriers = flightCarriers
    this.dialingCodes = dialingCodes
    this.phoneTypes = phoneTypes
    this.legalTerms = legalTerms

    this.setNavigatorValues()
    this.setPreselectedOptions()

    // do not remove line below
    this.key = uuidv4()

    this.modalCheckReservation = new Modal(
      document.getElementById('modalCheckReservation')
    )

    document.addEventListener('startNotifyTimer', (e) => {
      this.startNotifyTimer(e)
    })

    document.addEventListener('paymentRemoveIframe', (e) => {
      this.paymentRemoveIframe(e)
    })
  },

  methods: {
    paymentShowIframe() {
      console.log('paymentShowIframe')
      jQuery('#payment-iframe-frame').show()
      jQuery('#payment-iframe-frame').attr('src', this.remoteUrl)
      lib.scrollToElem(jQuery(this.$refs.strip), 100)
    },

    startNotifyTimer(e) {
      console.log('startNotifyTimer', e)

      const { checkUrl, iframeUrl } = e.detail

      let start = 0
      const timer = window.setInterval(() => {
        console.log('interval', start)

        if (start > 10) {
          console.log('start larger than 10')
          window.clearInterval(timer)
          jQuery('#payment-iframe-frame').attr('src', iframeUrl)
          return
        }
        axios
          .get(checkUrl)
          .then((resp) => {
            const { success, notified } = resp.data

            if (success && notified) {
              console.log('success && notified')
              window.clearInterval(timer)
              jQuery('#payment-iframe-frame').attr('src', iframeUrl)
              setTimeout(() => {
                this.isLoading = false
              }, 1000)
            }
          })
          .then(() => {
            start += 1
          })
      }, 1000)
    },

    paymentRemoveIframe(e) {
      console.log('paymentRemoveIframe', e)
      console.log('paymentRemoveIframe', e.detail.errors)
      console.log('paymentRemoveIframe', e.detail.url)

      this.isLoading = true

      jQuery('#payment-iframe-frame').hide()

      const { errors, url } = e.detail
      if (url) {
        window.location.assign(url)
        return false
      }

      this.remoteUrl = ''
      this.isLoading = false
      this.errorMsgs = [this.dictionary.defaultErrorMessage]
      // if errors array is empty or
      // has just one item with empty string
      if (errors.length && lib.hasValue(errors[0])) {
        this.errorMsgs = errors
      }

      lib.scrollToElem(jQuery(this.$refs.strip), 100)
    },

    onChangeComments(data) {
      this.additionalComments = data
    },

    onChangeAddressData(data) {
      this.addressData = data
    },

    onChangeFlightData(data) {
      this.flightData = data
    },

    onChangeFFNData(data) {
      this.FFNData = data
    },

    onChangecreditCardData(data) {
      this.creditCardData = data
    },

    onInputEmail() {
      if (!this.v$.email.email.$invalid) {
        this.checkReservation()
      }
    },

    checkReservation() {
      this.isLoading = true

      const postData = {
        Email: this.email,
        Token: this.token,
      }
      axios
        .post(this.configuration.endpointCheckReservation, postData)
        .then((response) => {
          const { Message } = response.data
          this.modalCheckReservationMessage = Message
          if (Message) {
            // to do
            this.modalCheckReservation.show()
          }
          this.handleSuccess()
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    setPreselectedOptions() {
      const entry = this.dialingCodes.find((i) => i.selected)
      this.dialingCode =
        this.mobileDialingCode =
        this.landDialingCode =
          entry ? entry.value : ''

      const entry2 = this.phoneTypes.find((i) => i.selected)
      this.phoneType = entry2 ? entry2.value : ''
    },

    appendNoCardData(formData) {
      formData.append('Phone', this.phone)
      formData.append('DialingCode', this.dialingCode)
    },

    appendCreditCardData(formData) {
      const {
        address,
        streetNumber,
        addressAlt,
        postalCode,
        city,
        country,
        area,
      } = this.addressData

      formData.append('MobilePhone', this.mobilePhone)
      formData.append('MobileDialingCode', this.mobileDialingCode)
      formData.append('LandPhone', this.landPhone)
      formData.append('LandDialingCode', this.landDialingCode)

      formData.append('AddressStreet', address)
      formData.append('AddressNumber', streetNumber)

      formData.append('Address2', addressAlt)
      formData.append('PostalCode', postalCode)

      formData.append('City', city)
      formData.append('Country', country)
      formData.append('Area', area)

      formData.append('CardNumber', this.creditCardData.cardNumber)
      formData.append('CardHolder', this.creditCardData.cardHolder)
      // prettier-ignore
      formData.append('CardExpirationMonth', this.creditCardData.cardExpirationMonth)
      // prettier-ignore
      formData.append('CardExpirationYear', this.creditCardData.cardExpirationYear)
      formData.append('CardSecureCode', this.creditCardData.cardSecureCode)

      formData.append('NavigatorLanguage', this.navigatorLanguage)
      formData.append('NavigatorColors', this.navigatorColors)
      formData.append('NavigatorHeight', this.navigatorHeight)
      formData.append('NavigatorWidth', this.navigatorWidth)
      formData.append('NavigatorTimezone', this.navigatorTimezone)
      formData.append('IsJavaEnabled', this.isJavaEnabled)
      formData.append('ChallengeWindowSize', this.challengeWindowSize)
    },

    onSubmitForm() {
      this.v$.$validate()

      if (this.v$.$invalid) {
        this.$nextTick(() => {
          lib.scrollToValidation(this.scrollOffsetY)
        })
        return false
      }

      this.isLoading = true

      const formData = new FormData()
      formData.append('Name', this.name)
      formData.append('Surname', this.surname)
      formData.append('Email', this.email)
      formData.append('PhoneType', this.phoneType)
      formData.append('ConfirmEmail', this.confirmEmail)
      formData.append('SendSmsMessages', this.sendSmsMessages)
      formData.append('SendTextMessages', false)
      formData.append('UsePersonalData', this.usePersonalData)
      formData.append('ArrivingByPlane', this.flightData.arrivingByPlane)
      formData.append('FlightCarrier', this.flightData.flightCarrier)
      formData.append('FlightNumber', this.flightData.flightNumber)
      formData.append('FFNCarrier', this.FFNData.FFNCarrier)
      formData.append('FFNCard', this.FFNData.FFNCard)
      formData.append('AdditionalComments', false)
      formData.append('Comments', this.additionalComments)
      formData.append('AcceptTerms', true)
      formData.append('Token', this.token)

      if (this.hasCreditCard) {
        this.appendCreditCardData(formData)
      } else {
        this.appendNoCardData(formData)
      }

      axios
        .post(this.configuration.endpointSubmitData, formData)
        .then((response) => {
          const { HasError, Errors, Url, RemoteUrl } = response.data
          // response has error
          if (HasError) {
            this.handleError(Errors)
            return false
          }
          if (Url) {
            window.location.assign(Url)
          }
          if (RemoteUrl) {
            this.remoteUrl = RemoteUrl
            this.paymentShowIframe()
          }
          this.errorMsgs = []
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    handleSuccess() {
      this.errorMsgs = []
      this.isLoading = false
    },

    handleError(messages) {
      this.errorMsgs = messages
      this.isLoading = false

      this.$nextTick(() => {
        lib.scrollToError(100)
      })
    },

    onSubmitAside() {
      this.onSubmitForm()
    },
  },
}
</script>

<style scope lang="scss">
#payment-iframe-frame {
  border: none;
  width: 100%;
  height: 450px;
  overflow: auto;
}

.credit-card-info {
  display: flex;
  gap: 10px;

  > div:nth-child(1) {
    width: 50px;
  }
  > div:nth-child(2) {
    flex: 1;
  }
}
</style>
