<template>
  <div class="b-booking-credit-card mb-60">
    <div class="b-booking-credit-card__body">
      <div class="b-booking-credit-card__main">
        <div class="row">
          <div class="col mb-30">
            <label for="cardNumber" class="form-label">
              {{ dictionary.cardNumberLabel }}
            </label>

            <input
              id="cardNumber"
              v-model.trim="cardNumber"
              class="form-control form-control-credit-card"
              :class="{ 'has-error': v$.cardNumber.$error }"
              name="cardNumber"
              type="text"
              @blur="v$.cardNumber.$touch"
              @keyup="onKeyUpCardNumber" />

            <div v-if="v$.cardNumber.$error">
              <div v-if="v$.cardNumber.required.$invalid" class="error">
                {{ dictionary.cardNumberRequired }}
              </div>
              <div v-if="v$.cardNumber.cardNumberRegex.$invalid" class="error">
                {{ dictionary.cardNumberRegex }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mb-30">
            <label for="cardHolder" class="form-label">
              {{ dictionary.cardHolderLabel }}
            </label>
            <input
              id="cardHolder"
              v-model.trim="cardHolder"
              class="form-control form-control-cardholder"
              :class="{ 'has-error': v$.cardHolder.$error }"
              name="cardHolder"
              type="text"
              @blur="v$.cardHolder.$touch" />

            <div v-if="v$.cardHolder.$error">
              <div v-if="v$.cardHolder.required.$invalid" class="error">
                {{ dictionary.cardHolderRequired }}
              </div>
              <!-- prettier-ignore -->
              <div
                  v-if="v$.cardHolder.minLength.$invalid || v$.cardHolder.maxLength.$invalid"
                  class="error">
                  {{ dictionary.cardHolderLength }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-8">
            <div class="row">
              <div class="col-lg-6">
                <label for="cardExpirationMonth" class="form-label">
                  {{ dictionary.cardExpirationMonthLabel }}
                </label>

                <div class="form-select-datepicker">
                  <i class="icon-calendar"></i>
                  <select
                    id="cardExpirationMonth"
                    v-model="cardExpirationMonth"
                    name="cardExpirationMonth"
                    class="form-select"
                    :class="{ 'has-error': v$.cardExpirationMonth.$error }">
                    <option value="">
                      {{ dictionary.cardExpirationMonthPlaceholder }}
                    </option>
                    <option
                      v-for="item in cardExpirationMonths"
                      :key="item.value"
                      :value="item.value">
                      {{ item.text }}
                    </option>
                  </select>
                </div>

                <div v-if="v$.cardExpirationMonth.$error">
                  <div
                    v-if="v$.cardExpirationMonth.required.$invalid"
                    class="error">
                    {{ dictionary.cardExpirationMonthRequired }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <label
                  for="cardExpirationYear"
                  class="form-label d-none d-lg-block">
                  &nbsp;
                </label>

                <div class="form-select-datepicker">
                  <i class="icon-calendar"></i>
                  <select
                    id="cardExpirationYear"
                    v-model="cardExpirationYear"
                    name="cardExpirationYear"
                    class="form-select"
                    :class="{ 'has-error': v$.cardExpirationYear.$error }">
                    <option value="">
                      {{ dictionary.cardExpirationYearPlaceholder }}
                    </option>
                    <option
                      v-for="item in cardExpirationYears"
                      :key="item.value"
                      :value="item.value">
                      {{ item.text }}
                    </option>
                  </select>
                </div>

                <div v-if="v$.cardExpirationYear.$error">
                  <div
                    v-if="v$.cardExpirationYear.required.$invalid"
                    class="error">
                    {{ dictionary.cardExpirationYearRequired }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4">
            <label
              for="cardSecureCode"
              class="form-label form-label--tooltip"
              data-bs-toggle="tooltip"
              :title="dictionary.ccv2TooltipText">
              {{ dictionary.ccv2Label }}
              <LazyImage
                :image="{ url: '/imgs/icons/tooltip.svg' }"
                width="16" />
            </label>
            <input
              id="cardSecureCode"
              v-model="cardSecureCode"
              class="form-control form-control-ccv"
              :class="{ 'has-error': v$.cardSecureCode.$error }"
              placeholder="3-4 ψηφία"
              name="cardSecureCode"
              type="number"
              @blur="v$.cardSecureCode.$touch" />

            <div v-if="v$.cardSecureCode.$error">
              <div v-if="v$.cardSecureCode.required.$invalid" class="error">
                {{ dictionary.ccv2Required }}
              </div>
              <div
                v-if="v$.cardSecureCode.cardSecureCodeRegex.$invalid"
                class="error">
                {{ dictionary.ccv2Regex }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="b-booking-credit-card__aside d-none d-lg-block">
        <img :src="cardTypeImageUrl" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery'
import LazyImage from './common/LazyImage.vue'
import * as lib from './../../lib/lib'
import useVuelidate from '@vuelidate/core'
import { helpers, maxLength, minLength, required } from '@vuelidate/validators'
import './../../lib/jquery.creditCardValidator.js'
import constants from './../../lib/constants'

const cardNumberRegex = helpers.regex(/^\d{13,19}$/)
const cardSecureCodeRegex = helpers.regex(/^\d{3,4}$/)

export default {
  components: {
    LazyImage,
  },
  emits: ['change-credit-card-data'],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      // form properties
      cardNumber: '',
      cardHolder: '',
      cardExpirationMonth: '',
      cardExpirationYear: '',
      cardSecureCode: '',
      // sources
      cardExpirationMonths: [],
      cardExpirationYears: [],
      cardTypeName: null,
    }
  },
  validations() {
    return {
      cardNumber: {
        required,
        cardNumberRegex,
      },
      cardHolder: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(40),
      },
      cardExpirationMonth: {
        required,
      },
      cardExpirationYear: {
        required,
      },
      cardSecureCode: {
        required,
        cardSecureCodeRegex,
      },
    }
  },
  computed: {
    cardTypeImageUrl() {
      return this.cardTypeName
        ? `/imgs/credit-cards/${this.cardTypeName}.png`
        : constants.placeholderImage
    },
  },
  watch: {
    cardNumber() {
      this.onChangeCreditCardData()
    },
    cardHolder() {
      this.onChangeCreditCardData()
    },
    cardExpirationMonth() {
      this.onChangeCreditCardData()
    },
    cardExpirationYear() {
      this.onChangeCreditCardData()
    },
    cardSecureCode() {
      this.onChangeCreditCardData()
    },
  },
  mounted() {
    const { cardExpirationMonths, cardExpirationYears } =
      lib.getData('bookingData')
    this.cardExpirationMonths = cardExpirationMonths
    this.cardExpirationYears = cardExpirationYears
    this.validateCreditCard()
  },
  methods: {
    onChangeCreditCardData() {
      this.$emit('change-credit-card-data', {
        cardNumber: this.cardNumber,
        cardHolder: this.cardHolder,
        cardExpirationMonth: this.cardExpirationMonth,
        cardExpirationYear: this.cardExpirationYear,
        cardSecureCode: this.cardSecureCode,
      })
    },

    validateCreditCard() {
      jQuery('#cardNumber').validateCreditCard((result) => {
        this.cardTypeName = result.card_type?.name
      })
    },
  },
}
</script>
