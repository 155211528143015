<template>
  <div
    class="alert fade show"
    role="alert"
    :class="[{ 'alert-dismissible': dismissible }, `alert-${type}`]">
    <div class="alert__body">
      <div class="alert__aside d-none d-lg-flex">
        <LazyImage :image="{ url: `/imgs/icons/alert-${type}.svg` }" />
      </div>
      <div class="alert__main">
        <div
          v-for="(item, index) in messages"
          :key="index"
          class="text"
          v-html="item"></div>

        <slot></slot>
      </div>
    </div>

    <button
      v-if="dismissible"
      type="button"
      class="btn btn-clear btn-close"
      data-bs-dismiss="alert"
      aria-label="Close">
      <i class="icon-close-light"></i>
    </button>
  </div>
</template>

<script>
import LazyImage from './LazyImage.vue'

export default {
  components: { LazyImage },
  props: {
    type: {
      required: false,
      type: String,
      default: '',
    },
    messages: {
      required: false,
      type: Array,
      default: () => [],
    },
    dismissible: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {}
  },
}
</script>
