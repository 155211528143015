<template>
  <div class="b-booking-pick-network">
    <ul>
      <li>
        <Loader v-if="isLoadingCountry" display="absolute" size="sm"></Loader>
        <MultiSelect
          :id="type + 'country'"
          v-model="country"
          autocomplete="nope"
          :searchable="true"
          :clear-on-search="true"
          :can-clear="false"
          :options="countries"
          :can-deselect="false"
          :no-results-text="dictionary.noResultsText"
          value-prop="Country"
          label="CountryName"
          :placeholder="dictionary.countryPlaceholder"
          @select="onChangeCountry" />
      </li>
      <li v-show="country">
        <Loader v-if="isLoadingCity" display="absolute" size="sm"></Loader>
        <MultiSelect
          :id="type + 'city'"
          v-model="city"
          autocomplete="nope"
          :searchable="true"
          :clear-on-search="true"
          :can-clear="false"
          :options="cities"
          :can-deselect="false"
          :no-results-text="dictionary.noResultsText"
          value-prop="City"
          label="City"
          :placeholder="dictionary.cityPlaceholder"
          @select="onChangeCity" />
      </li>
      <li v-show="country && city">
        <Loader v-if="isLoadingLocation" display="absolute" size="sm"></Loader>
        <MultiSelect
          :id="type + 'location'"
          v-model="location"
          autocomplete="nope"
          :searchable="true"
          :clear-on-search="true"
          :can-clear="false"
          :options="locations"
          :can-deselect="false"
          :no-results-text="dictionary.noResultsText"
          value-prop="Value"
          label="Label"
          :placeholder="dictionary.locationPlaceholder"
          @select="onChangeLocation" />
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'
import MultiSelect from '@vueform/multiselect'
import Loader from './../components/common/Loader.vue'

export default {
  components: { MultiSelect, Loader },
  props: {
    type: {
      required: true,
      type: String,
    },
  },
  emits: ['change-location'],
  data() {
    return {
      // loaders
      isLoadingCountry: false,
      isLoadingCity: false,
      isLoadingLocation: false,
      errorMsgs: [],
      // properties
      country: '',
      city: '',
      location: '',
      // data sources
      countries: [],
      cities: [],
      locations: [],
    }
  },
  computed: {},
  mounted() {
    this.getCountries()
  },
  methods: {
    resetData() {
      this.country = this.city = this.location = ''
      this.countries = this.cities = this.locations = []
    },
    getCountries() {
      this.isLoadingCountry = true

      axios
        .post(this.configuration.endpointGetCountries, {})
        .then((response) => {
          const { Countries } = response.data
          this.countries = Countries

          this.isLoadingCountry = false
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    getCities(countryCode) {
      this.isLoadingCity = true

      const postData = {
        countryCode: countryCode,
      }

      axios
        .post(this.configuration.endpointGetCities, postData)
        .then((response) => {
          const { Cities } = response.data
          this.cities = Cities

          this.isLoadingCity = false
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    getLocations(countryCode, cityName) {
      this.isLoadingLocation = true

      const postData = {
        countryCode: countryCode,
        cityName: cityName,
      }
      axios
        .post(this.configuration.endpointGetLocations, postData)
        .then((response) => {
          const { Locations } = response.data
          this.locations = Locations

          this.isLoadingLocation = false
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    onChangeCountry() {
      this.getCities(this.country)
    },

    onChangeCity() {
      this.getLocations(this.country, this.city)
    },

    onChangeLocation() {
      this.$emit('change-location', this.location, this.locations, this.type)
      this.resetData()
    },

    handleError(messages) {
      this.errorMsgs = messages
      this.isLoadingCountry =
        this.isLoadingCity =
        this.isLoadingLocation =
          false
    },
  },
}
</script>
