import * as lib from '../lib/lib'
import jQuery from 'jquery'
import { Modal } from 'bootstrap'

export default class modalBannerSplit {
  constructor() {
    const element = document.getElementById('modalBannerSplit')
    const $element = jQuery(element)

    if (!element) {
      return false
    }

    if ($element.hasClass('d-none')) {
      return false
    }

    this.$element = jQuery('#modalBannerSplit')
    this.modal = new Modal(element)

    this._checkBreakpoint()
  }

  _checkBreakpoint() {
    if (lib.scrDesktops()) {
      if (this.$element.hasClass('modal--show-desktop')) {
        this._showModal()
      }
    }

    if (lib.scrDevices()) {
      if (this.$element.hasClass('modal--show-device')) {
        this._showModal()
      }
    }
  }

  _showModal() {
    if (window.sessionStorage.getItem('cb-split') === null) {
      this.modal.show()
      window.sessionStorage.setItem('cb-split', 'true')
    }
  }
}
