<template>
  <Loader v-if="isLoading"></Loader>
  <Alert v-if="errorMsgs.length" type="danger" :messages="errorMsgs"></Alert>

  <div class="row gx-5">
    <!-- address -->
    <div class="col-lg-8 mb-30">
      <label for="address" class="form-label">
        {{ dictionary.addressLabel }}
      </label>
      <input
        id="address"
        v-model.trim="address"
        class="form-control"
        :class="{ 'has-error': v$.address.$error }"
        name="address"
        type="text"
        @blur="v$.address.$touch" />

      <div v-if="v$.address.$error">
        <div v-if="v$.address.required.$invalid" class="error">
          {{ dictionary.addressRequired }}
        </div>
      </div>
    </div>

    <!-- streetNumber -->
    <div class="col-lg-4 mb-30">
      <label for="streetNumber" class="form-label">
        {{ dictionary.streetNumberLabel }}
      </label>
      <input
        id="streetNumber"
        v-model.trim="streetNumber"
        class="form-control"
        :class="{ 'has-error': v$.streetNumber.$error }"
        name="streetNumber"
        type="text"
        @blur="v$.streetNumber.$touch" />

      <div v-if="v$.streetNumber.$error">
        <div v-if="v$.streetNumber.required.$invalid" class="error">
          {{ dictionary.streetNumberRequired }}
        </div>
      </div>
    </div>
  </div>

  <div class="row gx-5">
    <!-- addressAlt -->
    <div class="col-lg-8 mb-30">
      <label for="addressAlt" class="form-label">
        {{ dictionary.addressAltLabel }}
      </label>
      <input
        id="addressAlt"
        v-model.trim="addressAlt"
        class="form-control"
        name="addressAlt"
        type="text" />
    </div>

    <!-- postalCode -->
    <div class="col-lg-4 mb-30">
      <label for="postalCode" class="form-label">
        {{ dictionary.postalCodeLabel }}
      </label>
      <input
        id="postalCode"
        v-model.trim="postalCode"
        class="form-control"
        :class="{ 'has-error': v$.postalCode.$error }"
        name="postalCode"
        type="text"
        @blur="v$.postalCode.$touch" />

      <div v-if="v$.postalCode.$error">
        <div v-if="v$.postalCode.required.$invalid" class="error">
          {{ dictionary.postalCodeRequired }}
        </div>
      </div>
    </div>
  </div>

  <div class="row gx-5">
    <!-- city -->
    <div class="col-lg-8 mb-30">
      <div class="row gx-5">
        <div class="col-lg-6 mb-30">
          <label for="city" class="form-label">
            {{ dictionary.cityLabel }}
          </label>
          <input
            id="city"
            v-model.trim="city"
            class="form-control"
            :class="{ 'has-error': v$.city.$error }"
            name="city"
            type="text"
            @blur="v$.city.$touch" />

          <div v-if="v$.city.$error">
            <div v-if="v$.city.required.$invalid" class="error">
              {{ dictionary.cityRequired }}
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-30">
          <label for="country" class="form-label">
            {{ dictionary.countryLabel }}
          </label>

          <MultiSelect
            id="country"
            v-model="country"
            autocomplete="nope"
            :searchable="true"
            :clear-on-search="true"
            :options="countries"
            :can-deselect="false"
            :no-results-text="dictionary.noResultsText"
            value-prop="Country"
            label="CountryName"
            :placeholder="dictionary.countryPlaceholder"
            :class="{ 'has-error': v$.country.$error }"
            @select="onChangeCountry" />

          <div v-if="v$.country.$error">
            <div v-if="v$.country.required.$invalid" class="error">
              {{ dictionary.countryRequired }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- streetNumber -->
    <div class="col-lg-4 mb-30">
      <label for="area" class="form-label">
        {{ dictionary.areaLabel }}
      </label>

      <MultiSelect
        id="area"
        v-model="area"
        autocomplete="nope"
        :searchable="true"
        :clear-on-search="true"
        :options="areas"
        :can-deselect="false"
        :no-results-text="dictionary.noResultsText"
        value-prop="Code"
        label="Name"
        :placeholder="dictionary.areaPlaceholder"
        :class="{ 'has-error': v$.area.$error }" />

      <div v-if="v$.area.$error">
        <div v-if="v$.area.required.$invalid" class="error">
          {{ dictionary.areaRequired }}
        </div>
      </div>
    </div>
  </div>

  <div v-if="showPhones" class="row gx-5">
    <!-- mobilePhone -->
    <div class="col-xl-6 mb-30">
      <label for="mobilePhone" class="form-label">
        {{ dictionary.mobilePhoneLabel }}
      </label>

      <div class="phone-group">
        <MultiSelect
          id="mobileDialingCode"
          v-model="mobileDialingCode"
          autocomplete="nope"
          :searchable="true"
          :clear-on-search="true"
          :options="dialingCodes"
          :can-deselect="false"
          :can-clear="false"
          :no-results-text="dictionary.noResultsText"
          label="text"
          :class="{
            'has-error': v$.mobileDialingCode.$error,
          }">
          <template #singlelabel="{ value }">
            <div class="multiselect-single-label">
              <img class="multiselect-icon-currency" :src="value.icon" />
              {{ value.text }}
            </div>
          </template>

          <template #option="{ option }">
            <img class="multiselect-icon-currency" :src="option.icon" />
            {{ option.text }}
          </template>
        </MultiSelect>

        <input
          id="mobilePhone"
          v-model.trim="mobilePhone"
          class="form-control"
          :class="{ 'has-error': v$.mobilePhone.$error }"
          name="mobilePhone"
          type="tel"
          @blur="v$.mobilePhone.$touch" />
      </div>

      <div v-if="v$.mobilePhone.$error">
        <div v-if="v$.mobilePhone.requiredIf.$invalid" class="error">
          {{ dictionary.mobilePhoneRequired }}
        </div>
        <!-- prettier-ignore -->
        <div
          v-if="v$.mobilePhone.minLength.$invalid || v$.mobilePhone.maxLength.$invalid"
          class="error">
          {{ dictionary.mobilePhoneLength }}
        </div>
      </div>
    </div>

    <!-- landPhone -->
    <div class="col-xl-6 mb-30">
      <label for="landPhone" class="form-label">
        {{ dictionary.landPhoneLabel }}
      </label>

      <div class="phone-group">
        <MultiSelect
          id="landDialingCode"
          v-model="landDialingCode"
          autocomplete="nope"
          :searchable="true"
          :clear-on-search="true"
          :options="dialingCodes"
          :can-deselect="false"
          :can-clear="false"
          :no-results-text="dictionary.noResultsText"
          label="text"
          :class="{
            'has-error': v$.landDialingCode.$error,
          }">
          <template #singlelabel="{ value }">
            <div class="multiselect-single-label">
              <img class="multiselect-icon-currency" :src="value.icon" />
              {{ value.text }}
            </div>
          </template>

          <template #option="{ option }">
            <img class="multiselect-icon-currency" :src="option.icon" />
            {{ option.text }}
          </template>
        </MultiSelect>

        <input
          id="landPhone"
          v-model.trim="landPhone"
          class="form-control"
          :class="{ 'has-error': v$.landPhone.$error }"
          name="landPhone"
          type="tel"
          @blur="v$.landPhone.$touch" />
      </div>

      <div v-if="v$.landPhone.$error">
        <div v-if="v$.landPhone.requiredIf.$invalid" class="error">
          {{ dictionary.landPhoneRequired }}
        </div>
        <!-- prettier-ignore -->
        <div
          v-if="v$.landPhone.minLength.$invalid || v$.landPhone.maxLength.$invalid"
          class="error">
          {{ dictionary.landPhoneLength }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as lib from './../../lib/lib'
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import {
  maxLength,
  minLength,
  required,
  requiredIf,
} from '@vuelidate/validators'
import MultiSelect from '@vueform/multiselect'
import Loader from './../components/common/Loader.vue'
import Alert from './../components/common/Alert.vue'

export default {
  components: { Alert, Loader, MultiSelect },
  props: {
    showPhones: {
      required: false,
      type: Boolean,
    },
  },
  emits: ['change-address-data'],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      isLoading: false,
      errorMsgs: [],

      address: '',
      streetNumber: '',

      addressAlt: '',
      postalCode: '',

      city: '',
      country: '',
      area: '',

      mobileDialingCode: '',
      mobilePhone: '',

      landDialingCode: '',
      landPhone: '',

      // data sources
      countries: [],
      areas: [],
      dialingCodes: [],
    }
  },
  validations() {
    return {
      address: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
      },
      streetNumber: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(10),
      },
      addressAlt: {
        maxLength: maxLength(100),
      },
      postalCode: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(10),
      },
      city: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
      },
      country: {
        required,
      },
      area: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(10),
      },
      mobileDialingCode: {
        requiredIf: requiredIf(this.showPhones),
      },
      mobilePhone: {
        requiredIf: requiredIf(this.showPhones),
        minLength: minLength(5),
        maxLength: maxLength(40),
      },
      landDialingCode: {
        requiredIf: requiredIf(this.showPhones),
      },
      landPhone: {
        requiredIf: requiredIf(this.showPhones),
        minLength: minLength(5),
        maxLength: maxLength(40),
      },
    }
  },
  watch: {
    address() {
      this.onChangeAddressData()
    },
    streetNumber() {
      this.onChangeAddressData()
    },
    addressAlt() {
      this.onChangeAddressData()
    },
    postalCode() {
      this.onChangeAddressData()
    },
    city() {
      this.onChangeAddressData()
    },
    country() {
      this.onChangeAddressData()
    },
    area() {
      this.onChangeAddressData()
    },
    mobileDialingCode() {
      this.onChangeAddressData()
    },
    mobilePhone() {
      this.onChangeAddressData()
    },
    landDialingCode() {
      this.onChangeAddressData()
    },
    landPhone() {
      this.onChangeAddressData()
    },
  },
  mounted() {
    const { dialingCodes } = lib.getData('bookingData')
    this.dialingCodes = dialingCodes

    this.getCountries()
    this.setPreselectedOptions()
  },

  methods: {
    setPreselectedOptions() {
      const entry = this.dialingCodes.find((i) => i.selected)
      this.mobileDialingCode = this.landDialingCode = entry ? entry.value : ''
    },

    onChangeAddressData() {
      this.$emit('change-address-data', {
        address: this.address,
        streetNumber: this.streetNumber,
        addressAlt: this.addressAlt,
        postalCode: this.postalCode,
        city: this.city,
        country: this.country,
        area: this.area,

        mobileDialingCode: this.mobileDialingCode,
        mobilePhone: this.mobilePhone,

        landDialingCode: this.landDialingCode,
        landPhone: this.landPhone,
      })
    },

    getCountries() {
      this.isLoading = true

      axios
        .post(this.configuration.endpointGetCountries, {})
        .then((response) => {
          const { Countries } = response.data
          this.countries = Countries
          this.handleSuccess()
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    onChangeCountry() {
      this.area = ''
      try {
        /* eslint-disable no-undef */
        this.areas = areaList[this.country]
      } catch (error) {
        console.log(error)
      }
    },

    handleSuccess() {
      this.errorMsgs = []
      this.isLoading = false
    },

    handleError(messages) {
      this.errorMsgs = messages
      this.isLoading = false
    },
  },
}
</script>
