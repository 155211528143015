import './../main.js'
import { createApp } from 'vue'
import BookingBar from './apps/BookingBar.vue'
import BookingStep1 from './apps/BookingStep1.vue'
import BookingStep2 from './apps/BookingStep2.vue'
import BookingStep3 from './apps/BookingStep3.vue'
import BookingStep4 from './apps/BookingStep4.vue'
import BookingModify from './apps/BookingModify.vue'
import BookingModifyCancel from './apps/BookingModifyCancel.vue'
import BookingModifyReview from './apps/BookingModifyReview.vue'
import BookingModifyRefund from './apps/BookingModifyRefund.vue'
import BookingModifySearch from './apps/BookingModifySearch.vue'
import SolutionsSlick from './apps/SolutionsSlick.vue'
import OffersSlick from './apps/OffersSlick.vue'
import Services from './apps/Services.vue'
import ServicesSlick from './apps/ServicesSlick.vue'
import ProgrammsSlick from './apps/ProgrammsSlick.vue'
import SolutionsWizard from './apps/SolutionsWizard.vue'
import Fleet from './apps/Fleet.vue'
import MenuMobile from './apps/MenuMobile.vue'
import ForgotPassword from './apps/ForgotPassword.vue'
import Login from './apps/Login.vue'
import Register from './apps/Register.vue'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { setupCalendar } from 'v-calendar'

if (document.getElementById('app-booking-bar')) {
  const { dictionary, configuration } = window['bookingData']
  const app = createApp(BookingBar)
    .use(autoAnimatePlugin)
    .use(setupCalendar, {})

  Object.assign(
    app.config.globalProperties,
    { dictionary: dictionary },
    { configuration: configuration }
  )

  app.mount('#app-booking-bar')
}

if (document.getElementById('app-booking-step1')) {
  const { dictionary, configuration } = window['bookingData']
  const app = createApp(BookingStep1).use(autoAnimatePlugin)

  Object.assign(
    app.config.globalProperties,
    { dictionary: dictionary },
    { configuration: configuration }
  )

  app.mount('#app-booking-step1')
}

if (document.getElementById('app-booking-step2')) {
  const { dictionary, configuration } = window['bookingData']
  const app = createApp(BookingStep2).use(autoAnimatePlugin)

  Object.assign(
    app.config.globalProperties,
    { dictionary: dictionary },
    { configuration: configuration }
  )

  app.mount('#app-booking-step2')
}

if (document.getElementById('app-booking-step3')) {
  const { dictionary, configuration } = window['bookingData']
  const app = createApp(BookingStep3).use(autoAnimatePlugin)

  Object.assign(
    app.config.globalProperties,
    { dictionary: dictionary },
    { configuration: configuration }
  )

  app.mount('#app-booking-step3')
}

if (document.getElementById('app-booking-step4')) {
  const { dictionary, configuration } = window['bookingData']
  const app = createApp(BookingStep4).use(autoAnimatePlugin)

  Object.assign(
    app.config.globalProperties,
    { dictionary: dictionary },
    { configuration: configuration }
  )

  app.mount('#app-booking-step4')
}

if (document.getElementById('app-booking-modify')) {
  const { dictionary, configuration } = window['bookingData']
  const app = createApp(BookingModify)
    .use(autoAnimatePlugin)
    .use(setupCalendar, {})

  Object.assign(
    app.config.globalProperties,
    { dictionary: dictionary },
    { configuration: configuration }
  )

  app.mount('#app-booking-modify')
}

if (document.getElementById('app-booking-modify-cancel')) {
  const { dictionary, configuration } = window['bookingData']
  const app = createApp(BookingModifyCancel)
    .use(autoAnimatePlugin)
    .use(setupCalendar, {})

  Object.assign(
    app.config.globalProperties,
    { dictionary: dictionary },
    { configuration: configuration }
  )

  app.mount('#app-booking-modify-cancel')
}

if (document.getElementById('app-booking-modify-review')) {
  const { dictionary } = window['bookingData']
  const app = createApp(BookingModifyReview)
    .use(autoAnimatePlugin)
    .use(setupCalendar, {})

  Object.assign(app.config.globalProperties, { dictionary: dictionary })

  app.mount('#app-booking-modify-review')
}

if (document.getElementById('app-booking-modify-refund')) {
  const { dictionary, configuration } = window['bookingData']
  const app = createApp(BookingModifyRefund)
    .use(autoAnimatePlugin)
    .use(setupCalendar, {})

  Object.assign(
    app.config.globalProperties,
    { dictionary: dictionary },
    { configuration: configuration }
  )

  app.mount('#app-booking-modify-refund')
}

if (document.getElementById('app-booking-modify-search')) {
  const { dictionary, configuration } = window['bookingData']
  const app = createApp(BookingModifySearch)
    .use(autoAnimatePlugin)
    .use(setupCalendar, {})

  Object.assign(
    app.config.globalProperties,
    { dictionary: dictionary },
    { configuration: configuration }
  )

  app.mount('#app-booking-modify-search')
}

if (document.getElementById('app-solutions-slick')) {
  createApp(SolutionsSlick).mount('#app-solutions-slick')
}

if (document.getElementById('app-offers-slick')) {
  createApp(OffersSlick).mount('#app-offers-slick')
}

if (document.getElementById('app-services')) {
  createApp(Services).use(autoAnimatePlugin).mount('#app-services')
}

if (document.getElementById('app-services-slick')) {
  createApp(ServicesSlick).use(autoAnimatePlugin).mount('#app-services-slick')
}

if (document.getElementById('app-programms-slick')) {
  createApp(ProgrammsSlick).use(autoAnimatePlugin).mount('#app-programms-slick')
}

if (document.getElementById('app-solutions-wizard')) {
  const { dictionary } = window['solutionsWizard']
  const app = createApp(SolutionsWizard)

  Object.assign(app.config.globalProperties, { dictionary: dictionary })
  app.mount('#app-solutions-wizard')
}

createApp(MenuMobile).use(autoAnimatePlugin).mount('#app-menu-mobile')

if (document.getElementById('app-forgot-password')) {
  const { dictionary, configuration } = window['forgotPasswordData']
  const app = createApp(ForgotPassword)

  Object.assign(
    app.config.globalProperties,
    { dictionary: dictionary },
    { configuration: configuration }
  )

  app.mount('#app-forgot-password')
}

if (document.getElementById('app-login')) {
  const { dictionary, configuration } = window['loginData']
  const app = createApp(Login)

  Object.assign(
    app.config.globalProperties,
    { dictionary: dictionary },
    { configuration: configuration }
  )

  app.mount('#app-login')
}

if (document.getElementById('app-register')) {
  const { dictionary, configuration } = window['registerData']
  const app = createApp(Register)

  Object.assign(
    app.config.globalProperties,
    { dictionary: dictionary },
    { configuration: configuration }
  )

  app.mount('#app-register')
}

if (document.getElementById('app-fleet')) {
  const { dictionary, configuration } = window['fleetData']
  const app = createApp(Fleet).use(autoAnimatePlugin)

  Object.assign(
    app.config.globalProperties,
    { dictionary: dictionary },
    { configuration: configuration }
  )

  app.mount('#app-fleet')
}
