<template>
  <section class="s-strip s-strip--short s-strip--theme-grey">
    <div class="s-strip__header mb-40">
      <div class="page-container">
        <div class="b-strip-header align-items-center">
          <div class="title h2">{{ title }}</div>
        </div>
      </div>
    </div>

    <div class="page-container">
      <BookingWizard :step="step" />
    </div>
  </section>

  <section class="s-strip">
    <div class="page-container">
      <Loader v-if="isLoading" display="absolute"></Loader>

      <form autocomplete="off" @submit.prevent="onSubmitForm">
        <section class="s-search-reservation">
          <div class="s-search-reservation__conts">
            <Alert
              v-if="headerMessage"
              type="warning"
              :dismissible="true"
              class="mb-30">
              <div v-html="headerMessage"></div>
            </Alert>

            <div class="h2 mb-30">
              <span>{{ dictionary.formTitle }}</span>
            </div>

            <Alert
              v-if="errorMsgs.length"
              type="danger"
              :messages="errorMsgs"></Alert>

            <div class="s-search-reservation__form mb-30">
              <div class="row gx-4">
                <div class="col-lg-5 mb-30 mb-lg-0">
                  <label
                    for="referenceNumber"
                    class="form-label form-label--tooltip"
                    data-bs-toggle="tooltip"
                    :title="dictionary.referenceNumberTooltip">
                    {{ dictionary.referenceNumberLabel }}

                    <LazyImage
                      :image="{ url: '/imgs/icons/tooltip.svg' }"
                      width="16" />
                  </label>
                  <input
                    id="referenceNumber"
                    v-model.trim="referenceNumber"
                    type="text"
                    class="form-control"
                    :placeholder="dictionary.referenceNumberPlaceholder"
                    :class="{ 'has-error': v$.referenceNumber.$error }"
                    @blur="v$.referenceNumber.$touch" />

                  <div v-if="v$.referenceNumber.$error">
                    <div
                      v-if="v$.referenceNumber.required.$invalid"
                      class="error">
                      {{ dictionary.referenceNumberRequired }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-5">
                  <label for="refSurname" class="form-label">
                    {{ dictionary.surnameLabel }}
                  </label>
                  <input
                    id="refSurname"
                    v-model.trim="surname"
                    type="text"
                    class="form-control"
                    :class="{ 'has-error': v$.surname.$error }"
                    @blur="v$.surname.$touch" />

                  <div v-if="v$.surname.$error">
                    <div v-if="v$.surname.required.$invalid" class="error">
                      {{ dictionary.surnameRequired }}
                    </div>
                    <div
                      v-if="
                        v$.surname.minLength.$invalid ||
                        v$.surname.maxLength.$invalid
                      "
                      class="error">
                      {{ dictionary.surnameLength }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-2">
                  <label class="form-label">&nbsp;</label>
                  <button type="submit" class="btn btn-primary btn-full-width">
                    {{ dictionary.buttonSubmitText }}
                  </button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col mb-30 p d-flex justify-content-center">
                <p>{{ dictionary.newBookLabel }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col d-flex justify-content-center">
                <Anchor
                  v-if="newBookLink"
                  :item="newBookLink"
                  class="btn btn-primary" />
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import * as lib from '../../lib/lib'
import Loader from './../components/common/Loader.vue'
import Alert from './../components/common/Alert.vue'
import LazyImage from './../components/common/LazyImage.vue'
import Anchor from '../components/common/Anchor.vue'
import BookingWizard from '../components/BookingWizard.vue'
import useVuelidate from '@vuelidate/core'
import { maxLength, minLength, required } from '@vuelidate/validators'

export default {
  components: {
    Loader,
    Alert,
    LazyImage,
    Anchor,
    BookingWizard,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      scrollOffsetY: 125,
      isLoading: false,
      errorMsgs: [],
      title: null,
      step: 0,
      headerMessage: null,
      newBookLink: null,
      // form properties
      referenceNumber: '',
      surname: '',
    }
  },
  validations() {
    return {
      referenceNumber: {
        required,
      },
      surname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(40),
      },
    }
  },
  mounted() {
    const { title, newBookLink, headerMessage } = lib.getData('bookingData')
    this.title = title
    this.headerMessage = headerMessage
    this.newBookLink = newBookLink
  },
  methods: {
    onSubmitForm() {
      this.v$.$validate()

      if (this.v$.$invalid) {
        this.$nextTick(() => {
          lib.scrollToValidation(this.scrollOffsetY)
        })
        return false
      }

      this.isLoading = true

      const postData = {
        ReferenceNumber: this.referenceNumber,
        Surname: this.surname,
      }

      axios
        .post(this.configuration.endpointSubmitData, postData)
        .then((response) => {
          const { HasError, Errors, Url } = response.data
          // response has error
          if (HasError) {
            this.handleError(Errors)
            return false
          }
          if (Url) {
            window.location.assign(Url)
          }
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    handleError(messages) {
      this.errorMsgs = messages
      this.isLoading = false
    },
  },
}
</script>
