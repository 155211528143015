/**
 * apply sticky functionality to .booking-bar selector
 */

import jQuery from 'jquery'
import * as lib from '../lib/lib'

export default class BookingBarSticky {
  constructor(element) {
    if (!element) {
      return false
    }

    this.element = element
    this.$element = jQuery(element)
    this.headerHeight = jQuery('.s-header').outerHeight()
    this.alertHeight = jQuery('.b-alert-line').outerHeight()
    this.pageContainer = jQuery('.page-container', this.$element)
    this.toggleButton = jQuery('.e-booking-bar-toggle button', this.$element)
    this.lastScrollTop = 0
    this.stickyClassName = 'is-sticky'
    this.lastPosition = 0
    this.scrolled = false

    this._onClickToggle()

    window.addEventListener('scroll', () => {
      if (this.lastPosition < window.scrollY) {
        this.scrolled = true
        // scroll down
        this._onScrollDown()
      }

      if (this.lastPosition > window.scrollY) {
        this.scrolled = false
        // scroll up
        this._onScrollUp()
      }

      this.lastPosition = window.scrollY
    })

    jQuery(window).on('resize', () => {
      if (lib.scrDesktops()) {
        this._reset()
      }
    })
  }

  _onScrollDown() {
    if (this.$element.hasClass(this.stickyClassName)) {
      return false
    }

    if (lib.scrDesktops()) {
      this._onScrollDownDesktops()
    }

    if (lib.scrDevices()) {
      this._onScrollDownDevices()
    }
  }

  _onScrollDownDesktops() {
    const bounding = this.element.getBoundingClientRect()
    if (bounding.top <= this.headerHeight) {
      this._stick()
    } else {
      this._unStick()
    }
  }

  _onScrollDownDevices() {
    if (this._isOutOfViewport()) {
      this._stick()
    } else {
      this._unStick()
    }
  }

  _onScrollUp() {
    if (lib.scrDesktops()) {
      this._onScrollUpDesktops()
    }

    if (lib.scrDevices()) {
      this._onScrollUpDevices()
    }
  }

  _onScrollUpDesktops() {
    if (window.scrollY <= this.headerHeight + this.alertHeight) {
      this._unStick()
    }
  }

  _onScrollUpDevices() {
    const st = jQuery(window).scrollTop()

    if (st <= 320) {
      this.$element.removeClass(this.stickyClassName).removeClass('is-expanded')
      this.pageContainer.removeAttr('style')
    }
  }

  _onClickToggle() {
    this.toggleButton.on('click', (e) => {
      e.preventDefault()

      this.$element.toggleClass('is-expanded')
      this.pageContainer.slideToggle('fast')
    })
  }

  _isOutOfViewport() {
    const bounding = this.element.getBoundingClientRect()
    // Returns something like this:
    // {top: -123, left: 0, right: 0, bottom: 25}
    return bounding.bottom <= this.headerHeight
  }

  _reset() {
    this.pageContainer.removeAttr('style')
  }

  _stick() {
    this.$element.addClass(this.stickyClassName)
  }

  _unStick() {
    this.$element.removeClass(this.stickyClassName)
  }
}
