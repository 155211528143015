<template>
  <section class="s-strip s-strip--short s-strip--theme-grey">
    <div class="s-strip__header mb-40">
      <div class="page-container">
        <div class="b-strip-header align-items-center">
          <div class="title h2">{{ title }}</div>
        </div>
      </div>
    </div>

    <div class="page-container">
      <BookingWizard :step="step" />
    </div>
  </section>

  <section class="s-strip">
    <div class="page-container">
      <Loader v-if="isLoading" display="absolute"></Loader>
      <Alert
        v-if="errorMsgs.length"
        type="danger"
        :messages="errorMsgs"></Alert>

      <div class="mb-30 h1">
        <span class="light">{{ fullName }} | {{ referenceNumber }}</span>
      </div>

      <div ref="strip" class="s-booking-layout">
        <Alert
          v-if="headerMessage"
          type="warning"
          :dismissible="true"
          class="mb-30">
          <div v-html="headerMessage"></div>
        </Alert>

        <div class="s-booking-layout__body">
          <div class="s-booking-layout__main">
            <BookingRibbon
              v-if="reservationIdentityInfo"
              :info="reservationIdentityInfo" />

            <div class="d-none d-lg-flex mb-30">
              <div class="fs-20">
                <span class="extrabold">{{ dictionary.yourReservation }}</span>
              </div>
            </div>

            <BookingYourReservation
              v-if="pickupDetails && returnDetails"
              :pickup-details="pickupDetails"
              :return-details="returnDetails"
              class="d-none d-lg-flex" />

            <Accordion
              :title="dictionary.yourReservation"
              class="d-block d-lg-none mb-40">
              <BookingYourReservation
                v-if="pickupDetails && returnDetails"
                :pickup-details="pickupDetails"
                :return-details="returnDetails" />
            </Accordion>

            <div v-if="amenitiesDefinitions.length" class="row">
              <div class="col mb-30 fs-20">
                <span class="extrabold">Extras</span>
              </div>
            </div>

            <BookingExtrasShortList
              v-if="amenitiesDefinitions.length"
              :records="amenitiesDefinitions" />

            <div class="row d-none d-lg-flex">
              <div class="col mb-30 fs-20">
                <span class="extrabold">
                  {{ dictionary.personalInformation }}
                </span>
              </div>
            </div>

            <BookingPersonalInfo
              v-if="reservationPersonalInfo"
              :personal-info="reservationPersonalInfo"
              class="d-none d-lg-flex" />

            <Accordion
              :title="dictionary.personalInformation"
              class="d-block d-lg-none mb-40">
              <BookingPersonalInfo
                v-if="reservationPersonalInfo"
                :personal-info="reservationPersonalInfo" />
            </Accordion>

            <div class="d-block d-lg-none mb-40">
              <BookingAsideAccordion
                v-if="vehicle"
                :item="vehicle"
                :payment="payment">
                <BookingAside :item="vehicle" :payment="payment" />
              </BookingAsideAccordion>
            </div>

            <div class="row">
              <div class="col">
                <ul class="b-booking-buttons-list mb-40">
                  <li>
                    <Anchor
                      v-if="homepageLink"
                      :item="homepageLink"
                      class="btn btn-primary btn-full-width" />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div v-if="vehicle" class="s-booking-layout__aside d-none d-lg-block">
            <BookingAside :item="vehicle" :payment="payment" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as lib from '../../lib/lib'
import Loader from '../components/common/Loader.vue'
import Alert from '../components/common/Alert.vue'
import Anchor from '../components/common/Anchor.vue'
import Accordion from '../components/common/Accordion.vue'
import BookingWizard from '../components/BookingWizard.vue'
import BookingAside from '../components/BookingAside.vue'
import BookingAsideAccordion from '../components/BookingAsideAccordion.vue'
import BookingPersonalInfo from '../components/BookingPersonalInfo.vue'
import BookingYourReservation from '../components/BookingYourReservation.vue'
import BookingRibbon from '../components/BookingRibbon.vue'
import BookingExtrasShortList from '../components/BookingExtrasShortList.vue'

export default {
  components: {
    Loader,
    Alert,
    BookingWizard,
    BookingAside,
    BookingAsideAccordion,
    Anchor,
    Accordion,
    BookingPersonalInfo,
    BookingYourReservation,
    BookingRibbon,
    BookingExtrasShortList,
  },
  data() {
    return {
      isLoading: false,
      errorMsgs: [],
      // always show last step as active
      token: null,
      step: 0,
      title: null,
      vehicle: null,
      payment: null,
      reservationIdentityInfo: null,
      reservationPersonalInfo: null,
      pickupDetails: null,
      returnDetails: null,
      homepageLink: null,
      backLink: null,
      headerMessage: null,
      amenitiesDefinitions: [],
      fullName: '',
      referenceNumber: '',
    }
  },
  mounted() {
    const {
      title,
      vehicle,
      Payment,
      personalInfo,
      reservationDetails,
      homepageLink,
      backLink,
      headerMessage,
      amenitiesDefinitions,
      token,
      fullName,
      referenceNumber,
      reservationIdentityInfo,
    } = lib.getData('bookingData')

    this.step = 3
    this.title = title
    this.vehicle = vehicle
    this.payment = Payment
    this.reservationPersonalInfo = personalInfo
    this.reservationIdentityInfo = reservationIdentityInfo

    this.pickupDetails = reservationDetails.pickupDetails
    this.returnDetails = reservationDetails.returnDetails
    this.homepageLink = homepageLink
    this.backLink = backLink
    this.headerMessage = headerMessage
    this.amenitiesDefinitions = amenitiesDefinitions
    this.token = token
    this.fullName = fullName
    this.referenceNumber = referenceNumber
  },
}
</script>
