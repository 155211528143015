<template>
  <div class="b-extras-short-list mb-50">
    <ul>
      <li v-for="item in records" :key="item.code">
        <div class="b-extra-short">
          <figure class="b-extra-short__figure">
            <LazyImage :image="item.image" />
          </figure>
          <div class="b-extra-short__title">
            {{ item.description }} {{ getQuantity(item) }}
          </div>
          <div class="b-extra-short__price">
            <span class="e-price-day__price">
              <em v-html="item.dailyRate"></em>
              <span>&nbsp;/&nbsp;</span>
              <span v-if="item.isFixedRate">
                {{ dictionary.perReservation }}
              </span>
              <span v-else>{{ dictionary.perDay }}</span>
            </span>
            <span class="e-price-day__vat">{{ dictionary.vatIncluded }}</span>
            <span class="e-price-day__other">
              <span v-if="item.isPrepaid">
                {{ dictionary.prepayOnline }}
              </span>
              <span v-else>{{ dictionary.payUponDelivery }}</span>
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import LazyImage from './../components/common/LazyImage.vue'

export default {
  components: { LazyImage },
  props: {
    records: {
      required: true,
      type: Array,
    },
  },
  methods: {
    getQuantity(item) {
      return item.requestedQuantity > 1 ? `(x${item.requestedQuantity})` : ''
    },
  },
}
</script>
