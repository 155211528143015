import 'lazysizes'
import Breakpoint from 'bootstrap-breakpoints'
import * as lib from './lib/lib'
import IsInViewport from './modules/IsInViewport'
import Hero from './modules/Hero'
import Footer from './modules/Footer'
import Newsletter from './modules/Newsletter'
import Menu from './modules/Menu'
import Promo from './modules/Promo'
import { lazyImages } from './modules/LazyImages'
import PasswordToggler from './modules/PasswordToggler'
import ModalBannerSplit from './modules/ModalBannerSplit'
import ModalBannerSolo from './modules/ModalBannerSolo'
import WorkFlow from './modules/WorkFlow'

window.onYouTubeIframeAPIReady = () => {
  document.dispatchEvent(new Event('on-youtube-iframe-api-ready'))
}

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    // document is ready. Do your stuff here

    lib.iniTooltips(document)

    Breakpoint.init()

    lazyImages()

    PasswordToggler()

    new ModalBannerSplit()

    new ModalBannerSolo()

    new WorkFlow()

    new Hero(document.querySelector('.s-hero-v2'))

    new Menu()

    new Footer(document.querySelector('.s-footer'))

    new Newsletter(document.querySelector('.s-newsletter'))

    document.querySelectorAll('.in-viewport').forEach((i) => {
      new IsInViewport(i)
    })

    document.querySelectorAll('.b-promo').forEach((i) => {
      new Promo(i)
    })
  }
}
