<template>
  <section class="s-strip s-strip--short s-strip--theme-grey">
    <div class="s-strip__header mb-40">
      <div class="page-container">
        <div class="b-strip-header align-items-center">
          <div class="title h2">{{ title }}</div>
        </div>
      </div>
    </div>

    <div class="page-container">
      <BookingWizard :step="step" />
    </div>
  </section>

  <section class="s-strip">
    <div class="page-container">
      <div ref="strip" class="s-booking-layout">
        <Loader v-if="isLoading"></Loader>
        <Alert
          v-if="errorMsgs.length"
          type="danger"
          :messages="errorMsgs"></Alert>

        <div class="mb-30 h1">
          <span class="light">{{ fullName }} | {{ referenceNumber }}</span>
        </div>

        <Alert
          v-if="headerMessage"
          type="warning"
          :dismissible="true"
          class="mb-30">
          <div v-html="headerMessage"></div>
        </Alert>

        <div class="s-booking-layout__body">
          <div class="s-booking-layout__main">
            <div class="d-block d-lg-none mb-40">
              <BookingAsideAccordion
                v-if="vehicle"
                :item="vehicle"
                :payment="payment">
                <BookingAside :item="vehicle" :payment="payment" />
              </BookingAsideAccordion>
            </div>

            <Accordion
              :title="dictionary.accordionHeaderBookingDetails"
              :is-active-prop="true"
              class="theme-shadow mb-40">
              <div class="row">
                <div class="col mb-30">
                  <BookingBarEdit
                    @change-locations-data="onChangeLocationsData" />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <BookingFlight
                    :carriers="flightCarriers"
                    @change-flight-data="onChangeFlightData" />
                </div>
              </div>

              <div class="form-separator mb-40"></div>

              <div class="row">
                <div class="col">
                  <BookingFFN
                    :carriers="carriers"
                    @change-ffn-data="onChangeFFNData" />
                </div>
              </div>

              <div class="form-separator mb-40"></div>

              <div class="row">
                <div class="col">
                  <BookingComments @change-comments="onChangeComments" />
                </div>
              </div>
            </Accordion>

            <div class="row">
              <div class="col mb-40">
                <Accordion
                  :title="dictionary.accordionHeaderExtras"
                  :is-active-prop="atLeastOneExtraIsSelected"
                  class="theme-shadow">
                  <BookingExtrasList
                    :is-modify-mode="true"
                    @change-extras-data="onChangeExtrasData" />
                </Accordion>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <ul class="b-booking-buttons-list mb-40">
                  <li>
                    <form autocomplete="off" @submit.prevent="onSubmitForm">
                      <button
                        type="submit"
                        class="btn btn-primary btn-full-width">
                        {{ dictionary.buttonSaveText }}
                      </button>
                    </form>
                  </li>

                  <li>
                    <form autocomplete="off" @submit.prevent="onSubmitCancel">
                      <button
                        type="submit"
                        class="btn btn-outline-primary btn-full-width">
                        {{ dictionary.buttonCancelText }}
                      </button>
                    </form>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div v-if="vehicle" class="s-booking-layout__aside d-none d-lg-block">
            <BookingAside :item="vehicle" :payment="payment" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import * as lib from '../../lib/lib'
import Alert from '../components/common/Alert.vue'
import Loader from '../components/common/Loader.vue'
import Accordion from './../components/common/Accordion.vue'
import BookingAside from '../components/BookingAside.vue'
import BookingWizard from '../components/BookingWizard.vue'
import BookingBarEdit from './../components/BookingBarEdit.vue'
import BookingFFN from '../components/BookingFFN.vue'
import BookingFlight from '../components/BookingFlight.vue'
import BookingComments from '../components/BookingComments.vue'
import BookingExtrasList from './../components/BookingExtrasList.vue'
import BookingAsideAccordion from '../components/BookingAsideAccordion.vue'

export default {
  components: {
    Alert,
    Loader,
    Accordion,
    BookingFlight,
    BookingFFN,
    BookingWizard,
    BookingAside,
    BookingBarEdit,
    BookingComments,
    BookingExtrasList,
    BookingAsideAccordion,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      scrollOffsetY: 125,
      token: null,
      key: uuidv4(),
      isLoading: false,
      errorMsgs: [],
      step: 1,
      title: null,
      vehicle: null,
      payment: null,
      reservationIdentityInfo: null,
      reservationPersonalInfo: null,
      pickupDetails: null,
      returnDetails: null,
      homepageLink: null,
      headerMessage: null,
      amenitiesDefinitions: [],
      extras: [],
      extrasSelected: [],
      fullName: '',
      referenceNumber: '',
      carriers: [],
      flightCarriers: [],

      // properties
      locationsData: null,
      flightData: {
        arrivingByPlane: false,
        flightCarrier: '',
        flightNumber: '',
      },
      FFNData: {
        FFNCarrier: '',
        FFNCard: '',
      },
      additionalComments: '',
    }
  },
  computed: {
    extrasSelectedIds() {
      return this.extrasSelected.map((i) => i.code)
    },

    atLeastOneExtraIsSelected() {
      return this.amenitiesDefinitions.some((i) => i.requestedQuantity > 0)
    },
  },
  mounted() {
    const {
      token,
      title,
      vehicle,
      Payment,
      reservationIdentityInfo,
      reservationPersonalInfo,
      pickupDetails,
      returnDetails,
      homepageLink,
      headerMessage,
      amenitiesDefinitions,
      carriers,
      flightCarriers,
      fullName,
      referenceNumber,
    } = lib.getData('bookingData')

    this.token = token
    this.title = title
    this.vehicle = vehicle
    this.payment = Payment
    this.reservationIdentityInfo = reservationIdentityInfo
    this.reservationPersonalInfo = reservationPersonalInfo
    this.pickupDetails = pickupDetails
    this.returnDetails = returnDetails
    this.homepageLink = homepageLink
    this.headerMessage = headerMessage
    this.amenitiesDefinitions = amenitiesDefinitions
    this.carriers = carriers
    this.flightCarriers = flightCarriers
    this.fullName = fullName
    this.referenceNumber = referenceNumber
  },
  methods: {
    onChangeExtrasData(data) {
      this.extrasSelected = data
    },

    onChangeLocationsData(data) {
      this.locationsData = data
    },

    onChangeFFNData(data) {
      this.FFNData = data
    },

    onChangeComments(data) {
      this.additionalComments = data
    },

    onChangeFlightData(data) {
      this.flightData = data
    },

    onSubmitCancel() {
      this.isLoading = true

      const postData = {
        Token: this.token,
      }

      axios
        .post(this.configuration.endpointCancelData, postData)
        .then((response) => {
          const { HasError, Errors, Url } = response.data

          // response has error
          if (HasError) {
            this.handleError(Errors)
            return false
          }

          window.location.assign(Url)
          this.handleSuccess()
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    amenityIsSelected(item) {
      return this.extrasSelectedIds.includes(item.code)
    },

    getAmenityQuantity(item) {
      if (!this.amenityIsSelected(item)) {
        return 0
      }
      const entry = this.extrasSelected.find((i) => i.code === item.code)
      return entry.quantity
    },

    getAmenities() {
      return this.amenitiesDefinitions.map((i) => ({
        Code: i.code,
        Quantity: this.getAmenityQuantity(i),
      }))
    },

    onSubmitForm() {
      this.v$.$validate()

      if (this.v$.$invalid) {
        this.$nextTick(() => {
          lib.scrollToValidation(this.scrollOffsetY)
        })
        return false
      }

      this.isLoading = true

      const {
        locationDeparture,
        countryDeparture,
        dateDeparture,
        hourDeparture,
        minutesDeparture,
        locationReturn,
        countryReturn,
        dateReturn,
        hourReturn,
        minutesReturn,
      } = this.locationsData
      const { arrivingByPlane, flightCarrier, flightNumber } = this.flightData
      const { FFNCarrier, FFNCard } = this.FFNData

      const postData = {
        PickupLocationCode: locationDeparture,
        PickupLocationCountryCode: countryDeparture,
        PickupDate: dateDeparture,
        PickupHour: hourDeparture,
        PickupMinutes: minutesDeparture,
        ReturnLocationCode: locationReturn,
        ReturnLocationCountryCode: countryReturn,
        ReturnDate: dateReturn,
        ReturnHour: hourReturn,
        ReturnMinutes: minutesReturn,
        ArrivingByPlane: arrivingByPlane,
        FlightCarrier: flightCarrier,
        FlightNumber: flightNumber,
        FFPCarrier: FFNCarrier,
        FFPCard: FFNCard,
        SendTextMessagesOnly: false,
        UsePersonalData: false,
        AdditionalComments: false,
        Comments: this.additionalComments,
        Token: this.token,
        Amenities: this.getAmenities(),
      }

      axios
        .post(this.configuration.endpointSubmitData, postData)
        .then((response) => {
          const { HasError, Errors, Url } = response.data

          // response has error
          if (HasError) {
            this.handleError(Errors)
            return false
          }

          window.location.assign(Url)
          this.handleSuccess()
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    handleSuccess() {
      this.errorMsgs = []
    },

    handleError(messages) {
      this.errorMsgs = messages
      this.isLoading = false
      this.$nextTick(() => {
        lib.scrollToError(150)
      })
    },
  },
}
</script>
