<template>
  <div class="row">
    <div class="col mb-30">
      <div class="form-check">
        <input
          id="arrivingByPlane"
          v-model="arrivingByPlane"
          name="arrivingByPlane"
          type="checkbox"
          class="form-check-input"
          autocomplete="off"
          @change="onChangeArrivingByPlane" />
        <label class="form-check-label fs-20" for="arrivingByPlane">
          <span class="extrabold">
            {{ dictionary.arrivingByPlaneLabel }}
          </span>
        </label>
      </div>
    </div>
  </div>

  <div v-show="arrivingByPlane" class="row gx-5">
    <div class="col-lg-6 mb-30">
      <label for="flightCarrier" class="form-label">
        {{ dictionary.flightCarrierLabel }}
      </label>

      <MultiSelect
        id="flightCarrier"
        v-model="flightCarrier"
        autocomplete="nope"
        :searchable="true"
        :clear-on-search="true"
        :options="carriers"
        :can-deselect="false"
        :no-results-text="dictionary.noResultsText"
        label="text" />
    </div>
    <div class="col-lg-6 mb-30">
      <label
        for="flightNumber"
        class="form-label form-label--tooltip"
        data-bs-toggle="tooltip"
        :title="dictionary.flightNumberTooltipText">
        {{ dictionary.flightNumberLabel }}
        <LazyImage :image="{ url: '/imgs/icons/tooltip.svg' }" width="16" />
      </label>
      <input
        id="flightNumber"
        v-model.trim="flightNumber"
        class="form-control"
        name="flightNumber"
        type="text"
        :placeholder="dictionary.flightNumberPlaceholder"
        :class="{ 'has-error': v$.flightNumber.$error }" />

      <div v-if="v$.flightNumber.$error">
        <div v-if="v$.flightNumber.requiredIf.$invalid" class="error">
          {{ dictionary.flightNumberRegex }}
        </div>
        <div v-if="v$.flightNumber.flightNumberRegex.$invalid" class="error">
          {{ dictionary.flightNumberRegex }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as lib from './../../lib/lib'
import MultiSelect from '@vueform/multiselect'
import LazyImage from './../components/common/LazyImage.vue'
import useVuelidate from '@vuelidate/core'
import { helpers, requiredIf } from '@vuelidate/validators'

const flightNumberRegex = helpers.regex(/^(\d){0,4}$/)

export default {
  components: { MultiSelect, LazyImage },
  props: {
    carriers: {
      required: true,
      type: Array,
    },
  },
  emits: ['change-flight-data'],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return { arrivingByPlane: false, flightCarrier: '', flightNumber: '' }
  },
  watch: {
    arrivingByPlane() {
      this.onChangeFlightData()
    },
    flightCarrier() {
      this.onChangeFlightData()
    },
    flightNumber() {
      this.onChangeFlightData()
    },
  },
  validations() {
    return {
      flightNumber: {
        requiredIf: requiredIf(this.arrivingByPlane),
        flightNumberRegex,
      },
    }
  },
  mounted() {
    this.setPreselectedOptions()
    this.setPreselectedValues()
  },
  methods: {
    onChangeArrivingByPlane() {
      if (!this.arrivingByPlane) {
        this.flightNumber = ''
      }
    },

    setPreselectedOptions() {
      const entry1 = this.carriers.find((i) => i.selected)
      this.flightCarrier = entry1 ? entry1.value : ''
    },

    setPreselectedValues() {
      try {
        const { arrivingByPlane, flightCarrier, flightNumber } =
          lib.getData('bookingData')

        if (arrivingByPlane) {
          this.arrivingByPlane = arrivingByPlane
        }

        if (flightCarrier) {
          this.flightCarrier = flightCarrier
        }

        if (flightNumber) {
          this.flightNumber = flightNumber
        }
      } catch (error) {
        console.error(error)
      }
    },

    onChangeFlightData() {
      this.$emit('change-flight-data', {
        arrivingByPlane: this.arrivingByPlane,
        flightCarrier: this.flightCarrier,
        flightNumber: this.flightNumber,
      })
    },
  },
}
</script>
