<template>
  <div
    id="modalForgot"
    class="modal fade s-modal s-modal--forgot"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <div class="col-lg-6">{{ dictionary.modalTitle }}</div>
            </div>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close">
            <i class="icon-close-light"></i>
          </button>
        </div>
        <div class="modal-body">
          <form autocomplete="off" @submit.prevent="onSubmitForm">
            <Loader v-if="isLoading" display="absolute"></Loader>
            <Alert
              v-if="errorMsgs.length"
              type="danger"
              :messages="errorMsgs" />

            <!-- Email -->
            <div class="row">
              <div class="col mb-30">
                <label for="ForgotEmail" class="form-label">
                  {{ dictionary.emailLabel }}
                </label>

                <input
                  id="ForgotEmail"
                  v-model.trim="email"
                  class="form-control"
                  :class="{ 'has-error': v$.email.$error }"
                  maxlength="100"
                  name="ForgotEmail"
                  type="email"
                  @blur="v$.email.$touch" />

                <!-- validation -->
                <div v-if="v$.email.$error">
                  <div v-if="v$.email.required.$invalid" class="error">
                    {{ dictionary.emailRequired }}
                  </div>
                  <div v-if="v$.email.email.$invalid" class="error">
                    {{ dictionary.emailRegex }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col d-flex justify-content-center mb-30">
                <button type="submit" class="btn btn-primary btn-full-width">
                  {{ dictionary.buttonSubmitText }}
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col d-flex justify-content-center semibold">
                <span>
                  {{ dictionary.notAMemberYet }}&nbsp;
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#modalSignup"
                    href="javascript:void(0)">
                    {{ dictionary.signUpHere }}
                  </a>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    id="modalForgotThanks"
    class="modal fade s-modal s-modal--forgot"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <div class="col-lg-6">{{ dictionary.modalTitle }}</div>
            </div>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close">
            <i class="icon-close-light"></i>
          </button>
        </div>
        <div class="modal-body">
          <div v-html="dictionary.successText"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from './../mixins/mixins'
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import Alert from './../components/common/Alert.vue'
import Loader from './../components/common/Loader.vue'
import { Modal } from 'bootstrap'

export default {
  components: { Loader, Alert },
  mixins: [mixins],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      isLoading: false,
      errorMsgs: [],
      // form properties
      email: '',
      modalForgot: null,
      modalForgotThanks: null,
    }
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
    }
  },
  mounted() {
    this.modalForgot = new Modal(document.getElementById('modalForgot'))
    // prettier-ignore
    this.modalForgotThanks = new Modal(document.getElementById('modalForgotThanks'))
  },
  methods: {
    onSubmitForm() {
      this.v$.$validate()

      if (this.v$.$invalid) {
        return false
      }

      this.isLoading = true

      const postData = {
        Email: this.email,
        nodeId: this.configuration.nodeId,
        Culture: this.configuration.culture,
      }
      axios
        .post(
          this.configuration.endpointSubmitData,
          postData,
          this.getAxiosConfig()
        )
        .then((response) => {
          const { result, description } = response.data
          // response has error
          if (result === 'error') {
            this.handleError([description])
            return false
          }

          this.modalForgot.hide()
          this.modalForgotThanks.show()
          this.handleSuccess()
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    handleSuccess() {
      this.isLoading = false
      this.errorMsgs = []
    },

    handleError(messages) {
      this.errorMsgs = messages
      this.isLoading = false
    },
  },
}
</script>
