<template>
  <!--Extras Case-1-->
  <div
    id="modalCase1"
    class="modal s-modal s-modal--warning"
    tabindex="-1"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div><i class="icon-warning"></i></div>
            <div>{{ dictionary.modalCase1Title }}</div>
          </div>
          <button
            type="button"
            class="btn-close btn-clear btn-link"
            data-bs-dismiss="modal"
            aria-label="Close">
            <i class="icon-close-light"></i>
          </button>
        </div>
        <div class="modal-body">
          <div v-html="text"></div>
          <ul class="b-extras-as-badges-list">
            <li v-for="(child, index) in childsTitles" :key="index">
              <div class="e-extra-as-badge">{{ child }}</div>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <ul>
            <li>
              <button
                type="button"
                class="btn btn-outline-danger btn-full-width"
                @click="onClickUndo">
                {{ dictionary.modalCase1ButtonUndoText }}
              </button>
            </li>
            <li>
              <button
                type="button"
                class="btn btn-primary btn-full-width"
                @click="onClickSubmit">
                {{ dictionary.modalCase1ButtonSubmitText }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as lib from './../../lib/lib'

export default {
  props: {
    master: {
      required: false,
      type: String,
      default: '',
    },
    childs: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  emits: ['submit', 'undo'],
  data() {
    return { records: [] }
  },
  computed: {
    text() {
      const value = `<strong>${this.masterTitle}</strong>`
      return this.dictionary.modalCase1Text.replace('{0}', value)
    },

    masterTitle() {
      return this.master
        ? this.records.find((i) => i.code === this.master).description
        : ''
    },

    childsTitles() {
      return this.records
        .filter((i) => this.childs.includes(i.code))
        .map((i) => i.description)
    },
  },
  mounted() {
    const { amenitiesDefinitions } = lib.getData('bookingData')
    this.records = amenitiesDefinitions
  },
  methods: {
    onClickUndo() {
      this.$emit('undo')
    },

    onClickSubmit() {
      this.$emit('submit', this.master, this.childs)
    },
  },
}
</script>
