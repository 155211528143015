<template>
  <a :href="url" :target="target" :title="title" :download="download">
    <slot name="before"></slot>
    <span v-if="!hideText">
      {{ text }}
    </span>
    <slot></slot>
  </a>
</template>

<script>
import mixins from '../../mixins/mixins'
import * as lib from '../../../lib/lib'

export default {
  mixins: [mixins],
  props: {
    item: {
      required: true,
      type: Object,
    },
    hideText: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {}
  },
  computed: {
    text() {
      return this.item.text ? this.item.text : ''
    },
    url() {
      return this.item.url ? this.item.url : null
    },
    target() {
      return this.item.target ? this.item.target : '_parent'
    },
    download() {
      return this.item.download ? this.item.target : null
    },
    title() {
      return lib.hasValue(this.title) ? this.title : this.text
    },
  },
}
</script>
