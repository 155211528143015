<template>
  <div ref="strip" class="s-booking-layout">
    <Loader v-if="isLoading"></Loader>
    <Alert v-if="errorMsgs.length" type="danger" :messages="errorMsgs"></Alert>

    <form autocomplete="off" @submit.prevent="onSubmitForm">
      <div class="s-booking-layout__body">
        <div class="s-booking-layout__main">
          <BookingExtrasList @change-payment="onChangePayment" />

          <div class="d-flex justify-content-center">
            <button type="submit" class="btn btn-primary">
              {{ dictionary.buttonSubmitText }}
            </button>
          </div>
        </div>

        <div class="s-booking-layout__aside">
          <div class="d-block d-lg-none">
            <BookingAsideAccordion
              v-if="vehicle"
              :item="vehicle"
              :payment="payment">
              <BookingAside :item="vehicle" :payment="payment" />
            </BookingAsideAccordion>
          </div>

          <BookingAside
            v-if="vehicle"
            class="d-none d-lg-block"
            :item="vehicle"
            :payment="payment"
            :show-submit-button="true"
            @submit="onSubmitAside" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import * as lib from '../../lib/lib'
import Loader from './common/Loader.vue'
import Alert from './common/Alert.vue'
import axios from 'axios'
import BookingAside from './BookingAside.vue'
import BookingAsideAccordion from './BookingAsideAccordion.vue'
import BookingExtrasList from './BookingExtrasList.vue'

export default {
  components: {
    Alert,
    Loader,
    BookingAside,
    BookingAsideAccordion,
    BookingExtrasList,
  },
  data() {
    return {
      isLoading: false,
      errorMsgs: [],
      vehicle: null,
      payment: null,
      token: '',
    }
  },
  mounted() {
    const { token, vehicle, Payment } = lib.getData('bookingData')
    this.token = token
    this.vehicle = vehicle
    this.payment = Payment
  },
  methods: {
    onChangePayment(payment, token) {
      this.payment = payment
      this.token = token
    },

    onSubmitAside() {
      this.onSubmitForm()
    },

    onSubmitForm() {
      this.isLoading = true

      const postData = { Token: this.token }
      axios
        .post(this.configuration.endpointSubmitData, postData)
        .then((response) => {
          const { HasError, Errors, Url } = response.data
          // response has error
          if (HasError) {
            this.handleError(Errors)
            return false
          }

          window.location.assign(Url)
          this.errorMsgs = []
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    handleSuccess() {
      this.errorMsgs = []
      this.isLoading = false
    },

    handleError(messages) {
      this.errorMsgs = messages
      this.isLoading = false

      this.$nextTick(() => {
        lib.scrollToError(100)
      })
    },
  },
}
</script>
