import { Modal } from 'bootstrap'
import jQuery from 'jquery'

export default class ModalBannerSolo {
  constructor() {
    const element = document.getElementById('modalBannerSolo')
    const $element = jQuery(element)

    if (!element) {
      return false
    }

    if ($element.hasClass('d-none')) {
      return false
    }

    this.modal = new Modal(element)
    this.modal.show()
  }
}
