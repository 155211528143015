<template>
  <div class="b-pick-n-return">
    <div class="b-pick-n-return__body">
      <div class="b-pick-n-return__main">
        <!-- group -->
        <div class="b-pick-n-return__group">
          <ul>
            <li>
              <!-- block -->
              <div class="b-pick-n-return__header mb-10">
                <i class="icon-pin"></i>
                <span>{{ dictionary.departureLocationLabel }}</span>
              </div>
              <div>{{ pickupDetails?.address }}</div>
            </li>
            <li>
              <!-- block -->
              <div class="b-pick-n-return__header mb-10">
                <i class="icon-calendar"></i>
                <span>{{ dictionary.departureDateLabel }}</span>
              </div>
              <div>
                {{ pickupDetails?.date }}
                <br />
                {{ pickupDetails?.time }}
              </div>
            </li>
          </ul>
        </div>
        <!-- group -->
        <div class="b-pick-n-return__group">
          <ul>
            <li>
              <!-- block -->
              <div class="b-pick-n-return__header mb-10">
                <i class="icon-pin"></i>
                <span>{{ dictionary.returnLocationLabel }}</span>
              </div>
              <div>{{ returnDetails?.address }}</div>
            </li>
            <li>
              <!-- block -->
              <div class="b-pick-n-return__header mb-10">
                <i class="icon-calendar"></i>
                <span>{{ dictionary.returnDateLabel }}</span>
              </div>
              <div>
                {{ returnDetails?.date }}
                <br />
                {{ returnDetails?.time }}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="b-pick-n-return__aside">
        <Anchor
          v-if="editDetailsLink"
          :item="editDetailsLink"
          :hide-text="true"
          class="btn btn-link btn-icon-start btn-edit">
          <i class="icon-edit"></i>
          <small>
            {{ editDetailsLink.text }}
          </small>
        </Anchor>
      </div>
    </div>
  </div>
</template>

<script>
import * as lib from './../../lib/lib'
import Anchor from './../components/common/Anchor.vue'

export default {
  components: { Anchor },
  data() {
    return { pickupDetails: null, returnDetails: null, editDetailsLink: null }
  },
  mounted() {
    const { pickupDetails, returnDetails, editDetailsLink } =
      lib.getData('bookingData')
    this.pickupDetails = pickupDetails
    this.returnDetails = returnDetails
    this.editDetailsLink = editDetailsLink
  },
}
</script>
