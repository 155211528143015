<template>
  <div class="row">
    <div class="col mb-30 fs-20">
      <span class="extrabold">
        {{ dictionary.FFNCarrierHeader }}
      </span>
    </div>
  </div>

  <div class="row gx-5">
    <div class="col-lg-6 mb-30">
      <label for="FFNCarrier" class="form-label">
        {{ dictionary.FFNCarrierLabel }}
      </label>
      <MultiSelect
        id="FFNCarrier"
        v-model="FFNCarrier"
        autocomplete="nope"
        :searchable="true"
        :clear-on-search="true"
        :options="carriers"
        :can-deselect="false"
        :no-results-text="dictionary.noResultsText"
        label="text" />
    </div>
    <div class="col-lg-6 mb-30">
      <label for="FFNCard" class="form-label">
        {{ dictionary.FFNCardLabel }}
      </label>
      <input
        id="FFNCard"
        v-model.trim="FFNCard"
        class="form-control"
        name="FFNCard"
        type="text" />
    </div>
  </div>
</template>

<script>
import * as lib from './../../lib/lib'
import MultiSelect from '@vueform/multiselect'

export default {
  components: { MultiSelect },
  props: {
    carriers: {
      required: true,
      type: Array,
    },
  },
  emits: ['change-ffn-data'],
  data() {
    return { FFNCarrier: '', FFNCard: '' }
  },
  watch: {
    FFNCarrier() {
      this.onChangeFFNData()
    },
    FFNCard() {
      this.onChangeFFNData()
    },
  },
  mounted() {
    this.setPreselectedOptions()
    this.setPreselectedValues()
  },
  methods: {
    setPreselectedOptions() {
      const entry1 = this.carriers.find((i) => i.selected)
      this.FFNCarrier = entry1 ? entry1.value : ''
    },

    setPreselectedValues() {
      try {
        const { FFPCarrier, FFPCard } = lib.getData('bookingData')

        if (FFPCarrier) {
          this.FFNCarrier = FFPCarrier
        }

        if (FFPCard) {
          this.FFNCard = FFPCard
        }
      } catch (error) {
        console.error(error)
      }
    },

    onChangeFFNData() {
      this.$emit('change-ffn-data', {
        FFNCarrier: this.FFNCarrier,
        FFNCard: this.FFNCard,
      })
    },
  },
}
</script>
