<template>
  <div class="b-extra">
    <div class="b-extra__body">
      <div class="b-extra__aside d-none d-lg-block">
        <LazyImage v-if="item.image" :image="item.image" />
      </div>
      <div class="b-extra__main">
        <div class="b-extra__title h2 mb-15">
          <LazyImage
            v-if="item.image"
            :image="item.image"
            class="d-block d-lg-none" />
          <span>
            {{ item.description }}
          </span>
        </div>
        <div class="b-extra__summary h4 mb-30 p" v-html="item.summary"></div>

        <div v-if="item.highlights" class="b-extra__highlights mb-30">
          <div class="b-extra__highlights__aside">
            <IdeaLottie />
          </div>

          <div
            class="b-extra__highlights__main h4 p"
            v-html="item.highlights"></div>
        </div>

        <div class="b-extra__footer">
          <div class="b-extra__price-info">
            <div
              class="b-extra__price semibold mb-15"
              data-bs-toggle="tooltip"
              :title="item.toolTip">
              <em class="extrabold" v-html="item.dailyRate"></em>
              <span>/</span>
              <span v-if="item.isFixedRate">
                {{ dictionary.perReservation }}
              </span>
              <span v-else>{{ dictionary.perDay }}</span>
              <LazyImage
                v-if="item.toolTip"
                :image="{ url: '/imgs/icons/tooltip.svg' }" />
            </div>
            <div class="b-extra__vat">
              <span class="small">{{ dictionary.vatIncluded }}</span>
              <br />
              <span class="h4 extrabold">
                <span v-if="item.isPrepaid">{{ dictionary.payOnline }}</span>
                <span v-else>{{ dictionary.payUponDelivery }}</span>
              </span>
            </div>
          </div>

          <div class="b-extra__quantity">
            <div v-if="item.maxQuantity > 1 && !isSelected">
              <label for="" class="form-label semibold mb-15">
                {{ item.quantityLabel }}
              </label>

              <div class="form-check-list">
                <div
                  v-for="index in item.maxQuantity"
                  :key="index"
                  class="form-check">
                  <input
                    :id="getId(index)"
                    v-model="quantity"
                    class="form-check-input"
                    type="radio"
                    :name="'radio' + item.code"
                    :value="index" />
                  <label class="form-check-label" :for="getId(index)">
                    {{ index }}
                  </label>
                </div>
              </div>
            </div>
            <div v-else class="d-none d-lg-block">&nbsp;</div>
          </div>

          <div class="b-extra__buttons">
            <button
              v-if="isSelected"
              type="button"
              class="btn btn-icon-start btn-outline-danger btn-full-width"
              @click="onClickDelete">
              <i class="icon-trash"></i>
              {{ dictionary.buttonDeleteText }}
            </button>
            <button
              v-else
              type="button"
              class="btn btn-outline-primary btn-full-width"
              @click="onClickAdd">
              {{ dictionary.buttonAddText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LazyImage from './../components/common/LazyImage.vue'
import IdeaLottie from './../components/lotties/Idea.vue'

export default {
  components: { LazyImage, IdeaLottie },
  props: {
    item: {
      required: true,
      type: Object,
    },
    selected: {
      required: true,
      type: Array,
    },
  },
  emits: ['add', 'delete'],
  data() {
    return { quantity: 1 }
  },
  computed: {
    isSelected() {
      const selected = this.selected.map((i) => i.code)
      return selected.includes(this.item.code)
    },
  },
  mounted() {
    this.setPreselectedQuantity()
  },
  methods: {
    setPreselectedQuantity() {
      if (this.isSelected) {
        const entry = this.selected.find((i) => i.code === this.item.code)
        this.quantity = entry.quantity
      }
    },

    getId(index) {
      return `radio${this.item.code}${index}`
    },

    onClickAdd() {
      this.$emit('add', this.item.code, this.quantity)
    },

    onClickDelete() {
      this.$emit('delete', this.item.code)
    },
  },
}
</script>
