<template>
  <div class="row">
    <div class="col mb-30 fs-20">
      <label for="additionalComments" class="extrabold">
        {{ dictionary.additionalCommentsLabel }}
      </label>
    </div>
  </div>

  <div class="row">
    <div class="col mb-15">
      <!-- additionalComments -->
      <textarea
        id="additionalComments"
        v-model="additionalComments"
        class="form-control"
        :class="{ 'has-error': v$.additionalComments.$error }"
        :placeholder="dictionary.additionalCommentsPlaceholder"
        @blur="v$.additionalComments.$touch"></textarea>

      <div v-if="v$.additionalComments.$error">
        <div v-if="v$.additionalComments.maxLength.$invalid" class="error">
          {{ dictionary.additionalCommentsLength }}
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col mb-30 d-flex justify-content-end">
      <small class="bold">
        <span v-if="commentsLength">{{ commentsLength }} /</span>
        {{ additionalCommentsMaxLength }}
        {{ dictionary.additionalCommentsCharacters }}
      </small>
    </div>
  </div>
</template>

<script>
import * as lib from './../../lib/lib'
import useVuelidate from '@vuelidate/core'
import { maxLength } from '@vuelidate/validators'

export default {
  emits: ['change-comments'],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return { additionalComments: '', additionalCommentsMaxLength: 250 }
  },
  validations() {
    return {
      additionalComments: {
        maxLength: maxLength(this.additionalCommentsMaxLength),
      },
    }
  },
  computed: {
    commentsLength() {
      return this.additionalComments.length
    },
  },
  watch: {
    additionalComments() {
      this.onChangeComments()
    },
  },
  mounted() {
    this.setPreselectedValues()
  },
  methods: {
    setPreselectedValues() {
      try {
        const { comments } = lib.getData('bookingData')
        if (comments) {
          this.additionalComments = comments
        }
      } catch (error) {
        console.error(error)
      }
    },

    onChangeComments() {
      this.$emit('change-comments', this.additionalComments)
    },
  },
}
</script>
