<template>
  <div class="b-your-reservation mb-50">
    <div class="b-your-reservation__conts">
      <div class="b-your-reservation__tbl">
        <div class="e-pickup">
          <div>
            {{ dictionary.pickUp }}
          </div>
          <div>
            <!-- prettier-ignore -->
            <LazyImage :image="{ url: '/imgs/icons/pickup.svg' }" />
          </div>
        </div>
        <div class="b-your-reservation__dt">
          {{ dictionary.pickUpLocation }}
        </div>

        <div class="b-your-reservation__dd">
          <div class="b-your-reservation__dd__main">
            <ul>
              <li>
                {{ pickupDetails?.address }}
              </li>
              <li>{{ pickupDetails?.telephone }}</li>
            </ul>
          </div>
          <div class="b-your-reservation__dd__aside">
            <span
              data-bs-toggle="tooltip"
              data-bs-html="true"
              :title="pickupDetails?.scheduleAsList">
              <LazyImage
                :image="{
                  url: '/imgs/icons/tooltip.svg',
                }" />
              {{ dictionary.workingHours }}
            </span>
          </div>
        </div>
        <div class="b-your-reservation__dt">
          {{ dictionary.dateAndTime }}
        </div>
        <div class="b-your-reservation__dd">
          <div class="b-your-reservation__dd__main">
            <ul>
              <li>{{ pickupDetails?.date }}</li>
            </ul>
          </div>
          <div class="b-your-reservation__dd__aside">
            <span>{{ pickupDetails?.time }}</span>
          </div>
        </div>
      </div>
      <div
        class="b-your-reservation__seperator d-block d-lg-none d-xl-block"
        role="presentation"></div>
      <div class="b-your-reservation__tbl">
        <div class="e-pickup">
          <div>
            {{ dictionary.return }}
          </div>
          <div>
            <!-- prettier-ignore -->
            <LazyImage :image="{ url: '/imgs/icons/return.svg' }" />
          </div>
        </div>
        <div class="b-your-reservation__dt">
          {{ dictionary.returnLocation }}
        </div>
        <div class="b-your-reservation__dd">
          <div class="b-your-reservation__dd__main">
            <ul>
              <li>
                {{ returnDetails?.address }}
              </li>
              <li>{{ returnDetails?.telephone }}</li>
            </ul>
          </div>
          <div class="b-your-reservation__dd__aside">
            <span
              data-bs-toggle="tooltip"
              data-bs-html="true"
              :title="returnDetails?.scheduleAsList">
              <!-- prettier-ignore -->
              <LazyImage :image="{ url: '/imgs/icons/tooltip.svg' }" />
              {{ dictionary.workingHours }}
            </span>
          </div>
        </div>
        <div class="b-your-reservation__dt">
          {{ dictionary.dateAndTime }}
        </div>
        <div class="b-your-reservation__dd">
          <div class="b-your-reservation__dd__main">
            <ul>
              <li>{{ returnDetails?.date }}</li>
            </ul>
          </div>
          <div class="b-your-reservation__dd__aside">
            <span>{{ returnDetails?.time }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LazyImage from './../components/common/LazyImage.vue'

export default {
  components: { LazyImage },
  props: {
    pickupDetails: {
      required: true,
      type: Object,
    },
    returnDetails: {
      required: true,
      type: Object,
    },
  },
}
</script>
