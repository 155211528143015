<template>
  <div ref="strip" class="s-booking-fleet">
    <Loader v-if="isLoading" display="absolute"></Loader>
    <Alert v-if="errorMsgs.length" type="danger" :messages="errorMsgs"></Alert>

    <div class="s-booking-fleet__found mb-40">
      <div class="semibold">
        <span v-if="recordsCountFiltered === 1">
          {{ dictionary.resultsFoundPrefixSingle }}&nbsp;
        </span>
        <span v-else>{{ dictionary.resultsFoundPrefix }}&nbsp;</span>

        <span class="extrabold">
          {{ recordsCountFiltered }}

          <span v-if="recordsCountFiltered === 1">
            {{ dictionary.resultsFoundSuffixSingle }}
          </span>
          <span v-else>{{ dictionary.resultsFoundSuffix }}</span>
        </span>
        <br />
        {{ dictionary.resultsFoundInstructions }}
      </div>

      <div v-if="couponCodeApplied" class="s-booking-fleet__discount extrabold">
        <CheckLottie />
        <span>
          {{ dictionary.offerCodeActivatedText }}
        </span>
      </div>
    </div>

    <div class="s-booking-fleet__tools mb-40">
      <div class="s-booking-fleet__tools__group">
        <div v-if="types.length" class="s-booking-fleet__my-filters">
          <label class="form-label">{{ dictionary.filtersLabel }}</label>
          <FleetFilters
            :types="types"
            id-prefix="checkbox-lg"
            @submit-filters="onSubmitFilters" />
        </div>

        <div class="s-booking-fleet__guaranteed">
          <label for="" class="form-label">
            {{ dictionary.guaranteedLabel }}
          </label>

          <div class="form-check">
            <input
              id="checkboxGuaranteed"
              v-model="guaranteed"
              class="form-check-input"
              type="checkbox"
              @change="onChangeGuaranteed" />
            <label class="form-check-label" for="checkboxGuaranteed">
              <div>
                {{ dictionary.guaranteedCheckboxPrefix }}&nbsp;
                <span class="extrabold">
                  {{ dictionary.guaranteedCheckboxSuffix }}
                </span>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="s-booking-fleet__tools__group">
        <div class="s-booking-fleet__sort-by">
          <label for="selectSortBy" class="form-label">
            {{ dictionary.sortLabel }}
          </label>
          <select
            id="selectSortBy"
            v-model="sortBy"
            class="form-select"
            @change="onChangeSortBy">
            <option
              v-for="(item, index) in sortByOptions"
              :key="index"
              :value="item.value">
              {{ item.text }}
            </option>
          </select>
        </div>

        <div class="s-booking-fleet__view-as d-none d-lg-flex">
          <label class="form-label">{{ dictionary.viewAsLabel }}</label>
          <ul>
            <li>
              <button type="button" class="btn-clear" @click="onClickGridView">
                <div class="e-view-grid" :class="{ 'is-active': isViewGrid }">
                  <i></i>
                </div>
              </button>
            </li>
            <li>
              <button type="button" class="btn-clear" @click="onClickListView">
                <div class="e-view-list" :class="{ 'is-active': isViewList }">
                  <i></i>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      v-if="recordsCountFiltered"
      ref="grid"
      v-auto-animate
      class="s-booking-fleet__grid mb-60"
      :class="{ 'view-list': isViewList }">
      <div
        v-for="item in recordsFilteredPaged"
        :key="item.Id"
        class="s-booking-fleet__item">
        <BookingVehicle
          :item="item"
          :is-view-grid="isViewGrid"
          :is-view-list="isViewList" />
      </div>
    </div>

    <div v-if="!recordsCountFiltered">
      <NoResults></NoResults>
    </div>

    <div
      v-if="recordsCountFiltered"
      class="d-flex justify-content-center mb-40">
      <Pagination
        :total-records="recordsCountFiltered"
        :per-page="itemsPerPage"
        :current-page="page"
        @pagechanged="pageChanged" />
    </div>

    <div v-if="showButtonViewAll" class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btn-outline-primary"
        @click="onClickViewAll">
        {{ dictionary.buttonViewAllText }}
      </button>
    </div>
  </div>
</template>

<script>
import * as lib from '../../lib/lib'
import BookingVehicle from './BookingVehicle.vue'
import Loader from './common/Loader.vue'
import Alert from './common/Alert.vue'
import NoResults from './common/NoResults.vue'
import FleetFilters from './FleetFilters.vue'
import constants from '../../lib/constants'
import Pagination from './../components/common/Pagination.vue'
import jQuery from 'jquery'
import CheckLottie from './../components/lotties/Check.vue'
/* eslint-disable no-unused-vars */
import { orderBy } from 'lodash'

export default {
  components: {
    FleetFilters,
    Pagination,
    Alert,
    Loader,
    NoResults,
    BookingVehicle,
    CheckLottie,
  },

  data() {
    return {
      view: null,
      percentage: 0,
      isLoading: false,
      errorMsgs: [],
      records: [],
      recordsCount: null,
      recordsFiltered: [],
      page: 1,
      viewMoreLink: null,
      types: [],
      breakpoint: null,
      sortByOptions: [],
      itemsPerPage: 0,
      couponCodeApplied: false,
      guaranteed: false,
      sortBy: 'OrderingInitial',
      transmissions: [],
      specialCategories: [],
    }
  },
  computed: {
    showButtonViewAll() {
      return this.recordsCountFiltered > this.itemsPerPage
    },

    recordsFilteredPaged() {
      const start = (this.page - 1) * this.itemsPerPage
      const end = this.page * this.itemsPerPage
      return this.recordsFiltered.slice(start, end)
    },

    recordsCountFiltered() {
      return this.recordsFiltered.length
    },

    isViewGrid() {
      return (
        this.view === constants.view.grid &&
        this.breakpoint === constants.breakpoints.desktops
      )
    },

    isViewList() {
      return (
        this.breakpoint === constants.breakpoints.devices ||
        this.view === constants.view.list
      )
    },
  },
  mounted() {
    // prettier-ignore
    const { records, recordsCount, types, sortByOptions, couponCodeApplied } = lib.getData('bookingData')
    this.records = this.recordsFiltered = records
    this.recordsCount = recordsCount
    this.types = types
    this.sortByOptions = sortByOptions
    this.couponCodeApplied = couponCodeApplied
    this.itemsPerPage = this.configuration.itemsPerPage

    if (this.configuration.listView) {
      this.onClickListView()
    } else {
      this.onClickGridView()
    }

    this.setBreakpoint()
    jQuery(window).on('resize', () => {
      this.setBreakpoint()
    })
  },
  methods: {
    onChangeSortBy() {
      /* eslint-disable no-undef */
      this.recordsFiltered = _.orderBy(this.recordsFiltered, [this.sortBy])
      this.page = 1
    },

    onClickViewAll() {
      this.page = 1
      this.itemsPerPage = 9999
    },

    setBreakpoint() {
      const { devices, desktops } = constants.breakpoints
      this.breakpoint = lib.scrDevices() ? devices : desktops
    },

    pageChanged(value) {
      this.page = value
      console.log('pagechange')
      lib.scrollToElem(jQuery(this.$refs.grid), 100)
    },

    onClickGridView() {
      this.page = 1
      this.view = constants.view.grid
      this.itemsPerPage = this.configuration.itemsPerPageGridView
    },

    onClickListView() {
      this.page = 1
      this.view = constants.view.list
      this.itemsPerPage = this.configuration.itemsPerPageListView
    },

    onChangeGuaranteed() {
      this.setFilters()
    },

    onSubmitFilters(categories, transmissions, specialCategories) {
      this.transmissions = transmissions
      this.specialCategories = specialCategories
      this.setFilters()
    },

    setFilters() {
      this.recordsFiltered = this.records

      // transmissions
      if (this.transmissions.length) {
        this.recordsFiltered = this.recordsFiltered.filter((i) =>
          i.Categories.split('|').some(
            (r) => this.transmissions.indexOf(r) >= 0
          )
        )
      }

      // specialCategories
      if (this.specialCategories.length) {
        this.recordsFiltered = this.recordsFiltered.filter((i) =>
          i.Categories.split('|').some(
            (r) => this.specialCategories.indexOf(r) >= 0
          )
        )
      }

      // guaranteed
      if (this.guaranteed) {
        this.recordsFiltered = this.recordsFiltered.filter((i) => i.Guaranteed)
      }
    },
  },
}
</script>
