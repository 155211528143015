<template>
  <div class="b-booked-ribbon mb-40">
    <div class="b-booked-ribbon__conts">
      <dl>
        <dt>{{ dictionary.reservationNumberLabel }}</dt>
        <dd>
          {{ info?.reservationNumber }}
        </dd>
      </dl>
      <dl>
        <dt>{{ dictionary.referenceNumberLabel }}</dt>
        <dd>
          {{ info?.referenceNumber }}
        </dd>
      </dl>
      <dl>
        <dt>{{ dictionary.paymentMethodLabel }}</dt>
        <dd>
          <span v-if="info?.isPrepaid">
            {{ dictionary.payOnline }}
          </span>
          <span v-else>{{ dictionary.payUponDelivery }}</span>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      required: true,
      type: Object,
    },
  },
}
</script>
