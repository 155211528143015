<template>
  <section class="s-strip s-strip--short s-strip--theme-grey d-print-none">
    <div class="s-strip__header mb-40">
      <div class="page-container">
        <div class="b-strip-header align-items-center">
          <div class="title h2">{{ title }}</div>
        </div>
      </div>
    </div>

    <div class="page-container">
      <BookingWizard :step="step" />
    </div>
  </section>

  <section class="s-strip d-print-none">
    <div class="page-container">
      <div ref="strip" class="s-booking-layout">
        <div class="alert alert-warning alert-dismissible mb-40" role="alert">
          <div class="alert__body">
            <div class="alert__aside d-none d-lg-flex">
              <LazyImage :image="{ url: '/imgs/icons/alert-warning.svg' }" />
            </div>
            <div class="alert__main" v-html="alertWarningText"></div>
          </div>
          <button
            type="button"
            class="btn btn-clear btn-close"
            data-bs-dismiss="alert"
            aria-label="Close">
            <i class="icon-close-light"></i>
          </button>
        </div>

        <div class="s-booking-layout__body">
          <div class="s-booking-layout__main">
            <BookingRibbon
              v-if="reservationIdentityInfo"
              :info="reservationIdentityInfo" />

            <div class="d-none d-lg-flex mb-30">
              <div class="fs-20">
                <span class="extrabold">{{ dictionary.yourReservation }}</span>
              </div>
            </div>

            <BookingYourReservation
              v-if="pickupDetails && returnDetails"
              :pickup-details="pickupDetails"
              :return-details="returnDetails"
              class="d-none d-lg-flex" />

            <Accordion
              :title="dictionary.yourReservation"
              class="d-block d-lg-none mb-40">
              <BookingYourReservation
                v-if="pickupDetails && returnDetails"
                :pickup-details="pickupDetails"
                :return-details="returnDetails" />
            </Accordion>

            <div class="row d-none d-lg-flex">
              <div class="col mb-30 fs-20">
                <span class="extrabold">
                  {{ dictionary.personalInformation }}
                </span>
              </div>
            </div>

            <BookingPersonalInfo
              v-if="reservationPersonalInfo"
              :personal-info="reservationPersonalInfo"
              class="d-none d-lg-flex" />

            <Accordion
              :title="dictionary.personalInformation"
              class="d-block d-lg-none mb-40">
              <BookingPersonalInfo
                v-if="reservationPersonalInfo"
                :personal-info="reservationPersonalInfo" />
            </Accordion>

            <div class="d-block d-lg-none mb-40">
              <BookingAsideAccordion
                v-if="vehicle"
                :item="vehicle"
                :payment="payment">
                <BookingAside :item="vehicle" :payment="payment" />
              </BookingAsideAccordion>
            </div>

            <div class="d-print-none">
              <div class="d-none d-lg-flex justify-content-center mb-40">
                <a
                  href="javascript:window.print();"
                  class="btn btn-link btn-icon-start btn-print">
                  <i class="icon-printer"></i>
                  <span>{{ dictionary.buttonPrintText }}</span>
                </a>
              </div>

              <ul class="b-booking-buttons-list mb-40">
                <li>
                  <Anchor
                    v-if="newBookingLink"
                    :item="newBookingLink"
                    class="btn btn-primary btn-full-width" />
                </li>
                <li>
                  <a
                    class="btn btn-primary btn-full-width"
                    :title="dictionary.buttonWebCheckInText"
                    data-bs-toggle="modal"
                    data-bs-target="#modalWebCheckIn"
                    href="javascript:void(0)">
                    {{ dictionary.buttonWebCheckInText }}
                  </a>
                </li>
                <li>
                  <Anchor
                    v-if="modifyCancelLink"
                    :item="modifyCancelLink"
                    class="btn btn-outline-primary btn-full-width" />
                </li>
              </ul>

              <div class="d-flex justify-content-center">
                <a
                  class="btn btn-link btn-icon-start"
                  href="javascript:void(0)"
                  :title="legalTermInfo?.title"
                  data-bs-toggle="modal"
                  data-bs-target="#modalBookingTerms">
                  <i class="icon-arrow-right"></i>
                  {{ legalTermInfo?.title }}
                </a>
              </div>
            </div>
          </div>

          <div v-if="vehicle" class="s-booking-layout__aside d-none d-lg-block">
            <BookingAside :item="vehicle" :payment="payment" />
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- modal -->
  <div
    id="modalBookingTerms"
    class="modal fade s-modal s-modal--booking-terms"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <div class="col-lg-6">{{ legalTermInfo?.title }}</div>
            </div>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close">
            <i class="icon-close-light"></i>
          </button>
        </div>
        <div class="modal-body">
          <div v-html="legalTermInfo?.content"></div>
        </div>
      </div>
    </div>
  </div>

  <!-- modal -->
  <div
    id="modalWebCheckIn"
    class="modal fade s-modal s-modal--booking-terms"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <div class="col-lg-6">{{ dictionary.modalWebCheckInTitle }}</div>
            </div>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close">
            <i class="icon-close-light"></i>
          </button>
        </div>
        <div class="modal-body">
          <iframe
            :src="webCheckInIframe?.url"
            frameborder="0"
            width="100%"
            height="200vh"></iframe>
        </div>
      </div>
    </div>
  </div>

  <div class="d-none d-print-block"><BookingPrint /></div>
</template>

<script>
import LazyImage from './../components/common/LazyImage.vue'
import BookingWizard from '../components/BookingWizard.vue'
import BookingAside from './../components/BookingAside.vue'
import BookingAsideAccordion from './../components/BookingAsideAccordion.vue'
import Anchor from './../components/common/Anchor.vue'
import Accordion from './../components/common/Accordion.vue'
import BookingPersonalInfo from './../components/BookingPersonalInfo.vue'
import BookingYourReservation from './../components/BookingYourReservation.vue'
import BookingRibbon from './../components/BookingRibbon.vue'
import BookingPrint from './../components/BookingPrint.vue'
import * as lib from '../../lib/lib'

export default {
  components: {
    LazyImage,
    BookingWizard,
    BookingAside,
    BookingAsideAccordion,
    Anchor,
    Accordion,
    BookingPersonalInfo,
    BookingYourReservation,
    BookingRibbon,
    BookingPrint,
  },
  data() {
    return {
      step: 4,
      title: null,
      vehicle: null,
      payment: null,
      reservationIdentityInfo: null,
      reservationPersonalInfo: null,
      legalTermInfo: null,
      pickupDetails: null,
      returnDetails: null,
      newBookingLink: null,
      modifyCancelLink: null,
      webCheckInIframe: null,
      alertWarningText: null,
    }
  },

  mounted() {
    const {
      title,
      vehicle,
      Payment,
      reservationIdentityInfo,
      reservationPersonalInfo,
      legalTermInfo,
      pickupDetails,
      returnDetails,
      newBookingLink,
      modifyCancelLink,
      webCheckInIframe,
      alertWarningText,
    } = lib.getData('bookingData')

    this.title = title
    this.vehicle = vehicle
    this.payment = Payment
    this.reservationIdentityInfo = reservationIdentityInfo
    this.reservationPersonalInfo = reservationPersonalInfo
    this.legalTermInfo = legalTermInfo
    this.pickupDetails = pickupDetails
    this.returnDetails = returnDetails
    this.newBookingLink = newBookingLink
    this.modifyCancelLink = modifyCancelLink
    this.webCheckInIframe = webCheckInIframe
    this.alertWarningText = alertWarningText
  },
}
</script>
