<template>
  <nav class="b-pagination" aria-label="Page navigation" :class="inactiveClass">
    <ul class="b-pagination__list">
      <li class="b-pagination__item b-pagination__item--first">
        <button
          class="b-pagination__btn b-pagination__btn--first"
          type="button"
          :disabled="isInFirstPage"
          aria-label="Go to first page"
          @click="onClickFirstPage"></button>
      </li>
      <li class="b-pagination__item b-pagination__item--prev">
        <button
          class="b-pagination__btn b-pagination__btn--prev"
          type="button"
          :disabled="isInFirstPage"
          aria-label="Go to previous page"
          @click="onClickPreviousPage"></button>
      </li>
      <li
        v-for="(page, index) in pages"
        :key="index"
        class="b-pagination__item b-pagination__item--page">
        <button
          class="b-pagination__btn b-pagination__btn--page"
          type="button"
          :disabled="page.isDisabled"
          :class="{ active: isPageActive(page.name) }"
          :aria-label="'Go to page number ' + page.name"
          @click="onClickPage(page.name)">
          <span>{{ page.name }}</span>
        </button>
      </li>
      <li class="b-pagination__item b-pagination__item--next">
        <button
          class="b-pagination__btn b-pagination__btn--next"
          type="button"
          :disabled="isInLastPage"
          aria-label="Go to next page"
          @click="onClickNextPage"></button>
      </li>
      <li class="b-pagination__item b-pagination__item--last">
        <button
          class="b-pagination__btn b-pagination__btn--last"
          type="button"
          :disabled="isInLastPage"
          aria-label="Go to last page"
          @click="onClickLastPage"></button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5,
    },
    totalRecords: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  emits: ['pagechanged'],
  computed: {
    inactiveClass() {
      if (this.totalPages <= 1) {
        return 'b-pagination--inactive'
      }
      return ''
    },
    totalPages() {
      return Math.ceil(this.totalRecords / this.perPage)
    },
    startPage() {
      if (this.currentPage === 1) {
        return 1
      }

      if (this.currentPage === 2) {
        return 1
      }

      if (this.currentPage === this.totalPages) {
        return Math.max(this.totalPages - this.maxVisibleButtons + 1, 1)
      }

      return this.currentPage - 2
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      )
    },
    pages() {
      const range = []

      for (
        let i = Math.min(
          this.startPage,
          Math.max(this.endPage - (this.maxVisibleButtons - 1), 1)
        );
        i <= this.endPage;
        i += 1
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        })
      }

      return range
    },
    isInFirstPage() {
      return this.currentPage === 1
    },
    isInLastPage() {
      return this.currentPage === this.totalPages
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1)
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1)
    },
    onClickPage(page) {
      this.$emit('pagechanged', page)
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1)
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages)
    },
    isPageActive(page) {
      return this.currentPage === page
    },
  },
}
</script>
