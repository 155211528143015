<template>
  <div class="b-solutions-wizard-programm" :class="{ related: related }">
    <div class="b-solutions-wizard-programm__disclaimer h4">
      <span v-if="related">{{ dictionary.relatedSolutionDisclaimer }}</span>
      <span v-else>{{ dictionary.featureSolutionDisclaimer }}</span>
    </div>

    <div class="b-solutions-wizard-programm__body">
      <div class="b-solutions-wizard-programm__header">
        <div class="title h2 mb-10">
          {{ item.title }}
        </div>
        <div class="h3 p mb-15">
          {{ item.summary }}
        </div>
        <div class="b-solutions-wizard-programm__label mb-10">
          {{ item.label }}
        </div>
      </div>
      <div class="b-solutions-wizard-programm__main mb-40">
        <ul v-if="item.advantages.length" class="advantages">
          <li v-for="(x, index) in item.advantages" :key="index">
            <span>{{ x }}</span>
          </li>
        </ul>
      </div>
      <div class="b-solutions-wizard-programm__footer">
        <Anchor :item="item.link" class="btn btn-primary btn-full-width" />
      </div>
    </div>
  </div>
</template>

<script>
import Anchor from './../components/common/Anchor.vue'

export default {
  components: { Anchor },
  props: {
    item: {
      required: true,
      type: Object,
    },
    related: {
      required: false,
      type: Boolean,
    },
  },
}
</script>
