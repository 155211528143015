<template>
  <Anchor v-if="item.link" :item="item.link" :hide-text="true" class="b-offer">
    <div class="b-offer__picture mb-20">
      <LazyImage
        v-if="item.image"
        :image="item.image"
        class="object-fit-cover" />
    </div>
    <div class="b-offer__body mb-40">
      <div class="b-offer__title h2 mb-15">{{ item.title }}</div>
      <article class="b-article mb-40" v-html="item.summary"></article>
    </div>
    <div class="b-offer__view-more">
      <div class="btn btn-link btn-icon-end btn-forward d-inline-flex">
        {{ item.link.text }}
        <i class="icon-arrow-long-right"></i>
      </div>
    </div>
  </Anchor>
</template>

<script>
import Anchor from './common/Anchor.vue'
import LazyImage from './common/LazyImage.vue'

export default {
  components: { Anchor, LazyImage },
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {}
  },
}
</script>
