<template>
  <div class="row row gx-3 gx-lg-4">
    <div :class="[showRemove ? 'col-11' : 'col-12']">
      <div class="row row gx-3 gx-lg-4">
        <div class="col-6 mb-15">
          <div class="form-pseudo-floating">
            <label :for="'type' + item.id" class="form-label">
              {{ dictionary.offerTypeLabel }}
            </label>

            <select
              :id="'type' + item.id"
              v-model="type"
              name="offerType"
              class="form-select">
              <option value="">{{ dictionary.offerTypePlaceholder }}</option>
              <option
                v-for="option in offerTypes"
                :key="option.value"
                :value="option.value"
                :disabled="isDisabled(option)">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-6 mb-15">
          <div class="form-pseudo-floating">
            <label :for="'code' + item.id" class="form-label">
              {{ dictionary.offerCodeLabel }}
            </label>
            <input
              :id="'code' + item.id"
              v-model.trim="code"
              name="offerCode"
              class="form-control"
              type="text"
              :placeholder="dictionary.offerCodePlaceholder" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="showRemove" class="col-1 mb-15">
      <button
        type="button"
        class="btn btn-icon-start btn-full-width"
        :title="dictionary.buttonOfferRemoveText"
        @click="onClickRemove">
        <i class="icon-bin"></i>
      </button>
    </div>
  </div>
</template>

<script>
import * as lib from '../../lib/lib'

export default {
  components: {},
  props: {
    index: {
      required: true,
      type: Number,
    },
    item: {
      required: true,
      type: Object,
    },
    selectedOffers: {
      required: true,
      type: Array,
    },
  },
  emits: ['remove-offer', 'change-offer-data'],
  data() {
    return {
      type: '',
      code: '',
      // data sources
      offerTypes: [],
    }
  },
  computed: {
    showRemove() {
      return this.selectedOffers.length > 1
    },
  },
  watch: {
    type() {
      this.onChangeOfferData()
    },
    code() {
      this.onChangeOfferData()
    },
  },
  mounted() {
    const { offerTypes } = lib.getData('bookingData')
    this.offerTypes = offerTypes
    this.setPreselectedValues()
  },
  methods: {
    setPreselectedValues() {
      this.type = this.item.type
      this.code = this.item.code
    },

    isDisabled(option) {
      let res = false
      this.selectedOffers.forEach((element, index) => {
        if (index !== this.index) {
          if (element.type === option.value) {
            res = true
          }
        }
      })
      return res
    },
    onClickRemove() {
      this.$emit('remove-offer', this.item.id)
    },
    onChangeOfferData() {
      this.$emit('change-offer-data', this.index, this.type, this.code)
    },
  },
}
</script>
