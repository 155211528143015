<template>
  <form autocomplete="off" @submit.prevent="onSubmitForm">
    <section class="b-booking-bar">
      <div v-auto-animate class="page-container">
        <div class="row gx-4">
          <div v-auto-animate class="col-12 col-lg-6 mb-15">
            <div
              class="form-pseudo-floating pb-0 b-booking-pick-location has-different-return"
              :class="[v$.locationDeparture.$error ? 'has-error' : '']">
              <label for="searchLocationDeparture" class="form-label">
                <i class="icon-pin"></i>
                <span>{{ dictionary.locationDepartureLabel }}</span>
                <button
                  class="btn btn-info btn-icon-end btn-network"
                  type="button"
                  @click="onClickNetworkDeparture">
                  <small>{{ dictionary.buttonNetworkText }}</small>
                  <i class="icon-pin-network"></i>
                </button>
              </label>

              <MultiSelect
                id="searchLocationDeparture"
                v-model="locationDeparture"
                autocomplete="off"
                :searchable="true"
                :clear-on-search="true"
                :can-clear="false"
                :options="locations"
                :can-deselect="false"
                :no-results-text="dictionary.noResultsText"
                value-prop="Value"
                label="Label"
                :placeholder="dictionary.searchLocationPlaceholder"
                :filter-results="false"
                :no-options-text="dictionary.noOptionsText"
                @search-change="onSearchChange" />
            </div>

            <BookingBarNetwork
              v-if="showNetworkDeparture"
              type="departure"
              @change-location="onChangeLocation" />
          </div>
          <div v-auto-animate class="col-12 col-lg-6 mb-15">
            <div
              class="form-pseudo-floating pb-0 b-booking-pick-location has-different-return"
              :class="[v$.locationReturn.$error ? 'has-error' : '']">
              <label for="searchLocationReturn" class="form-label">
                <i class="icon-pin"></i>
                <span>{{ dictionary.locationReturnLabel }}</span>
                <button
                  class="btn btn-info btn-icon-end btn-network"
                  type="button"
                  @click="onClickNetworkReturn">
                  <small>{{ dictionary.buttonNetworkText }}</small>
                  <i class="icon-pin-network"></i>
                </button>
              </label>

              <MultiSelect
                id="searchLocationReturn"
                v-model="locationReturn"
                autocomplete="off"
                :searchable="true"
                :clear-on-search="true"
                :can-clear="false"
                :options="locations"
                :can-deselect="false"
                :no-results-text="dictionary.noResultsText"
                value-prop="Value"
                label="Label"
                :placeholder="dictionary.searchLocationPlaceholder"
                :filter-results="false"
                :no-options-text="dictionary.noOptionsText"
                @search-change="onSearchChange" />
            </div>

            <BookingBarNetwork
              v-if="showNetworkReturn"
              type="return"
              @change-location="onChangeLocation" />
          </div>
        </div>

        <div class="row gx-4">
          <div class="col-6 mb-15">
            <BookingBarDatePicker
              :key="'departure' + key"
              type="departure"
              :label="dictionary.dateDepartureLabel"
              :hour-label="dictionary.hourDepartureLabel"
              :selected-date="dateDepartureFormated"
              :selected-hour="hourDeparture"
              :selected-minutes="minutesDeparture"
              :hour-data-source="hourDataSource"
              :minutes-data-source="minutesDataSource"
              :has-error="v$.dateDeparture.$error"
              :disabled="disableTimeSelection"
              @change-clock="onChangeClock" />
          </div>
          <div class="col-6 mb-15">
            <BookingBarDatePicker
              :key="'return' + key"
              type="return"
              :label="dictionary.dateReturnLabel"
              :hour-label="dictionary.hourReturnLabel"
              :selected-date="dateReturnFormated"
              :selected-hour="hourReturn"
              :selected-minutes="minutesReturn"
              :hour-data-source="hourDataSource"
              :minutes-data-source="minutesDataSource"
              :has-error="v$.dateReturn.$error"
              :date-departure="dateDeparture"
              :disabled="disableTimeSelection"
              dropdown-align="right"
              @change-clock="onChangeClock" />
          </div>
        </div>
      </div>
    </section>
  </form>
</template>

<script>
import moment from 'moment'
import * as lib from '../../lib/lib'
import mixins from '../mixins/mixins'
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { v4 as uuidv4 } from 'uuid'
import MultiSelect from '@vueform/multiselect'
import BookingBarNetwork from './BookingBarNetwork.vue'
import BookingBarDatePicker from './BookingBarDatePicker.vue'
import constants from '../../lib/constants'

export default {
  components: {
    MultiSelect,
    BookingBarNetwork,
    BookingBarDatePicker,
  },
  mixins: [mixins],
  emits: ['change-locations-data'],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      key: uuidv4(),
      isLoading: false,
      errorMsgs: [],
      validationErrors: [],

      // form properties
      locationDeparture: '',
      locationReturn: '',

      countryDeparture: '',
      countryReturn: '',

      dateDeparture: null,
      dateDepartureOneDayAfter: null,
      dateReturn: null,

      hourDeparture: '',
      hourReturn: '',

      minutesDeparture: '',
      minutesReturn: '',

      vehiclesType: '',

      disableTimeSelection: false,

      // data sources
      countries: [],
      cities: [],
      locations: [],
      hourDataSource: [],
      minutesDataSource: [],

      // helpers
      showNetworkDeparture: false,
      showNetworkReturn: false,
      searchMinLength: 3,
    }
  },
  validations() {
    return {
      locationDeparture: { required },
      locationReturn: { required },
      dateDeparture: { required },
      dateReturn: { required },
      hourDeparture: { required },
      hourReturn: { required },
      minutesDeparture: { required },
      minutesReturn: { required },
    }
  },
  computed: {
    dateDepartureFormated() {
      return this.dateDeparture
        ? moment(this.dateDeparture, this.configuration.dateFormat).toDate()
        : null
    },

    dateReturnFormated() {
      return this.dateReturn
        ? moment(this.dateReturn, this.configuration.dateFormat).toDate()
        : null
    },
  },
  watch: {
    locationDeparture(newValue) {
      const country = this.getLocationCountry(newValue)
      this.countryDeparture = country

      this.onChangeLocationsData()
    },

    locationReturn(newValue) {
      const country = this.getLocationCountry(newValue)
      this.countryReturn = country

      this.onChangeLocationsData()
    },

    dateDeparture() {
      this.onChangeLocationsData()
    },

    dateReturn() {
      this.onChangeLocationsData()
    },

    hourDeparture() {
      this.onChangeLocationsData()
    },

    hourReturn() {
      this.onChangeLocationsData()
    },

    minutesDeparture() {
      this.onChangeLocationsData()
    },

    minutesReturn() {
      this.onChangeLocationsData()
    },
  },
  mounted() {
    const { hours, minutes, disableTimeSelection } = lib.getData('bookingData')
    this.hourDataSource = hours
    this.minutesDataSource = minutes
    this.disableTimeSelection = disableTimeSelection

    this.setPreselectedOptions()
    this.setPreselectedValues()
    // do not remove line below
    this.key = uuidv4()
  },

  methods: {
    onChangeLocationsData() {
      this.$emit('change-locations-data', {
        locationDeparture: this.locationDeparture,
        locationReturn: this.locationReturn,
        countryDeparture: this.countryDeparture,
        countryReturn: this.countryReturn,
        dateDeparture: this.dateDeparture,
        dateReturn: this.dateReturn,
        hourDeparture: this.hourDeparture,
        hourReturn: this.hourReturn,
        xxminutesDeparturex: this.minutesDeparture,
        minutesReturn: this.minutesReturn,
      })
    },

    setPreselectedOptions() {
      const { hours, minutes } = lib.getData('bookingData')

      // hours
      const entry1 = hours.find((i) => i.selected)
      this.hourDeparture = this.hourReturn = entry1 ? entry1.value : ''

      // minutes
      const entry2 = minutes.find((i) => i.selected)
      this.minutesDeparture = this.minutesReturn = entry2 ? entry2.value : ''
    },

    setPreselectedValues() {
      const {
        pickupLocationCode,
        pickupLocationCountryCode,
        pickupDate,
        pickupHour,
        pickupMinutes,
        returnLocationCode,
        returnLocationCountryCode,
        returnDate,
        returnHour,
        returnMinutes,
        selectPickupLocation,
        selectReturnLocation,
      } = lib.getData('bookingData')

      this.locationDeparture = pickupLocationCode
      this.countryDeparture = pickupLocationCountryCode

      this.locations.push({
        Country: pickupLocationCountryCode,
        Value: pickupLocationCode,
        Label: selectPickupLocation[0].Text,
      })

      this.dateDeparture = moment(pickupDate).toDate()
      this.hourDeparture = pickupHour
      this.minutesDeparture = pickupMinutes

      this.locationReturn = returnLocationCode
      this.countryReturn = returnLocationCountryCode

      if (pickupLocationCode !== returnLocationCode) {
        this.locations.push({
          Country: returnLocationCountryCode,
          Value: returnLocationCode,
          Label: selectReturnLocation[0].Text,
        })
      }

      this.dateReturn = moment(returnDate).toDate()
      this.hourReturn = returnHour
      this.minutesReturn = returnMinutes

      // do not remove line below
      this.key = uuidv4()
    },

    onChangeClock(date, hour, minutes, type) {
      if (type === constants.location.departure) {
        this.dateDeparture = date
        this.hourDeparture = hour
        this.minutesDeparture = minutes
        this.setDateReturnOneDayAfter()
      }
      if (type === constants.location.return) {
        this.dateReturn = date
        this.hourReturn = hour
        this.minutesReturn = minutes
      }
    },

    setDateReturnOneDayAfter() {
      this.dateDepartureOneDayAfter = moment(this.dateDeparture)
        .add(1, 'd')
        .toDate()

      if (!this.dateReturn || this.dateDeparture >= this.dateReturn) {
        this.dateReturn = this.dateDepartureOneDayAfter
      }
    },

    onSearchChange(query) {
      if (query.length < this.searchMinLength) {
        return false
      }

      const postData = { term: query }
      axios
        .post(this.configuration.endpointSearchLocations, postData)
        .then((response) => {
          const { Locations } = response.data
          this.locations = Locations
          this.handleSuccess()
        })
        .catch((errors) => {
          this.handleError([this.dictionary.defaultErrorMessage])
          console.error(errors)
        })
    },

    onChangeLocation(value, options, type) {
      this.locations = options

      if (type === constants.location.departure) {
        this.locationDeparture = value
        this.showNetworkDeparture = false
      }
      if (type === constants.location.return) {
        this.locationReturn = value
        this.showNetworkReturn = false
      }
    },

    getLocationCountry(value) {
      const entry = this.locations.find((i) => i.Value === value)
      return entry ? entry.Country : ''
    },

    onClickNetworkDeparture() {
      this.showNetworkDeparture = !this.showNetworkDeparture
    },

    onClickNetworkReturn() {
      this.showNetworkReturn = !this.showNetworkReturn
    },
  },
}
</script>
